import { Box, Button, FormControl, FormHelperText, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import '../create-campaign-modal/CreateCampaign.scss'
import ClientService from '../../../services/api/client.service';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../redux/slices/message';
import { setMessageSnackbarStateState } from '../../../redux/slices/common';
import { errorMessage } from '../../../config/config';
import { setIsLoadingState } from "../../../redux/slices/common"
import { LoadingButton } from '@mui/lab';


function CreateClient({ SetUsers,createClientModalOpen, handleCreateClientModalClose }) {
    const dispatch = useDispatch();

    const { register, handleSubmit, control, reset, formState: { errors } } = useForm();
    const user = useSelector(state => state.auth.auth.user);
    const [loading, setLoading] = useState(false)

    const handleOnClose = () => {
        handleCreateClientModalClose(true);
        reset();
    }

    const onSubmit = async (data) => {
        setLoading(true)
       
        const params = {
            companyName: data?.companyName,
            email: data?.memberEmail,
            firstName: data?.firstName,
            lastName: data?.lastName
        }

        ClientService.createClient(params)
            .then(res => {
                if(res){
                    setLoading(false)
                    dispatch(setMessage({
                        type: "success",
                        title: res.message
                      }));
                      dispatch(setMessageSnackbarStateState(true));
                      let queryParams = `page=0&size=8`

                      ClientService.getAllClients(queryParams)
                .then((res) => {
                    if (res && res?.data?.content?.length > 0) {
                        // setLoading(true);
                        dispatch(setIsLoadingState(false))
                    const loadedRows = [];
                        res.data.content.forEach((client) => {
                            loadedRows.push({
                                id: client.companyId,
                                image: client.companyLogo,
                                name: client.companyName,
                                status: client.isActive === true ? 1 : 0,
                                projectCount: client.totalProjects,
                                campaignCount: client.totalCampaigns,
                                clientEmail: client.email
                            });
                        });
                        SetUsers(loadedRows);
                    }
                })
          
                }
                else {
                    setLoading(false)
                    dispatch(setMessage({
                        type: "error",
                        title: errorMessage
                      }));
                      dispatch(setMessageSnackbarStateState(true));
                }
                handleOnClose();
            })
            .catch(err => {
                setLoading(false)
                dispatch(setMessage({
                    type: "error",
                    title: err?.response?.data?.message ||  errorMessage
                  }));
                  dispatch(setMessageSnackbarStateState(true));
            })
       
    };

    return (
        <Modal
            open={createClientModalOpen}
            onClose={handleCreateClientModalClose}
            sx={{
                backdropFilter: "blur(15px)",
            }}
        >
            <Box
                className="create-campaign-modal"
            >
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    type='reset'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Box className="login-logo">
                            <img
                                src="/images/featured-icon(edit-client).png"
                                alt="logo"
                                style={{ height: 48 }}
                            />
                        </Box>
                        <Box sx={{
                            cursor: "pointer"
                        }} className="login-logo" onClick={handleOnClose}>
                            <img
                                src="/images/close-icon.png"
                                alt="logo"
                                style={{ height: 48 }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant='h5' sx={{ fontWeight: 700, mb: '30px' }}>
                            Add New Client
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Box htmlFor='clientName' sx={{ fontWeight: 500, fontSize: "18px", color: "#344054" }}>
                                Company Name
                            </Box>

                            <Box className='input'>
                                <TextField
                                    variant='outlined'
                                    sx={{ width: '100%', mt: '10px' }}
                                    placeholder='Enter your company name'
                                    {...register('companyName', { required: true })}
                                />
                                {errors.companyName && (
                                    <FormHelperText error>This feild is required</FormHelperText>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Box htmlFor='clientName' sx={{ fontWeight: 500, fontSize: "18px", color: "#344054" }}>
                                First Name
                            </Box>

                            <Box className='input'>
                                <TextField
                                    variant='outlined'
                                    sx={{ width: '100%', mt: '10px' }}
                                    placeholder='Enter your first name'
                                    {...register('firstName', { required: false })}
                                />
                                {errors.firstName && (
                                    <FormHelperText error>This feild is required</FormHelperText>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Box htmlFor='clientName' sx={{ fontWeight: 500, fontSize: "18px", color: "#344054" }}>
                                Last Name
                            </Box>

                            <Box className='input'>
                                <TextField
                                    variant='outlined'
                                    sx={{ width: '100%', mt: '10px' }}
                                    placeholder='Enter your last name'
                                    {...register('lastName', { required: false })}
                                />
                                {errors.lastName && (
                                    <FormHelperText error>This feild is required</FormHelperText>
                                )}
                            </Box>
                        </Box>


                        <Box sx={{ mb: 2 }}>
                            <Box htmlFor='clientName' sx={{ fontWeight: 500, fontSize: "18px", color: "#344054" }}>
                                Email Address
                            </Box>

                            <Box className='input'>
                                <TextField
                                    variant='outlined'
                                    sx={{ width: '100%', mt: '10px' }}
                                    type='email'
                                    placeholder='Enter your email address'
                                    {...register('memberEmail', { required: true, pattern: /^\S+@\S+$/i, message: 'Please type valid email' })}
                                />
                                {errors.memberEmail && (
                                    <FormHelperText error>{errors.memberEmail.message || "This field is required."}</FormHelperText>
                                )}
                            </Box>
                        </Box>
                        <LoadingButton loading={loading} type='submit' variant='contained' size='large' 
                            sx={{ width: "100%", fontWeight: 600, borderRadius: '8px', textTransform: "none" }}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default CreateClient;
