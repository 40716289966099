// Correct way to import in /src/utils/decodeJwt.js
import { jwtDecode } from 'jwt-decode';

// Custom JWT decoding function
export const decodeJwt = (token) => {
  try {
    // Decode the token using jwt-decode
    const decoded = jwtDecode(token);
    return decoded;
  } catch (error) {
    console.error('Invalid token:', error);
    return null;
  }
};
