import React, {useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import {useForm} from 'react-hook-form';

function RejectReasonModal({
                               createRejectReasonModalOpen,
                               rejectReasonData,
                               handleRejectReasonClose,
                           }) {
    const [status, setStatus] = React.useState('');
    const {register, handleSubmit, watch, getValues, setValue, control} = useForm();
    const formData = watch();
    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const handleOnClose = () => {
        handleRejectReasonClose(true);
    };
    const onSubmit = (data) => {
        if (data) {
            rejectReasonData(data.rejectionReason);
            setValue("rejectionReason", null);
            handleRejectReasonClose(true);
        }
    }
    return (
        <Modal
            open={createRejectReasonModalOpen}
            onClose={handleRejectReasonClose}
            sx={{
                backdropFilter: 'blur(15px)',
            }}
        >
            <Box className='create-campaign-modal'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box className='login-logo'>
                                <img
                                    src='/images/rejectCancel.png'
                                    alt='logo'
                                    style={{height: 48}}
                                />
                            </Box>
                            <Box
                                sx={{
                                    cursor: 'pointer',
                                }}
                                className='login-logo'
                                onClick={handleOnClose}
                            >
                                <img
                                    src='/images/close-icon.png'
                                    alt='logo'
                                    style={{height: 48}}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={{mb: '30px'}}>
                                <Typography
                                    htmlFor='rejectionReason'
                                    sx={{fontWeight: 600, fontSize: 30}}
                                    variant='body1'
                                    component='label'
                                >
                                    Reason for rejection
                                </Typography>
                                <Box className='input'>
                                    <TextField
                                        variant='outlined'
                                        sx={{width: '100%', mt: '10px'}}
                                        size='small'
                                        type='text'
                                        multiline
                                        rows={4}
                                        placeholder='Enter your Reason for rejection'
                                        {...register('rejectionReason', {required: true})}
                                    />
                                </Box>
                            </Box>

                            <Button
                                type='submit'
                                variant='contained'
                                size='large'
                                sx={{width: '100%', fontWeight: 600, borderRadius: '8px'}}
                            >
                                Submit
                            </Button>
                        </Box>


                    </Box>
                </form>

            </Box>
        </Modal>
    );
}

export default RejectReasonModal;
