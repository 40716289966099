import React from 'react';
import './Password.scss';
import {
  Box,
  Button,
  Chip,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../../redux/slices/message';
import { setMessageSnackbarStateState } from '../../../redux/slices/common';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import SettingsHeader from '../../../components/common/SettingsHeader/SettingsHeader';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import UserService from '../../../services/api/user.service';
import { useNavigate } from "react-router-dom"; // Routing-related imports
import { getPermission } from "../../../utils/cryptograpgy";





function Password() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [msg, setMsg] = React.useState('');

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const dispatch = useDispatch();

  const navigate = useNavigate()

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (values) => {
    console.log(values);
    const formData = {
      oldPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmNewPassword,
    };
    console.log(formData);
    UserService.changePassword(formData).then((data) => {
      console.log(data);
      dispatch(setMessage({
        type: "success",
        title: data.message
    }));
    dispatch(setMessageSnackbarStateState(true));
    setMsg('');
    if(getPermission("STRETCH_CLIENT")) {
      navigate("/client-dashboard-projects");
    }
    else {
      navigate("/admin-dashboard");
    }
    
    

    }).catch((error) => {
      console.error("Password change failed:", error.response.data.message);
      setMsg(error.response.data.message)
      dispatch(setMessage({
        type: "error",
        title: error.response.data.message[0]
    }));
    dispatch(setMessageSnackbarStateState(true));
    });
  };

  return (
    <Box className='dashboard-container'>
      <SettingsHeader />
      <div className='password-container'>
        <div className='change-pass'>
          <div className='cont-header'>
            <Typography variant='h5' sx={{ fontWeight: 600 }} gutterBottom>
              Password
            </Typography>
            <Typography variant='body1' gutterBottom>
              Please enter your current password to change your password.
            </Typography>
          </div>

          <Divider sx={{ borderRadius: 1 }} />

          <div className='cont-detail'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='input-field'>
                <label style={{ fontWeight: 700 }} htmlFor=''>
                  Current password
                </label>
                <div className='input'>
                  <TextField
                    variant='outlined'
                    sx={{ width: { sm: 200, md: 300, lg: 512 } }}
                    size='small'
                    type='password'
                    {...register('currentPassword', { required: true })}
                    error={!!errors.currentPassword}
                    helperText={errors.currentPassword && 'Current password is required'}
                  />
                </div>
              </div>

              <Divider sx={{ borderRadius: 1 }} />
              <small>Your new password must be more than 8 characters, contain at least one letter, one number, and one special character.</small>

              <div className='input-field'>
                <label style={{ fontWeight: 700 }} htmlFor=''>
                  New password
                </label>
                <div className='input' style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <TextField
                    variant='outlined'
                    sx={{ width: { sm: 200, md: 300, lg: 512 } }}
                    size='small'
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...register('newPassword', {
                      required: 'New password is required',
                      minLength: {
                        value: 8,
                        message: 'Password must be at least 8 characters long',
                      },
                      pattern: {
                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message: 'Password must contain at least one letter, one number, and one special character',
                      },
                    })}
                    error={!!errors.newPassword }
                    helperText={errors.newPassword && errors.newPassword.message }
                  />
                </div>
              </div>

              <Divider sx={{ borderRadius: 1 }} />

              <div className='input-field'>
                <label style={{ fontWeight: 700 }} htmlFor=''>
                  Confirm new password
                </label>
                <div className='input'>
                  <TextField
                    variant='outlined'
                    sx={{ width: { sm: 200, md: 300, lg: 512 } }}
                    size='small'
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge="end"
                          >
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...register('confirmNewPassword', {
                      required: 'Confirm password is required',
                      validate: (val) => {
                        if (watch('newPassword') !== val) {
                          return 'Passwords do not match';
                        }
                      },
                    })}
                    error={!!errors.confirmNewPassword }
                    helperText={errors.confirmNewPassword && errors.confirmNewPassword.message }
                  />
                </div>
              </div>

              <Divider sx={{ borderRadius: 1 }} />

              <div className='button-container'>
                <Button
                  variant='outlined'
                  style={{ border: '1px solid gray', fontWeight: 600, borderRadius: '8px', color: 'black' }}
                  onClick={() =>  
                   { if(getPermission("STRETCH_CLIENT")) {
                    navigate("/client-dashboard-projects");
                  }
                  else {
                    navigate("/admin-dashboard");
                  }}
                }
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  style={{ fontWeight: 600, borderRadius: '8px' }}
                >
                  Update Password
                </Button>
              </div>
            </form>
          </div>
        </div>

        <div className='loggedInContainer'>
          <div className='info-tab'>
            <div>
              <h3>Where you’re logged in</h3>
              <Typography variant='body1' gutterBottom>
                We’ll alert you via{' '}
                <span style={{ fontWeight: 'bold' }}>
                  olivia@untitledui.com
                </span>{' '}
                if there is any unusual activity on your account.
              </Typography>
            </div>
            <div>
              <MoreVertIcon />
            </div>
          </div>

          <Divider sx={{ borderRadius: 1 }} />

          <div>
            <div className='user'>
              <div>
                <DesktopWindowsOutlinedIcon />
              </div>
              <div className='pc-info'>
                <div>
                  <Typography variant='body1' gutterBottom>
                    2018 Macbook Pro 15-inch
                  </Typography>
                </div>
                <div className='status'>
                  <Chip
                    avatar={
                      <FiberManualRecordIcon sx={{ marginRight: '3px', transform: 'scale(0.6)', }} style={{ color: 'green' }} />
                    }
                    label='Active Now'
                    variant='outlined'
                    style={{
                      fontSize: '0.75rem',
                      height: '25px',
                      padding: '5px',
                      borderRadius: '8px',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='location'>
              <div>
                <Typography variant='body1' gutterBottom>
                  Melbourne, Australia • 22 Jan at 10:40am
                </Typography>
              </div>
            </div>
          </div>

          <Divider sx={{ borderRadius: 1 }} />

          <div className='user'>
            <div>
              <DesktopWindowsOutlinedIcon />
            </div>
            <div className='pc-info'>
              <div>
                <Typography variant='body1' gutterBottom>
                  2018 Macbook Pro 15-inch
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default Password;
