import React, { useState, useEffect } from 'react';
import './MyProfile.scss';
import '../Settings.scss'
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import ReactFlagsSelect, { Py } from 'react-flags-select';
import { connect } from 'react-redux';
import SettingsHeader from '../../../components/common/SettingsHeader/SettingsHeader';
import UserService from '../../../services/api/user.service';
import { RHFAutocompleteField } from '../../../components/common/AutoComplete/RHFAutocompleteField';
import CommonFileUploader from '../../../components/common/CommonFileUploader/CommonFileUploader';
import { setUser } from '../../../redux/slices/auth';
import { getPermission } from '../../../utils/cryptograpgy';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../redux/slices/message';
import { setMessageSnackbarStateState } from '../../../redux/slices/common';

const MyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUser = getPermission("STRETCH_CLIENT");
  const mobileScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const tabScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const user = useSelector(state => state.auth.auth.user);
  const [image, setImage] = useState("/images/default-user-icon.jpg");
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [timezonesLoaded, setTimezoneLoaded] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const limit = 200;


  const { register, handleSubmit, watch, getValues, setValue, control } = useForm();

  const onSubmit = (data) => {
    const queryParams = [
      // `companyName=${values.name.trim()}`,
      `firstName:${data.firstName}`,
      `lastName:${data.lastName}`,
      `email=${data.email}`,
      `countryCode=${data.country}`,
      `timezoneId=${data.timezone}`,
      `bio:${data.bio}`,
      `roleId=${data.role}`
    ];

    const profileDataObject = queryParams.reduce((acc, item) => {
      const [key, value] = item.split(':');
      acc[key] = value;
      return acc;
    }, {});

    const formData = new FormData();
    if (data.file) {
      formData.append('file', data.file);

    }
    formData.append('profile-data', JSON.stringify(profileDataObject))
    UserService.updateProfile(formData)
      .then((res) => {
        //  console.log(res);
        if (res) {
          // if (!res.results.profileImage) {
          //   res.results.profileImage = image;
          // }
          dispatch(setMessageSnackbarStateState(true));

          dispatch(setMessage({
            type: "success",
            title: res?.message ? res.message : "Profile updated successfully",
          }));

          redirectToHomepage()
        }
      }).catch((res) => {
        dispatch(setMessageSnackbarStateState(true));

        dispatch(setMessage({
          type: "error",
          title: res?.message ? res.message : "Profile failed to update",
        }));

      })
  }

  const formData = watch();

  useEffect(() => {
    UserService.getTimezones().then((res) => {
      if (res && res.results.length > 0) {
        setTimezoneLoaded(true);
        const allTimezones = [];
        res.results.forEach(t => {
          allTimezones.push({
            name: t.timezoneName,
            id: t.id
          })
        });
        setValue("timezones", allTimezones);
      }
    })
  }, [])

  useEffect(() => {
    // console.log(formData);
  }, [formData])

  const handleFileChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    UserService.getProfile().then((res) => {
      setUserLoaded(true);
      if (res) {
        const userData = res.data;
        setValue("firstName", userData.userFirstName);
        setValue("lastName", userData.userLastName);
        setValue("email", userData.userEmail);
        setValue("country", userData.countryCode);
        setValue("timezone", userData.timezoneId);
        setValue("bio", userData.companyBio);
        setValue("role", isUser ? 3 : 2);
        if (userData.profileImage) {
          setImage(userData.profileImage);
        }
      }
    })
  }, [])

  useEffect(() => {
    UserService.getRoles().then((res) => {
      setRolesLoaded(true);
      if (res && res.results.length > 0) {
        const allRoles = [];
        res.results.forEach(t => {
          allRoles.push({
            name: t.roleName,
            id: t.roleId
          })
        });
        setValue("roles", allRoles);
      }
    })
  }, [])

  const redirectToHomepage = () => {
    if (getPermission("STRETCH_CLIENT")) {
      navigate("/client-dashboard-projects");
    }
    else {
      navigate("/admin-dashboard");
    }
  };

  const handleButtonClick = () => {
    handleSubmit(onSubmit)();
  };


  return (
    userLoaded && timezonesLoaded && rolesLoaded &&
    <Box className='dashboard-container'>
      <SettingsHeader />
      <Box className='profile-container'>
        <Box>
          <Box className='header'>
            <Box className='cont-header'>
              <Typography
                variant='h5'
                style={{ fontWeight: 'bold' }}
                gutterBottom
              >
                Personal info
              </Typography>
              <Typography variant='body1' gutterBottom>
                Update your photo and personal details here.
              </Typography>
            </Box>
            <Box>
              <Box className='button-container'>
                <Button
                  onClick={redirectToHomepage}
                  style={{
                    border: '1px solid gray',
                    fontWeight: 600,
                    borderRadius: '8px',
                    color: 'black',
                  }}
                  variant='outlined'
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleButtonClick}
                  type='submit'
                  variant='contained'
                  style={{
                    fontWeight: 600,
                    borderRadius: '8px',
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>

          <Divider sx={{ borderRadius: 1 }} />

          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Name */}
              <Grid container sx={{ padding: '20px 0' }}>
                <Grid item xs={mobileScreen ? 12 : 3}>
                  <Typography sx={{ fontWeight: 600, color: '#344054' }}>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={mobileScreen ? 12 : 5}>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                      variant='outlined'
                      placeholder='First Name'
                      fullWidth
                      size='small'
                      type='text'
                      {...register('firstName', { required: false })}
                    />

                    <TextField
                      variant='outlined'
                      placeholder='Last Name'
                      fullWidth
                      size='small'
                      type='text'
                      {...register('lastName', { required: false })}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ borderRadius: 1 }} />

              {/* Email */}
              <Grid container sx={{ padding: '20px 0' }}>
                <Grid item xs={mobileScreen ? 12 : 3}>
                  <Typography sx={{ fontWeight: 600, color: '#344054' }}>
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={mobileScreen ? 12 : 5}>
                  <Box className='input'>
                    <TextField
                      variant='outlined'
                      placeholder='Email Address'
                      fullWidth
                      size='small'
                      type='email'
                      {...register('email', { required: false })}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ borderRadius: 1 }} />

              <Grid container sx={{ padding: '20px 0' }}>
                <Grid item xs={mobileScreen ? 12 : 3}>
                  <Typography sx={{ fontWeight: 600, color: '#344054' }}>
                    Your photo
                  </Typography>
                  <Typography>
                    This will be displayed on your profile.
                  </Typography>
                </Grid>
                <Grid item xs={mobileScreen ? 12 : 5}
                 sx={{ display: 'flex', flexDirection:  'column' , alignItems: 'center' , gap: 2 }}>
                  <Box>
                    {image && <img src={image} defaultValue="Profile Image" alt='' />}
                  </Box>
                  <Box>
                    <Controller
                      name="file" // Name of the field
                      control={control}
                      render={({ field }) => (
                        <CommonFileUploader
                          getFiles={(files) => {
                            handleFileChange(files);
                            field.onChange(files);
                          }}
                          fileTypes={["jpg", "png", "gif"]}
                          file={field.value} // Use field.value as the file prop
                          showFileList={false}
                          label="project"
                          containerheight="74"
                          borderColor="#7F56D9"
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* <Divider sx={{ borderRadius: 1 }} />

              <Grid container sx={{ padding: '20px 0' }}>
                <Grid item xs={mobileScreen ? 12 : 3}>
                  <Typography sx={{ fontWeight: 600, color: '#344054' }}>
                    Role
                  </Typography>
                </Grid>
                <Grid item xs={mobileScreen ? 12 : 5}>
                  <Box>
                    <RHFAutocompleteField
                      options={getValues("roles")}
                      control={control}
                      name="role"
                      placeholder="Select role"
                      iconName="search"
                      disabled={isUser}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ borderRadius: 1 }} />

              <Grid container sx={{ padding: '20px 0' }}>
                <Grid item xs={mobileScreen ? 12 : 3}>
                  <Typography sx={{ fontWeight: 600, color: '#344054' }}>
                    Country
                  </Typography>
                </Grid>
                <Grid item xs={mobileScreen ? 12 : 5}>
                  <Box >
                    <Controller
                      control={control}
                      name="country"
                      render={({ field }) => (
                        <ReactFlagsSelect
                          className='menu-flags'
                          searchable
                          fullWidth
                          searchPlaceholder='Search country'
                          id='country'
                          selected={getValues("country")}
                          onSelect={(countryCode) => field.onChange(countryCode)}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ borderRadius: 1 }} />

              <Grid container sx={{ padding: '20px 0' }}>
                <Grid item xs={mobileScreen ? 12 : 3}>
                  <Typography sx={{ fontWeight: 600, color: '#344054' }}>
                    Timezone
                  </Typography>
                </Grid>
                <Grid item xs={mobileScreen ? 12 : 5}>
                  <Box>
                    <RHFAutocompleteField
                      options={getValues("timezones")}
                      control={control}
                      name="timezone"
                      placeholder="Select timezone"
                      iconName="timezone"
                    />
                  </Box>
                </Grid>
              </Grid> */}

              <Divider sx={{ borderRadius: 1 }} />

              {/* Short Bio  */}
              <Grid container sx={{ padding: '20px 0' }}>
                <Grid item xs={mobileScreen ? 12 : 3}>
                  <Typography sx={{ fontWeight: 600, color: '#344054' }}>
                    Bio
                  </Typography>
                  <Typography>
                    Write a short introduction.
                  </Typography>
                </Grid>
                <Grid item xs={mobileScreen ? 12 : 5}>
                  <Box>
                    <TextField
                      {...register('bio', { required: false })}
                      id='outlined-multiline-static'
                      multiline
                      rows={4}
                      fullWidth
                      placeholder='Enter your description here'
                      size='small'
                      inputProps={
                        {
                          maxLength: limit
                        }
                      }
                    />
                    <Typography variant="body2">
                      {getValues("bio")?.length || 0}/{limit}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ borderRadius: 1 }} />

              {/* <Box className='button-container'>
                <Button
                  onClick={redirectToHomepage}
                  variant='outlined'
                  style={{
                    border: '1px solid gray',
                    fontWeight: 600,
                    borderRadius: '8px',
                    color: 'black',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  style={{
                    fontWeight: 600,
                    borderRadius: '8px',
                  }}
                >
                  Save
                </Button>
              </Box> */}

            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MyProfile;
