import React, { useMemo, useState } from 'react';
import { Box, Button, Divider, IconButton, Typography, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SettingsHeader from '../../../../components/common/SettingsHeader/SettingsHeader';
import DoneIcon from '@mui/icons-material/Done';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CardDetail from '../CreditCardDetail/CardDetail';
import Pagination from '../../../../components/common/GenericTable/Pagination/Pagination';

const Billing = () => {

    const [currentPage, setCurrentPage] = useState(1);
    let clientsPageSize = 5;

    const rows = [
        {
            id: 1,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Paid',
        },
        {
            id: 2,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Paid',
        },
        {
            id: 3,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Paid',
        },
        {
            id: 4,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Unpaid',
        },
        {
            id: 5,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Paid',
        },
        {
            id: 6,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Unpaid',
        },
        {
            id: 7,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Paid',
        },
        {
            id: 8,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Unpaid',
        },
        {
            id: 9,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Paid',
        },
        {
            id: 10,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Paid',
        },
        {
            id: 11,
            invoice: 'Basic Plan - Dec 2022',
            amount: 'Big USD $10.00',
            date: 'Dec 1, 2022',
            status: 'Paid',
        },
    ];

    const usersTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * clientsPageSize;
        const lastPageIndex = firstPageIndex + clientsPageSize;
        return rows.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, rows, clientsPageSize]);


    const handleEdit = (e) => {
        e.preventDefault();
    };

    const columns = [
        {
            field: 'invoice',
            headerName: 'Invoice',
            type: 'text',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'text',
            flex: 1,
            minWidth: 130,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'date',
            headerName: 'Date',
            type: 'text',
            minWidth: 130,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 130,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const status = params.value;
                let mainDiv = { display: 'flex', alignItems: 'center' };

                let statusStyle = {};
                if (status === 'Paid') {
                    statusStyle = {
                        border: '1px solid #ABEFC6',
                        background: '#ECFDF3',
                        borderRadius: '20px',
                        color: '#067647',
                        padding: '1px 12px',
                        fontWeight: 500,
                        ...mainDiv,
                    };
                } else if (status === 'Unpaid') {
                    statusStyle = {
                        border: '1px solid #EAECF0',
                        borderRadius: '20px',
                        padding: '3px 13px',
                        fontWeight: 500,
                        ...mainDiv,
                    };
                }
                return (
                    <div style={statusStyle}>
                        <DoneIcon sx={{ marginRight: '3px', transform: 'scale(0.6)' }} />
                        {status}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: '',
            flex: 1,
            minWidth: 80,
            cellClassName: 'actions',
            align: 'center',
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton aria-label='delete' onClick={handleEdit}>
                        <CloudDownloadIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    const getRowClassName = (params) => {
        return params.id % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const mobileScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Box className='dashboard-container'>
            <SettingsHeader />
            <Box>
                <Box sx={{
                    paddingBottom: '20px'
                }}>
                    <Typography variant='h6' sx={{ fontWeight: 700 }}>
                        Payment Methods
                    </Typography>
                    <Typography variant='body1'>
                        Update your billing details and address.
                    </Typography>
                </Box>
                <Divider sx={{ borderRadius: 1 }} />
            </Box>
            <Box>
                <CardDetail />
            </Box>
            <Divider sx={{ borderRadius: 1 }} />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: ['left', 'center'],
                    justifyContent: 'space-between',
                    padding: '20px 0',
                    flexDirection: ['column', 'row'],
                }}
            >
                <Box sx={{ pb: mobileScreen && 2 }}>
                    <Typography variant='h6' sx={{ fontWeight: 700 }}>
                        Billing History
                    </Typography>
                    <Typography variant='body1'>
                        Access all your previous invoices.
                    </Typography>
                </Box>
                <Box>
                    <Button
                        variant='outlined'
                        sx={{ fontWeight: 600, color: '#344054', borderColor: '#D0D5DD', borderRadius: '8px' }}
                        startIcon={<CloudDownloadIcon />}
                    >
                        Download all
                    </Button>
                </Box>
            </Box>
            <Box sx={{ width: '100%', minHeight: 300, marginBottom: 4 }}>
                <DataGrid
                    rows={usersTableData}
                    columns={columns}
                    checkboxSelection
                    disableRowSelectionOnClick
                    getRowClassName={getRowClassName}
                    showPagination={false}
                    slots={{
                        footer: () => (
                            <Box sx={{
                                width: '100%',
                                height: 70,
                                borderTop: '1px solid #EAECF0',
                                p: 2
                            }}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={rows.length}
                                    pageSize={clientsPageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </Box>
                        )
                    }}
                />
            </Box>
        </Box>
    );
}

export default Billing;
