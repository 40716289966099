import { Box, Button, FormControl, FormHelperText, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import '../create-campaign-modal/CreateCampaign.scss'
import ClientService from '../../../services/api/client.service';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageSnackbarStateState } from '../../../redux/slices/common';
import { setMessage } from '../../../redux/slices/message';

function EditClient({ editClientModalOpen, handleEditClientModalClose, selectedClient }) {
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue, reset, control, formState: { errors } } = useForm();
    const user = useSelector(state => state.auth.auth.user);

    useEffect(() => {
        if (selectedClient) {
            setValue('clientName', selectedClient.name);
            setValue('clientEmail', selectedClient.clientEmail);
            setValue('clientStatus', +(selectedClient.status));
        }
    }, [selectedClient, setValue]);

    const handleOnClose = () => {
        handleEditClientModalClose(true);
        reset();
    }

    const onSubmit = (data) => {
        const params = {
            companyName: data?.clientName,
            email: data?.clientEmail
        }

        ClientService.updateClient(selectedClient?.id, params)
            .then(res => {
                dispatch(setMessage({
                    type: "success",
                    title: res
                  }));
                  dispatch(setMessageSnackbarStateState(true));
                  handleOnClose();
            })
            .catch(err => {
                console.error(err)
            })
    }

    return (
        <Modal
            open={editClientModalOpen}
            onClose={handleEditClientModalClose}
            sx={{
                backdropFilter: "blur(15px)",
            }}
        >
            <Box
                className="create-campaign-modal"
            >
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    type='reset'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Box className="login-logo">
                            <img
                                src="/images/featured-icon(edit-client).png"
                                alt="logo"
                                style={{ height: 48 }}
                            />
                        </Box>
                        <Box sx={{
                            cursor: "pointer"
                        }} className="login-logo" onClick={handleOnClose}>
                            <img
                                src="/images/close-icon.png"
                                alt="logo"
                                style={{ height: 48 }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box  sx={{ fontWeight: 600, mb: 4, mt: 1,  fontSize: "30px" }}>
                            Edit Client
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <Box htmlFor='clientName' sx={{ fontWeight: 500, fontSize: "18px", color: "#344054" }}>
                                Client Company Name*
                            </Box>
                            <Box className='input'>
                                <TextField
                                    variant='outlined'
                                    sx={{ width: '100%', mt: '10px' }}
                                    size='small'
                                    type='text'
                                    {...register('clientName')}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ mb: 1, mt: 2, mb: 2 }}>
                        <Box htmlFor='clientName' sx={{ fontWeight: 500, fontSize: "18px", color: "#344054" }}>
                                Client Email Address*
                            </Box>
                            <Box className='input'>
                                <TextField
                                    variant='outlined'
                                    sx={{ width: '100%', mt: '10px' }}
                                    size='small'
                                    type='email'
                                    {...register('clientEmail')}
                                />
                            </Box>
                        </Box>
                        {/* <Box sx={{ mb: 2, mt: 2 }}>
                            <Typography htmlFor='clientStatus' sx={{ fontWeight: 700 }} variant="body1" component="label">
                                Status
                            </Typography>
                            <Box className='input'>
                                <FormControl sx={{ width: '100%', mt: 1 }} size='small'>
                                    <Controller
                                        control={control}
                                        name="clientStatus"
                                        defaultValue=''
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                displayEmpty
                                            >
                                                <MenuItem value="" disabled>
                                                    <em>Select client status</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Active</MenuItem>
                                                <MenuItem value={0}>Deactivated</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Box>
                            {errors.clientStatus && (
                                <FormHelperText error>Please select client status.</FormHelperText>
                            )}
                        </Box> */}
                        <Button type='submit' variant='contained' size='large' sx={{ width: "100%", fontWeight: 600, borderRadius: '8px', textTransform: "none", mt: 4 }}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default EditClient