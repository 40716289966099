import React, { useState, useEffect, useMemo } from 'react';
import SettingsHeader from '../../../components/common/SettingsHeader/SettingsHeader';
import '../Settings.scss';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddMember from '../../../components/modals/add-member-modal/AddMember';
import { getPermission } from '../../../utils/cryptograpgy';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/common/GenericTable/Pagination/Pagination';
import TeamMemberService from '../../../services/api/team-member.service';
import { setIsLoadingState } from "../../../redux/slices/common"
import { useDispatch, useSelector } from "react-redux"

const TeamMember = () => {
    // const [editClientModalOpen, setEditClientModalOpen] = useState(false);
    const [createMemberModalOpen, setCreateMemberModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [selectedMember, setSelectedMember] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [reload, setReLoad] = useState(null);
    const navigate = useNavigate();
    let clientsPageSize = 5;
    const user = useSelector((state) => state.auth.auth.user);

    const clientPageSize = 8
    const [pageQueryParams, setPageQueryParams] = useState({
      page: 0,
      size: clientPageSize,
    })
    const dispatch = useDispatch()

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const isUser = getPermission('STRETCH_CLIENT');
            if (isUser) {
                navigate('/');
            }
        }
        return () => {
            mounted = false;
        };
    }, [navigate]);


    useEffect(() => {
            setLoading(false);
            let queryParams = `page=${pageQueryParams.page}&size=${pageQueryParams.size}`
            dispatch(setIsLoadingState(false))
            TeamMemberService.getTeamMembers(queryParams)
            .then((res) => {
                if (res && res?.data?.content?.length > 0) {
                    setLoading(false);
                    const loadedRows = [];
                    res.data.content.forEach((member) => {
                        loadedRows.push({
                            id: member.id,
                            image: member.profileImageURL,
                            name: member.firstName + ' ' + member.lastName,
                            status: member.enabled,
                            // projectCount: member.projectCount,
                            // campaignCount: member.campaignCount,
                            email: member.email
                        });
                    });
                    setTeamMembers(loadedRows);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [user]);

    // const handleEditClientModalClose = () => {
    //     setEditClientModalOpen(false);
    // };

    const usersTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * clientsPageSize;
        const lastPageIndex = firstPageIndex + clientsPageSize;
        return teamMembers.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, teamMembers, clientsPageSize]);

    const handleCreateMemberModalClose = () => {
        setCreateMemberModalOpen(false);
    };
    const handleOnUpdateSuccessFull = (value) => {
       if(value){
           console.log(value);
           // setReLoad(oldKey => oldKey +1)
       }
    };

    const handleCreate = () => {
        setIsEditMode(false);
        setCreateMemberModalOpen(true);
    };

    const handleEdit = (rowData) => {
        setIsEditMode(true);
        setSelectedMember(rowData);
        setCreateMemberModalOpen(true);
    };

    const handleDelete = () => {
        console.log('Delete Click');
    };

    const columns = [
        {
            field: 'image',
            headerName: '',
            width: 80,
            flex: 0.26,
            renderCell: (params) => (
                <img style={{ border: 'none', borderRadius: '50%', height: '35px', width: '35px', marginLeft:50 }} src={params.value || '/images/default-user-icon.jpg'} alt='' />
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 130,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const status = params.value;
                let mainDiv = { display: 'flex', alignItems: 'center' };

                let statusStyle = {};
                if (status) {
                    statusStyle = {
                        border: '1px solid #ABEFC6',
                        background: '#ECFDF3',
                        borderRadius: '20px',
                        color: '#067647',
                        padding: '1px 12px',
                        fontWeight: 500,
                        ...mainDiv,
                    };
                } else {
                    statusStyle = {
                        border: '1px solid #EAECF0',
                        borderRadius: '20px',
                        padding: '3px 13px',
                        fontWeight: 500,
                        ...mainDiv,
                    };
                }
                return (
                    <div style={statusStyle}>
                        <FiberManualRecordIcon
                            sx={{ marginRight: '3px', transform: 'scale(0.6)' }}
                        />
                        {status === true ? 'Active' : 'Offline'}
                    </div>
                );
            },
        },
        {
            field: 'email',
            headerName: 'Email',
            type: 'email',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        // {
        //     field: 'actions',
        //     headerName: '',
        //     flex: 1,
        //     minWidth: 80,
        //     align: 'center',
        //     headerAlign: 'center',
        //     cellClassName: 'actions',
        //     filterable: false,
        //     sortable: false,
        //     renderCell: (params) => (
        //         <div style={{ display: 'flex', alignItems: 'center' }}>
        //             <IconButton aria-label='edit' onClick={handleDelete}>
        //                 <DeleteOutlinedIcon />
        //             </IconButton>
        //             <IconButton
        //                 aria-label='delete'
        //                 onClick={() => handleEdit(params.row)}
        //             >
        //                 <EditOutlinedIcon />
        //             </IconButton>
        //         </div>
        //     ),
        // }
    ];

    const getRowClassName = (params) => {
        return params.id % 2 === 0 ? 'even-row' : 'odd-row';
    };

    return (
        <Box className='dashboard-container'>
            <SettingsHeader />
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <CircularProgress color='success' size={40} />
                </Box>
            ) : (
                <Box sx={{ width: '100%', mb: 4 }}>
    <Box
        sx={{
            display: 'flex',
            alignItems: ['left', 'center'],
            justifyContent: 'space-between',
            padding: '20px',
            borderBottom: '1px solid #EAECF0',
            flexDirection: ['column', 'row'],
        }}
    >
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    Team Members
                </Typography>
                <Typography
                    sx={{
                        border: '1px solid #E9D7FE',
                        background: '#F9F5FF',
                        borderRadius: '20px',
                        color: '#6941C6',
                        padding: '0 12px',
                        fontWeight: 500,
                    }}
                >
                    {teamMembers?.length} Users
                </Typography>
            </Box>
            <Box>
                <Typography variant="body2">
                    Manage your team members and their account permissions here.
                </Typography>
            </Box>
        </Box>
        <Box>
            <Button
                variant="contained"
                color="primary"
                sx={{ fontWeight: 600, borderRadius: '8px', textTransform: 'none' }}
                onClick={handleCreate}
            >
                + Add Member
            </Button>
        </Box>
    </Box>

    {teamMembers?.length === 0 ? (
        <Box
            sx={{
                textAlign: 'center',
                padding: '20px',
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                mt: 2,
            }}
        >
            <Typography variant="h6" sx={{ fontWeight: 600, color: '#666' }}>
                No team members available
            </Typography>
           
        </Box>
    ) : (
        <DataGrid
            rows={usersTableData}
            columns={columns}
            disableRowSelectionOnClick
            getRowClassName={getRowClassName}
            sx={{ mt: 2 }}
            slots={{
                footer: () => (
                    <Box
                        sx={{
                            width: '100%',
                            height: 70,
                            borderTop: '1px solid #EAECF0',
                            p: 2,
                        }}
                    >
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={teamMembers.length}
                            pageSize={clientsPageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </Box>
                ),
            }}
        />
    )}
</Box>

            )}

            <Box className='modal'>
                <AddMember
                    isEditMode={isEditMode}
                    setTeamMembers = {setTeamMembers}
                    selectedMember={selectedMember}
                    createMemberModalOpen={createMemberModalOpen}
                    handleCreateMemberModalClose={handleCreateMemberModalClose}
                    updateSuccessFull={handleOnUpdateSuccessFull}
                />
            </Box>
        </Box>
    );
};

export default TeamMember;
