import { api, authApi } from "../interceptor/auth.interceptor"

class ProjectService {
  static async getProjectList(formData) {
    return await authApi
      .get("project/name-list?userId=" + formData.userId)
      .then((response) => {
        return response?.data
      })
  }

  static async getAllClientProjectList(queryParams) {
    const url = queryParams
      ? `/client/dashboard/projectList?${queryParams}`
      : `/client/dashboard/projectList`

    return await authApi.get(url).then((response) => {
      return response?.data
    })
  }

  static async getAllProjectList(queryParams) {
    return await authApi
      .get(`/admin/dashboard/clientWiseProjectList?${queryParams}`)
      .then((response) => {
        return response?.data
      })
  }

  static async createProject(formData) {
    return await authApi.post("project/create", formData).then((response) => {
      return response?.data
    })
  }
}

export default ProjectService
