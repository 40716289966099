import React, { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  MenuItem,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Divider,
  FormControl,
  RadioGroup,
  Radio,
  IconButton,
  Link,
} from "@mui/material"
import CampaignService from "../../services/api/campaign.service"
import { setMessage } from "../../redux/slices/message"
import { setMessageSnackbarStateState } from "../../redux/slices/common"
import { useDispatch } from "react-redux"
import PlatformService from "../../services/api/platform.service"
import { LoadingButton } from "@mui/lab"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"
import CloseIcon from "@mui/icons-material/Close"

import { ReactComponent as UploadIcon } from "../../assets/images/Featured icon.svg"
import LocationMap from "../../components/common/map/LocationMap"
import ProjectService from "../../services/api/project.service"

function CreateCampaign() {
  const { handleSubmit, control, reset, getValues, setValue } = useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [projectOption, setProjectOption] = useState("create")
  const [projectOptions, setProjectOptions] = useState([])
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([])
  const [checkboxOptions, setCheckboxOptions] = useState([])
  const [draftCreating, setDraftCreating] = useState(false)
  const [campaignCreating, setCampaignCreating] = useState(false)
  const [loading, setLoading] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [platformFile, setPlatformFile] = useState(null)
  const [locationFile, setLocationFile] = useState(null)
  const [locations, setLocations] = useState([])
  const [competitorFile, setCompetitorFile] = useState(null)
  const [showInput, setShowInput] = useState(false)

  useEffect(() => {
    setLoading(true)
    PlatformService.getAllPlatform().then((data) => {
      setLoading(false)
      if (!data.data) {
        setCheckboxOptions([])
      } else {
        setCheckboxOptions(data.data)
      }
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    ProjectService.getAllClientProjectList().then((data) => {
      setLoading(false)
      const processedData = data.data.content.map((item) => ({
        id: item.projectAndCampaigns.projectId,
        name: item.projectAndCampaigns.projectName,
        startDate: item.projectAndCampaigns.projectStartDate,
        endDate: item.projectAndCampaigns.projectEndDate,
      }))
      setProjectOptions(processedData)
    })
  }, [])

  const handleCheckboxChange = (id) => {
    setSelectedCheckboxIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((checkboxId) => checkboxId !== id)
        : [...prevIds, id]
    )
  }

  const downloadPlatform = () => {
    setDownloading(true)
    PlatformService.downloadPlatformTemplate(selectedCheckboxIds).then(
      (res) => {
        setDownloading(false)
        const blob = new Blob([res], { type: "application/octet-stream" })

        const url = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = url
        link.download = "platform_template.xlsx"
        link.click()

        window.URL.revokeObjectURL(url)
      }
    )
  }

  const handlePlatformFileChange = async (event) => {
    const uploadedFile = event.target.files[0]

    if (uploadedFile) {
      try {
        const res = await PlatformService.validatePlatform(uploadedFile)

        dispatch(
          setMessage({
            type: "success",
            title: res.message,
          })
        )
        dispatch(setMessageSnackbarStateState(true))

        setPlatformFile(uploadedFile)
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred during validation."

        dispatch(
          setMessage({
            type: "error",
            title: errorMessage,
          })
        )
        dispatch(setMessageSnackbarStateState(true))

        setPlatformFile(null)
      }
    } else {
      setPlatformFile(null)
    }
  }

  const handleLocationFileChange = async (event) => {
    const uploadedFile = event.target.files[0]
    if (uploadedFile) {
      try {
        const res = await CampaignService.validateLocation(uploadedFile)
        dispatch(
          setMessage({
            type: "success",
            title: res.message,
          })
        )
        dispatch(setMessageSnackbarStateState(true))
        setLocations(res.data.locationDetails.locationDetails)
        setLocationFile(uploadedFile)
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred during validation."

        dispatch(
          setMessage({
            type: "error",
            title: errorMessage,
          })
        )
        dispatch(setMessageSnackbarStateState(true))

        setLocationFile(null)
      }
    } else {
      setLocationFile(null)
    }
  }

  const handleCompetitorFileChange = async (event) => {
    const uploadedFile = event.target.files[0]
    if (uploadedFile) {
      try {
        const res = await CampaignService.validateCompetitor(uploadedFile)

        dispatch(
          setMessage({
            type: "success",
            title: res.message,
          })
        )
        dispatch(setMessageSnackbarStateState(true))

        setCompetitorFile(uploadedFile)
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred during validation."

        dispatch(
          setMessage({
            type: "error",
            title: errorMessage,
          })
        )
        dispatch(setMessageSnackbarStateState(true))

        setCompetitorFile(null)
      }
    } else {
      setCompetitorFile(null)
    }
  }

  const onSubmit = async (data, isDraft = false) => {
    if (isDraft) {
      setDraftCreating(true)
    } else {
      setCampaignCreating(true)
    }

    const {
      reportingType,
      competitorAnalysis,
      existingProject,
      projectName: fallbackProjectName,
      ...restOfData
    } = data

    const projectId = existingProject?.id ?? null
    const projectName = existingProject?.name ?? fallbackProjectName

    const formatDate = (date) =>
      date ? dayjs(date).format("YYYY-MM-DD") : undefined

    const payload = {
      ...restOfData,
      ...(projectId && { projectId }),
      projectName,
      campaignStartDate: formatDate(restOfData.campaignStartDate),
      campaignEndDate: formatDate(restOfData.campaignEndDate),
      projectStartDate: formatDate(restOfData.projectStartDate),
      projectEndDate: formatDate(restOfData.projectEndDate),
      reportingType,
      competitorAnalysis,
      platformDetailsFile: platformFile,
      locationDetailsFile: locationFile,
      competitorPOIFile: competitorFile,
    }

    const validateFiles = () => {
      if (!platformFile && !isDraft) {
        return "Please upload a platform file"
      }
      if (!locationFile && !isDraft) {
        return "Please upload a location details file"
      }
      if (competitorAnalysis && !competitorFile && !isDraft) {
        return "Competitor analysis is enabled. Please upload a competitor POI file"
      }
      return null
    }

    const errorMessage = validateFiles()
    if (errorMessage) {
      setCampaignCreating(false)
      dispatch(setMessage({ type: "error", title: errorMessage }))
      dispatch(setMessageSnackbarStateState(true))
      return
    }

    try {
      const res = await CampaignService.createCampaign(payload, isDraft)
      if (isDraft) {
        setDraftCreating(false)
      } else {
        setCampaignCreating(false)
      }

      navigate(`/campaigns/${res.data.projectName}/${res.data.projectId}`, {
        replace: true,
      })
      dispatch(setMessage({ type: "success", title: res.message }))
      dispatch(setMessageSnackbarStateState(true))
    } catch (error) {
      if (isDraft) {
        setDraftCreating(false)
      } else {
        setCampaignCreating(false)
      }

      dispatch(
        setMessage({
          type: "error",
          title:
            error.response?.data?.message ||
            "An error occurred while submitting the draft.",
        })
      )
      dispatch(setMessageSnackbarStateState(true))
    }
  }

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data, false))}>
      <Grid
        container
        direction="column"
        sx={{
          minHeight: "100vh",
          paddingX: 9,
          paddingY: 4,
        }}
        padding={9}
      >
        <Grid item padding={2}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: "1.5rem", fontWeight: 700 }}
          >
            Create Campaign
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ fontSize: "0.875rem" }}
          >
            Create the details below
          </Typography>
        </Grid>

        <Grid container item style={{ flexGrow: 1 }} spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box p={2}>
              <Typography variant="h6" sx={{ fontSize: "1.15rem" }}>
                Project Details
              </Typography>
              <Typography variant="body2" gutterBottom>
                Fill the details below
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Box
              p={4}
              sx={{
                border: "1px solid #EAECF0",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TextField
                select
                label="Project Option"
                variant="outlined"
                fullWidth
                value={projectOption}
                onChange={(e) => {
                  setProjectOption(e.target.value)
                  reset({
                    ...getValues(),
                    existingProject: null,
                    projectName: null,
                    projectStartDate: null,
                    projectEndDate: null,
                  })
                }}
                sx={{ marginBottom: 2 }}
              >
                <MenuItem value="create">Create Project</MenuItem>
                <MenuItem value="select">Select from Existing Project</MenuItem>
              </TextField>

              {projectOption === "create" ? (
                <Controller
                  name="projectName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      projectOption === "create"
                        ? "Project Name is required"
                        : false,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Project Name"
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              ) : (
                <Controller
                  name="existingProject"
                  control={control}
                  defaultValue={null}
                  rules={{
                    required:
                      projectOption === "select"
                        ? "Existing Project is required"
                        : false,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      options={projectOptions}
                      getOptionLabel={(option) => option.name || ""}
                      loading={loading}
                      onChange={(event, value) => {
                        field.onChange(value)
                        if (value) {
                          // Set projectStartDate and projectEndDate in the form
                          setValue(
                            "projectStartDate",
                            value.startDate ? dayjs(value.startDate) : null
                          )
                          setValue(
                            "projectEndDate",
                            value.endDate ? dayjs(value.endDate) : null
                          )

                          // Set campaignStartDate and campaignEndDate in the form as same Date as Project
                          setValue(
                            "campaignStartDate",
                            value.startDate ? dayjs(value.startDate) : null
                          )
                          setValue(
                            "campaignEndDate",
                            value.endDate ? dayjs(value.endDate) : null
                          )
                        } else {
                          // Clear dates if no project is selected
                          setValue("projectStartDate", null)
                          setValue("projectEndDate", null)
                          setValue("campaignStartDate", null)
                          setValue("campaignEndDate", null)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Existing Project"
                          variant="outlined"
                          fullWidth
                          sx={{ marginBottom: 2 }}
                          error={!!error}
                          helperText={error ? error.message : ""}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                    />
                  )}
                />
              )}

              {/* Start and End Date Fields in a Single Row */}

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="projectStartDate"
                      control={control}
                      defaultValue={null}
                      rules={{
                        required:
                          projectOption === "select"
                            ? false
                            : "Start Date is required",
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          // disabled={projectOption === "select"}
                          {...field}
                          label="Project Start date"
                          fullWidth
                          sx={{ width: "100%" }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error ? error.message : null,
                            },
                            inputAdornment: {
                              position: "start",
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="projectEndDate"
                      control={control}
                      defaultValue={null}
                      rules={{
                        required:
                          projectOption === "select"
                            ? false
                            : "End Date is required",
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          // disabled={projectOption === "select"}
                          {...field}
                          label="Project End date"
                          fullWidth
                          sx={{ width: "100%" }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error ? error.message : null,
                            },
                            inputAdornment: {
                              position: "start",
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%", margin: 2 }} />

        <Grid container item style={{ flexGrow: 1 }} spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box p={2}>
              <Typography variant="h6" sx={{ fontSize: "1.15rem" }}>
                Campaign Details
              </Typography>
              <Typography variant="body2" gutterBottom>
                Fill the details below
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Box
              p={4}
              sx={{
                border: "1px solid #EAECF0",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Controller
                name="campaignTitle"
                control={control}
                defaultValue=""
                rules={{
                  required: "Campaign Name is required",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Campaign Name"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />

              {/* Start and End Date Fields in a Single Row */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="campaignStartDate"
                      control={control}
                      defaultValue={null}
                      rules={{
                        required: "Start Date is required",
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          {...field}
                          label="Campaign Start date"
                          fullWidth
                          sx={{ width: "100%" }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error ? error.message : null,
                            },
                            inputAdornment: {
                              position: "start",
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="campaignEndDate"
                      control={control}
                      defaultValue={null}
                      rules={{
                        required: "End Date is required",
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          {...field}
                          label="Campaign End date"
                          fullWidth
                          sx={{ width: "100%" }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error ? error.message : null,
                            },
                            inputAdornment: {
                              position: "start",
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%", margin: 2 }} />

        <Grid container item style={{ flexGrow: 1 }} spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box p={2}>
              <Typography variant="h6" sx={{ fontSize: "1.15rem" }}>
                Platform Details
              </Typography>
              <Typography variant="body2" gutterBottom>
                Fill the details below
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={9}>
            {checkboxOptions?.length !== 0 ? (
              <Box
                p={4}
                sx={{
                  border: "1px solid #EAECF0",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Grid container spacing={2}>
                    {checkboxOptions?.map((option) => (
                      <Grid item xs={8} sm={4} key={option.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedCheckboxIds.includes(option.id)}
                              onChange={() => handleCheckboxChange(option.id)}
                            />
                          }
                          label={option.name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}

                <Divider sx={{ width: "100%", mt: 2 }} />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt={2}
                >
                  <LoadingButton
                    sx={{ textTransform: "none" }}
                    loading={downloading}
                    variant="contained"
                    onClick={downloadPlatform}
                  >
                    Download Selected Platform CSV
                  </LoadingButton>
                  <Typography variant="body2" mt={1} textAlign="center">
                    Download our csv template to insert the desired landing
                    pages per platform. Use the same csv to upload{" "}
                  </Typography>
                </Box>
                <Divider sx={{ width: "100%", margin: 2 }} />

                <Button
                  variant="outlined"
                  component="label"
                  sx={{ width: "100%" }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <UploadIcon
                      width={60}
                      height={60}
                      style={{ marginTop: "16px", marginBottom: "16px" }}
                    />

                    {platformFile ? (
                      <Typography variant="body2" textTransform="none" mt={1}>
                        {platformFile.name}
                      </Typography>
                    ) : (
                      <Typography variant="caption" textTransform="none" mt={1}>
                        <span style={{ fontWeight: "bold" }}>
                          Click to upload
                        </span>{" "}
                        or drag and drop CSV
                      </Typography>
                    )}
                  </Box>
                  <input
                    type="file"
                    hidden
                    onChange={handlePlatformFileChange}
                  />
                </Button>

                <Box display="flex" justifyContent="flex-end" mt={1}>
                  <Link
                    href="/templates/platform-template.csv"
                    download
                    variant="caption"
                    underline="hover"
                  >
                    Download Template
                  </Link>
                </Box>
              </Box>
            ) : (
              <Typography
                variant="body1"
                p={4}
                sx={{
                  border: "1px solid #EAECF0",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              >
                No Platforms Available! Contact Admin to add Platforms.
              </Typography>
            )}
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%", margin: 2 }} />

        <Grid container item style={{ flexGrow: 1 }} spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box p={2}>
              <Typography variant="h6" sx={{ fontSize: "1.15rem" }}>
                Location Details
              </Typography>
              <Typography variant="body2" gutterBottom>
                Fill the details below
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Box
              p={4}
              sx={{
                border: "1px solid #EAECF0",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="h6" fontSize="large" marginBottom={2}>
                Upload desired Points of Interest for Footfall Attribution
              </Typography>

              {locations && locationFile ? (
                <Box sx={{ marginTop: "8px", marginBottom: "8px" }}>
                  <LocationMap locations={locations} />
                  <Typography
                    variant="body2"
                    textTransform="none"
                    mt={1}
                    display="flex"
                    alignItems="center"
                    sx={{ backgroundColor: "#f5f5f5", p: 1, borderRadius: 1 }}
                  >
                    <span
                      style={{
                        flex: 1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {locationFile.name}
                    </span>
                    <IconButton
                      size="small"
                      aria-label="remove file"
                      onClick={() => setLocationFile(null)}
                      sx={{ ml: 1 }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Typography>
                </Box>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ width: "100%" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <UploadIcon
                        width={60}
                        height={60}
                        style={{ marginTop: "16px", marginBottom: "16px" }}
                      />
                      <Typography variant="caption" textTransform="none" mt={1}>
                        <span style={{ fontWeight: "bold" }}>
                          Click to upload
                        </span>{" "}
                        or drag and drop CSV
                      </Typography>
                    </Box>
                    <input
                      type="file"
                      hidden
                      onChange={handleLocationFileChange}
                    />
                  </Button>

                  <Box display="flex" justifyContent="flex-end" mt={1}>
                    <Link
                      href="/templates/location-template.csv"
                      download
                      variant="caption"
                      underline="hover"
                    >
                      Download Template
                    </Link>
                  </Box>
                </>
              )}

              <Box display="flex" flexDirection="column">
                <Typography variant="h6" fontSize="large" marginTop={2}>
                  Reporting Type
                </Typography>
                <FormControl component="fieldset" sx={{ marginTop: 1 }}>
                  <Controller
                    name="reportingType"
                    control={control}
                    defaultValue="standard"
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="standard"
                          control={<Radio />}
                          label="Standard"
                        />
                        <FormControlLabel
                          value="enriched"
                          control={<Radio />}
                          label="Enrich Report"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>

                <Divider sx={{ width: "100%", mt: 2 }} />
                <Typography variant="h6" fontSize="large" marginTop={2}>
                  Competitor Analysis
                </Typography>

                <FormControl component="fieldset" sx={{ marginTop: 1 }}>
                  <RadioGroup
                    row
                    defaultValue="no"
                    name="competitorAnalysis"
                    onChange={(e) => {
                      const isYes = e.target.value === "yes"
                      setValue("competitorAnalysis", isYes)
                      setShowInput(isYes)
                      setCompetitorFile(null)
                    }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>

                {showInput && (
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ width: { xs: "100%", sm: "50%" } }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      mt={2}
                      mb={2}
                    >
                      <UploadIcon width={40} height={40} />

                      {competitorFile ? (
                        <Typography variant="body2" textTransform="none">
                          {competitorFile.name}
                        </Typography>
                      ) : (
                        <Typography variant="caption" textTransform="none">
                          <span style={{ fontWeight: "bold" }}>
                            Click to upload
                          </span>{" "}
                          POIs of competitors as a CSV
                        </Typography>
                      )}
                    </Box>

                    <input
                      type="file"
                      hidden
                      onChange={handleCompetitorFileChange}
                    />
                  </Button>
                )}
              </Box>
              <Divider sx={{ width: "100%", mt: 2 }} />
              <Box
                display="flex"
                justifyContent={{ xs: "center", sm: "flex-end" }}
                flexDirection={{ xs: "column", sm: "row" }}
                gap={{ xs: 2, sm: 5 }}
                marginTop={2}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    textTransform: "none",
                    width: { xs: "100%", sm: "auto" },
                  }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={draftCreating}
                  variant="outlined"
                  color="primary"
                  sx={{
                    textTransform: "none",
                    width: { xs: "100%", sm: "auto" },
                  }}
                  onClick={handleSubmit((data) => onSubmit(data, true))}
                >
                  Save as Draft
                </LoadingButton>
                <LoadingButton
                  loading={campaignCreating}
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "none",
                    width: { xs: "100%", sm: "auto" },
                  }}
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default CreateCampaign
