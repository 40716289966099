import CheckCircle from '@mui/icons-material/CheckCircle';

const CustomStepIcon = (props) => {
    const { active, completed } = props;
  
    return (
      <div
        style={{
          width: 24,
          height: 24,
          borderRadius: '50%',
          backgroundColor: 'white',
          border: `7px solid ${completed ? '#6927DA' : active ? '#6927DA' : 'grey'}`,
          color: completed ? '#6927DA' : active ? '#6927DA' : 'grey',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {completed ? <CheckCircle /> : null}
      </div>
    );
};

export default CustomStepIcon;