import axios from 'axios';
import {apiUrl, accessToken} from '../../config/config';
import {getCookie} from '../../utils/cookie';

const api = axios.create({
    baseURL: apiUrl,
});

const authApi = axios.create({
    baseURL: apiUrl,
    headers: {
        "Accept-Language": 'en-US',
    },
});
authApi.interceptors.request.use(
    async (config) => {
        let accessTokenNew = getCookie(accessToken);
        config.headers.Authorization = `Bearer ${accessTokenNew}`;
        return config;
    },
    (err) => {
        console.error(err);
        return Promise.reject(err);
    },
);
export {api, authApi};
