// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClientDashboard_primaryTitle__fbDQ3 {\n  font-weight: 600 !important;\n  font-size: 30px !important;\n  line-height: 38px !important;\n  color: #000000 !important;\n}\n\n.ClientDashboard_secondaryTitle__8JPVJ {\n  font-weight: 500 !important;\n  font-size: 16px !important;\n  line-height: 24px !important;\n  color: #1D2939 !important;\n}\n\n.ClientDashboard_boxFlex__z9P8F {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.ClientDashboard_rightBox__yZYhX {\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n}", "",{"version":3,"sources":["webpack://./src/pages/client-dashboard/ClientDashboard.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,0BAAA;EACA,4BAAA;EACA,yBAAA;AACF;;AACA;EACE,2BAAA;EACA,0BAAA;EACA,4BAAA;EACA,yBAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEF;;AAAA;EACE,aAAA;EACA,qBAAA;EACA,yBAAA;AAGF","sourcesContent":[".primaryTitle{\n  font-weight: 600!important;\n  font-size: 30px!important;\n  line-height: 38px!important;\n  color: #000000!important;\n}\n.secondaryTitle{\n  font-weight: 500!important;\n  font-size: 16px!important;\n  line-height: 24px!important;\n  color: #1D2939!important;\n}\n\n.boxFlex {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.rightBox{\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTitle": "ClientDashboard_primaryTitle__fbDQ3",
	"secondaryTitle": "ClientDashboard_secondaryTitle__8JPVJ",
	"boxFlex": "ClientDashboard_boxFlex__z9P8F",
	"rightBox": "ClientDashboard_rightBox__yZYhX"
};
export default ___CSS_LOADER_EXPORT___;
