import React from 'react';
import { Grid, Box, Stack, Avatar, Typography, Chip } from '@mui/material';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

// Reusable component for status chip
const StatusChip = ({ status }) => (
  <Chip
    label={status ? 'Active' : 'Inactive'}
    sx={{
      backgroundColor: status ? '#039855' : '#344054',
      color: '#ffffff',
      width: 'fit-content',
    }}
  />
);

// Reusable component for project/campaign information
const InfoBox = ({ title, value, Icon }) => (
  <Box>
    <Typography variant="body2" color="#667085">
      {title}
    </Typography>
    <Stack direction="row" spacing={1} alignItems="center" mt={1}>
      <Icon sx={{ fontSize: 20 }} />
      <Typography variant="body2">{value}</Typography>
    </Stack>
  </Box>
);

const ClientList = ({ clientList, redirectToProjectListView }) => {
  return (
    <>
      {clientList.map((item) => (
        <Grid item xs={12} sm={6} md={3} key={item.companyId}> 
          <Box
            sx={{
              p: 3,
              cursor: 'pointer',
              border: '1px solid #EAECF0',
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => redirectToProjectListView(item)}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar sx={{ width: 56, height: 56 }} alt={item.companyName} src={item.profileImageURL || item.companyLogo} />
              <Typography variant="body1">{item.companyName}</Typography>
            </Stack>

            <Stack spacing={1} mt={2} mb={2}>
              <StatusChip status={item.isActive} />
            </Stack>

            <Stack direction="row" justifyContent="space-between" mt={3}>
              <InfoBox title="Number of Projects" value={item.totalProjects} Icon={LaptopChromebookIcon} />
              <InfoBox title="Number of Campaigns" value={item.totalCampaigns} Icon={LayersOutlinedIcon} />
            </Stack>
          </Box>
        </Grid>
      ))}
    </>
  );
};

export default ClientList;
