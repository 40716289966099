import { getCookie } from '../../utils/cookie';
import { authApi } from '../interceptor/auth.interceptor';

class TeamMemberService {

  static async getTeamMembers(queryParams) {
    return await authApi
      .get(`admin/dashboard/allMember?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async createTeamMember(queryParams) {
    try {
      const response = await authApi.post(`/registration`, queryParams, {
        headers: {
          'registration-for': 'member', // Custom header
        },
      });
      
      return response.data; // Directly return the data from the response
    } catch (error) {
      console.error('Error creating team member:', error);
      throw error; // Re-throw the error after logging it
    }
  }
  


  static async updateTeamMember(userId, formData) {
    return await authApi
      .put(`member/edit?memberId=${userId}`, formData)
      .then((response) => {
        return response?.data;
      });
  }

  static async getMembersDashboardData() {
    return await authApi
      .get(`admin/dashboard/userCountDetail`)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error('Error fetching member dasboard:', error);

        const mockData = {
          "statusCode": 1,
          "results": {
              "totalClientCount": 9,
              "totalMembersCount": 5,
              "totalActive": 14
          },
          "message": "Members are Available!"
      }

      return mockData
      });
  }
}
export default TeamMemberService;
