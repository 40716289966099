import React, { useEffect } from 'react';
import { Box } from "@mui/material";
import "./CommonFileUploader.scss";
import { FileUploader } from '../GenericFileUploader/react-drag-drop-files';

const CommonFileUploader = (params) => {
  const { fileTypes, getFiles, label, file, borderColor, containerheight, showFileList,
     isTragetPois, isCompetitorPois, targetPoisName, competitorPoisName, disabled } = params;
    //  useEffect(() => {
    //   // console.log(file, isTragetPois, targetPoisName);
    //  }, [file])

  const handleChange = (file) => {
  //  console.log(file, showFileList);
    getFiles(file);
  }

  return (<>
    <Box className='image-upload'>
      <Box className='upload-container'>
        <Box sx={{
          height: `${containerheight}px`,
          border: `2px solid ${borderColor}`
        }} className='upload-field'>
          <FileUploader
            handleChange={handleChange}
            name='Myfile'
            label={label}
            // types={["csv"]}
            types={fileTypes}
            disabled={disabled}
          />
        </Box>
        {
          showFileList && (file?.name ||  (isTragetPois && targetPoisName) || (isCompetitorPois && competitorPoisName) ) && <Box className="upload-field" sx={{
            display: "flex",
            alignItems: "center",
            padding: 2,
            minHeight: "60px",
            width: "100%",
            border: "1px solid #EAECF0"
          }} >
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                width: "90%",
                gap: 2
              }}>
                <Box className="login-logo">
                  <img
                    src= {fileTypes.includes("csv") ? "/images/csv_icon.png" : "/images/jpg_icon.png"}
                    alt="logo"
                    style={{ height: 40, width: 32 }}
                  />
                </Box>
                <Box>
                  <Box>{file?.name?.substring(0, 20) || (isTragetPois && targetPoisName) || (isCompetitorPois && competitorPoisName)}</Box>
                  { file.size && <Box>{file.size ? file.size + " KB": ""}</Box> }
                </Box>
              </Box>
              
              <Box onClick={handleChange} sx={{
                cursor: "pointer"
              }} className="login-logo">
                  <img
                    src="/images/deleteIcon.png"
                    alt="logo"
                    style={{height: 16, width: 16}}
                  />
                </Box>
            </Box>

          </Box>
        }

      </Box>
    </Box>
  </>)
}

export default CommonFileUploader;