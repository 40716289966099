// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CampaignList_TextTitle__NO7CR {\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 30px;\n  color: #101828;\n}\n\n.CampaignList_DateTextTitle__qlGvS {\n  font-weight: 500;\n  font-size: 12px;\n  line-height: 18px;\n  color: #475467;\n}\n\n.CampaignList_DateText__LpIJ3 {\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  color: #101828;\n}\n\n.CampaignList_ViewBtn__DbMYv {\n  font-weight: 600 !important;\n  font-size: 14px !important;\n  line-height: 20px !important;\n  text-transform: none !important;\n}\n\n.CampaignList_pagination-bar__ybY9u {\n  width: 100%;\n  margin-top: 10px;\n  display: flex;\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/campaign-list/CampaignList.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEF;;AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAGF;;AADA;EACE,2BAAA;EACA,0BAAA;EACA,4BAAA;EACA,+BAAA;AAIF;;AAFA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;AAKF","sourcesContent":[".TextTitle{\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 30px;\n  color: #101828;\n}\n.DateTextTitle{\n  font-weight: 500;\n  font-size: 12px;\n  line-height: 18px;\n  color: #475467;\n}\n.DateText{\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  color: #101828;\n}\n.ViewBtn{\n  font-weight: 600!important;\n  font-size: 14px!important;\n  line-height: 20px!important;\n  text-transform: none!important;\n}\n.pagination-bar {\n  width: 100%;\n  margin-top: 10px;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextTitle": "CampaignList_TextTitle__NO7CR",
	"DateTextTitle": "CampaignList_DateTextTitle__qlGvS",
	"DateText": "CampaignList_DateText__LpIJ3",
	"ViewBtn": "CampaignList_ViewBtn__DbMYv",
	"pagination-bar": "CampaignList_pagination-bar__ybY9u"
};
export default ___CSS_LOADER_EXPORT___;
