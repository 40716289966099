import React, {useState} from "react";
import {
    Box,
    Modal,
    Typography,
} from '@mui/material';
import './confirmationModal.scss';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import RejectReasonModal from "../RejectionModal/rejectionModal";

const ConfirmationModal = ({
                               isApprovedMode,
                               confirmationModalOpen,
                               rejectReason,
                               handleConfirmationModalClose,
                               handleIfConfirmationApproved,
                           }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [status, setStatus] = useState();
    const [rejectReasonModalOpen, setRejectReasonModalOpen] = useState(false);

    const handleApprovedStatus = () => {
        handleIfConfirmationApproved(true)
        handleConfirmationModalClose(true);
    };
    const handleRejectStatus = () => {
        handleConfirmationModalClose(true);
        setRejectReasonModalOpen(true);
    };

    const handleOnClose = () => {
        handleConfirmationModalClose(true);
    };
    const handleOnRejectReasonModalClose = () => {
        setRejectReasonModalOpen(false);
    };

    const handelRejectReasonData = (data) => {
        if(data){
            rejectReason(data);
            setRejectReasonModalOpen(false);
        }
    };
    return (
        <>
        <Modal
            open={confirmationModalOpen}
            onClose={handleConfirmationModalClose}
            sx={{
                backdropFilter: 'blur(15px)',
            }}
        >
            <Box className='confirmation_modal'>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {isApprovedMode ? (
                            <Box className='login-logo'>
                                <img
                                    src='/images/approved.png'
                                    alt='logo'
                                    style={{height: 48}}
                                />
                            </Box>
                        ) : (
                            <Box className='login-logo'>
                                <img
                                    src='/images/reject.png'
                                    alt='logo'
                                    style={{height: 48}}
                                />
                            </Box>
                        )}
                        <Box
                            sx={{
                                cursor: 'pointer',
                            }}
                            className='login-logo'
                            onClick={handleOnClose}
                        >
                            <img
                                src='/images/close-icon.png'
                                alt='logo'
                                style={{height: 48}}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant='h6' sx={{fontWeight: 600, fontSize: 18}}>
                            {isApprovedMode === true ? 'Do you want to approve the campaign ' : 'Do you want to reject the campaign'}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='h6' sx={{fontWeight: 400, fontSize: 14, mb: '30px'}}>
                            {isApprovedMode === true ? 'Campaign has been published. Team members will be able to edit this post and republish changes.' :
                                'Campaign has been rejected. Team members will be able to edit this post and republish changes.'}
                        </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1, marginTop: '36px'}}>
                        <Grid container spacing={2} columns={16}>
                            <Grid item xs={8}>
                                <button className="btn_cancel_approved" onClick={handleOnClose}>
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Box className="btn_cancel_approved_text">Cancel</Box>
                                    </Stack>
                                </button>
                            </Grid>
                            <Grid item xs={8}>
                                {
                                    isApprovedMode === true ?


                                        <button className="btn_confirm_approved" onClick={handleApprovedStatus}>
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Box className="btn_confirm_approved_text">Approve</Box>
                                            </Stack>
                                        </button>
                                        :
                                        <button className="btn_reject_approved" onClick={handleRejectStatus}>
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Box className="btn_reject_approved_text">Reject</Box>
                                            </Stack>
                                        </button>

                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>

            <Box className="modal">
                <RejectReasonModal
                    createRejectReasonModalOpen={rejectReasonModalOpen}
                    rejectReasonData={handelRejectReasonData}
                    handleRejectReasonClose={handleOnRejectReasonModalClose} >
                </RejectReasonModal>
            </Box>
            </>

    );
}

export default ConfirmationModal;
