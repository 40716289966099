import LoginLayout from '../components/layouts/auth-layout/AuthLayout';
import Error404 from '../pages/error/Error404';
import Login from '../pages/login/Login';
import Signup from '../pages/signup/Signup';
import DashboardLayout from '../components/layouts/dashboard-layout/DashboardLayout';
import EditCampaign from '../pages/campaign/edit-campaign/EditCampaign';
import MyProfile from '../pages/Settings/MyProfile/MyProfile';
import Password from '../pages/Settings/Password/Password';
import Clients from '../pages/Settings/Clients/Clients';
import TeamMember from '../pages/Settings/TeamMember/TeamMember';
import Notifications from '../pages/Settings/Notifications/Notifications';
import ProjectListView from "../pages/admin-dashboard/projectListView";
import AdminReports from "../pages/admin-dashboard/AdminReports";
import CampaignListView from "../pages/admin-dashboard/campaignListView";
import AdminDashboard from "../pages/admin-dashboard/AdminDashboard";
import ForgetPassword from '../pages/forget-password/ForgetPassword';
import ResetPassword from '../pages/reset-password/ResetPassword';
import Billing from '../pages/Settings/Billing/Billing/Billing';
import ClientDashboard from '../pages/client-dashboard/ClientDashboard';
import ClientReports from '../pages/client-dashboard/ClientReport';
import CampaignList from '../pages/campaign-list/CampaignList';
import CreateCampaign from '../pages/campaign/CreateCampaign';

const routes = [
    {
        path: '', layout: DashboardLayout, children: [
            {path: '/admin-dashboard', name: 'Admin Dashboard', exact: true, element: AdminDashboard},
            // {path: '/client-dashboard-campaigns', name: 'Client Dashboard campaigns', exact: true, element: ClientDashboard},
            // {path: '/client-dashboard-projects', name: 'Client Dashboard Project', exact: true, element: ClientDashboardProjectList},
            {path: '/client-dashboard-projects', name: 'Client Dashboard Project', exact: true, element: ClientDashboard},
            {path: '/project-view/:clientId/:clientName', name: 'Project List View', exact: true, element: ProjectListView},
            {path: '/admin-reports', name: 'Admin Reports', exact: true, element: AdminReports},
            {path: '/client-reports', name: 'Client Reports', exact: true, element: ClientReports},
            {path: '/campaign-list-view/:clientId/:projectId/:projectName', name: 'campaign List View', exact: true, element: CampaignListView},
            {path: '/campaigns/:projectName/:projectId/', name: 'Campaigns', exact: true, element: CampaignList},
            {path: '/edit-campaign/:campaignId', name: 'Edit Campaign', element: EditCampaign},
            {path: '/settings/my-profile', name: 'My Profile', element: MyProfile},
            {path: '/settings/clients', name: 'Clients', element: Clients},
            {path: '/settings/password', name: 'Password', element: Password},
            {path: '/settings/team-member', name: 'Team Member', element: TeamMember},
            {path: '/settings/billing', name: 'Billing', element: Billing},
            {path: '/settings/notifications', name: 'Notifications', element: Notifications},
            {path: '/create-campaign', name: 'CreateCampaign', element: CreateCampaign}
        ]
    },
    {
        path: '', layout: LoginLayout, children: [
            {path: '/', exact: true, name: 'Login', element: Login},
            {path: '/signup', name: "Sign up", element: Signup},
            {path: '/forget-password', name: 'Forgot Password', element: ForgetPassword},
            {path: '/reset-password/:token', name: 'Reset Password', element: ResetPassword}
        ]
    },
    {path: '/404', name: 'Error404', element: Error404},
    {path: '*', name: 'Error404', element: Error404},
];

export default routes;
