import React from "react"
import {
  Grid,
  Box,
  Stack,
  Avatar,
  Typography,
  Chip,
  Button,
  Divider,
} from "@mui/material"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import { useNavigate } from "react-router-dom"
import moment from "moment"

// Reusable styles for campaign status
const campaignRemarkStyles = {
  ENDED: { label: "Ended", background: "#344054" },
  RUNNING: { label: "Running", background: "#039855" },
  UNDER_REVIEW: { label: "Under Review", background: "#E04F16" },
  DRAFT: { label: "Draft", background: "#444CE7" },
  PENDING: { label: "Pending", background: "#344054" },
  REJECT: { label: "Reject", background: "#B00020" },
  DEFAULT: { label: "Paused", background: "#344054" },
}

const CardComponent = ({ data }) => {
  const navigate = useNavigate()

  // Map over data here to generate the list of campaign cards
  return data.map((item) => {
    const remark = item.projectAndCampaigns.campaignRemark || "DEFAULT"
    const { label, background } =
      campaignRemarkStyles[remark] || campaignRemarkStyles.DEFAULT

    const handleNavigate = () => {
      navigate(
        `/campaign-list-view/${item.projectAndCampaigns.companyId}/${item.projectAndCampaigns.projectId}/${item.projectAndCampaigns.projectName}`
      )
    }

    return (
      <Grid item xs={12} sm={6} md={3} key={item.projectAndCampaigns.projectId}>
        <Box
          sx={{
            p: 3,
            border: "1px solid #EAECF0",
            borderRadius: 2,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
          }}
        >
          {/* Header */}
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Avatar
                sx={{ width: 56, height: 56 }}
                alt={item.projectAndCampaigns.companyName}
                src={item.projectImageUrl}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="body1">
                {item.projectAndCampaigns.companyName}
              </Typography>
            </Grid>
          </Grid>

          {/* Campaign Details */}
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item>
              <Typography variant="h6" fontWeight={700}>
                {item.projectAndCampaigns.projectName}
              </Typography>
              <Chip
                label={label}
                sx={{
                  backgroundColor: background,
                  color: "#fff",
                  mt: 1,
                }}
              />
            </Grid>
          </Grid>

          {/* Dates */}
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <Typography variant="caption" color="text.secondary">
                Starting Date
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mt: 0.5 }}
              >
                <CalendarTodayIcon fontSize="small" />
                <Typography variant="body2">
                  {moment(item.projectAndCampaigns.projectStartDate).format(
                    "DD/MM/YYYY"
                  )}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="text.secondary">
                End Date
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mt: 0.5 }}
              >
                <CalendarTodayIcon fontSize="small" />
                <Typography variant="body2">
                  {moment(item.projectAndCampaigns.projectEndDate).format(
                    "DD/MM/YYYY"
                  )}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          {/* Footer */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                variant="text"
                sx={{ color: "#344054", textTransform: "none" }}
                onClick={handleNavigate}
              >
                Pending Campaigns
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="body2" fontWeight={500}>
                {item.projectAndCampaigns.totalCampaigns}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    )
  })
}

export default CardComponent
