import React, { useState, useMemo } from "react" // React and hooks
import { Box, styled } from "@mui/material" // MUI components
import Pagination from "./Pagination/Pagination" // Pagination component

// Styled row component using Box and Flexbox
const StyledTableRow = styled(Box)(() => ({
  display: "flex", 
  alignItems: "center",
  flexWrap: "wrap",
  "&:hover": {
    backgroundColor: "#fafafa",
  },
  cursor: "pointer",
}));

// Wrapper to render the custom cell templates
function Wrapper({ template: CompanyTemplate, row }) {
  return <CompanyTemplate row={row} />
}

// Generic Table component
const GenericTable = ({
  tableConfig,
  tableData,
  totalRecords,
  getCurrentPageNumber,
  onRowClick,
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const currentTableData = useMemo(() => {
    return tableData
  }, [tableData])

  const handelOnChangePage = (page) => {
    if (page) {
      setCurrentPage(page)
      getCurrentPageNumber(page)
    }
  }

  return (
    <Box
      p={4}
      sx={{
        border: "1px solid #EAECF0",
        borderRadius: "8px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        overflowX: "auto",
      }}
    >
      {/* Table Header using Flexbox */}
      <Box>
        {tableConfig &&
          tableConfig.ShowHeaderTemplate &&
          tableConfig.Columns.map((column, index) => (
            <Box key={index} sx={{ flex: 1, textAlign: "left", padding: "0 16px" }}>
              {column.Title}
            </Box>
          ))}
      </Box>

      {/* Table Body using Flexbox for rows */}
      {currentTableData.map((row, rowIndex) => (
        <StyledTableRow key={rowIndex} onClick={() => onRowClick(row)} sx={{ display: "flex" }}>
          {tableConfig &&
            tableConfig.Columns.map((column, colIndex) => (
              <Box key={colIndex} sx={{ flex: 1, padding: "16px", wordWrap: "break-word" }}>
                {column.CellTemplate ? (
                  <Wrapper template={column.CellTemplate} row={row} />
                ) : (
                  row[column.Key]
                )}
              </Box>
            ))}
        </StyledTableRow>
      ))}

      {/* Pagination */}
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalRecords}
        pageSize={tableConfig.PageSize}
        onPageChange={(page) => handelOnChangePage(page)}
      />
    </Box>
  )
}

export default GenericTable
