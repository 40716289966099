import React from 'react'
import { Box, useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom'
import './SettingsHeader.scss'
import { getPermission } from '../../../utils/cryptograpgy';
import SearchComponent from '../SearchComponent/searchComponent';

function SettingsHeader() {
  const routes = ['/settings/my-profile', '/settings/clients', '/settings/password', '/settings/team-member', '/settings/billing', '/settings/notifications'];
  const isUser = !getPermission("STRETCH_CLIENT");

  const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <Box sx={{ my: '20px', display: 'flex', flexDirection: mediumScreen ? 'column' : 'row', alignItems: 'start', justifyContent: 'space-between' }}>
      <Box>
        <h1>Settings</h1>
        <Box className='settingHeader'>

          <NavLink to={routes[0]} className={({ isActive }) => isActive ? "active" : ""}>
            My Profile
          </NavLink>
          {
            isUser && <NavLink to={routes[1]} className={({ isActive }) => isActive ? "active" : ""}>
              Clients
            </NavLink>
          }

          {
            isUser &&
            <NavLink to={routes[3]} className={({ isActive }) => isActive ? "active" : ""}>
              Team Member
            </NavLink>
          }

          <NavLink to={routes[4]} className={({ isActive }) => isActive ? "active" : ""}>
            Billing
          </NavLink>
          <NavLink to={routes[5]} className={({ isActive }) => isActive ? "active" : ""}>
            Notifications
          </NavLink>
          <NavLink to={routes[2]} className={({ isActive }) => isActive ? "active" : ""}>
            Password
          </NavLink>
        </Box>
      </Box>

      <Box sx={{ mt: mediumScreen ? 2 : 0, ml: `${mediumScreen} && 0 !important,` }}>
        <SearchComponent />
      </Box>
    </Box>
  )
}

export default SettingsHeader