import React, { useState, useEffect } from "react";
import { Alert, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import AuthService from "../../services/api/auth.service";
import { login } from "../../redux/slices/auth";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import {
  errorMessage
} from "../../config/config";
import { getPermission } from "../../utils/cryptograpgy";

const ForgetPassword = ({
  refreshTokenFromRedux,
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (refreshTokenFromRedux) {
        // console.log("refreshTokenFromRedux", refreshTokenFromRedux);
        setTimeout(() => {
          if(getPermission("STRETCH_CLIENT")) {
            navigate("/client-dashboard-projects");
          }
          else {
            navigate("/admin-dashboard");
          }
        }, 100);
      } else {
        setIsLoaded(true);
      }
    }
    return () => {
      mounted = false;
    };
  }, [refreshTokenFromRedux, navigate]);


  const onSubmit = (values) => {
    console.log("values", values);
    
    const formData = {
      email: values.email
    };

    setIsSubmitting(true);
    AuthService.forgetPassword(formData.email).then(
      (data) => {
        setIsSubmitting(false);
        // console.log('login', data?.results);
        if (data) {
            reset();
            setResponseMessage({
              type: "success",
              message: data.message
                ? data.message 
                : "Requested Successfully."
            });
          
        } else {
          setResponseMessage({
            type: "error",
            message: errorMessage,
          });
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("Error Message", error.response.data.message);
        setResponseMessage({
          type: "error",
          message: error?.response?.data?.message
            ? error.response.data.message
            : errorMessage,
        });
        setIsSubmitting(false);
        return Promise.reject();
      }
    );
  };

  return (
    <>
      {isLoaded && (
        <Box sx={{display: 'flex'}} className="">
          <Box className="flex-container" sx={{
            display: "flex",
            flexDirection: "column"
          }}>
            {(
              <Box sx={{
                maxWidth: "360px"
              }} className="form-container">
                <Box sx={{ mb: [0, 5] }}>
                  <Box className="login-logo">
                    <img
                     src="/images/stretchNew.png"
                      alt="logo"
                      style={{ height: 82 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "44px",
                      mt: 3,
                    }}
                    className="logo-title"
                  >
                   Forgot Password
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "px",
                      mt: 1,
                      color: '#98A2B3'
                    }}
                   
                  >
                   Please enter your email to receive a password reset link.
                  </Box>
                </Box>
                {/* <Divider color={"grey"} /> */}
                {responseMessage && (
                  <Alert
                    severity={responseMessage.type}
                    variant="filled"
                    sx={{ mt: 1 }}
                  >
                    <strong>{responseMessage.message}</strong>
                  </Alert>
                )}
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Box> 
                    <Box className="small-sub">
                        Email
                    </Box>
                    <TextField
                        required
                        fullWidth
                        error={!!errors["email"]}
                        helperText={errors["email"] ? errors["email"].message : ""}
                        {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Invalid email"
                        }
                        })}
                        size="small"
                        sx={{ mb: 2 }}
                        type="email"
                        placeholder="Enter your email"
                        variant="outlined"
                        InputProps={{
                        style: { fontSize: "18px" },
                        className: "form-control",
                        onKeyDown: (event) => {
                            if (event.key === "Enter") {
                            event.preventDefault();
                            handleSubmit(onSubmit)();
                            }
                        },
                        }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                  </Box>
                  <LoadingButton
                    disabled={!isValid}
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isSubmitting}
                    className="form-button"
                    sx={{
                      mt: 3.5,
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontWeight: 600,
                      borderRadius: 1.5
                    }}
                  >
                   Reset Password
                  </LoadingButton>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3
                    }}> 
                    <Box sx ={{
                      color:  '#344054',
                      fontWeight: 400,
                      fontSize: [14, 14],
                      lineHeight: "20px",
                      mr: 1
                    }}>
                     Already have an account?
                    </Box>
                    <Link to="/">
                      <Box sx ={{
                        color: 'primary.main',
                        fontWeight: 600,
                        fontSize: [14, 14],
                        lineHeight: "20px",
                        cursor: "pointer"
                      }}>
                        Log in
                      </Box>
                    </Link>
                    
                  </Box>
                </Box>
              </Box>
            )}
           
          </Box>
          {/* <AuthCommonPart /> */}
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  refreshTokenFromRedux: state.auth.auth.refreshToken
});

export default connect(mapStateToProps, { login })(
  ForgetPassword
);
