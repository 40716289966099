import { Box, Button, Checkbox, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CreatePayment from '../../../../components/modals/create-payment-modal/CreatePayment';

function CardDetail() {
    const [createPaymentModalOpen, setCreatePaymentModalOpen] = useState(false);

    const handleCreatePaymentModalClose = () => {
        setCreatePaymentModalOpen(false);
    };

    const [cardDetails, setCardDetails] = useState([
        { img: '/images/visa.png', name: 'Visa Card', expires: 'Expiry 06/2024', isChecked: true },
        { img: '/images/master.png', name: 'Master card ', expires: 'Expiry 06/2024', isChecked: false },
        { img: '/images/apple.png', name: 'Apple Pay', expires: 'Expiry 06/2024', isChecked: false },
    ]);

    const handleCheckboxChange = (index) => {
        setCardDetails(prevState => {
            const updatedCardDetails = [...prevState];
            updatedCardDetails[index].isChecked = !updatedCardDetails[index].isChecked;
            return updatedCardDetails;
        });
    };


    const handleAddNewPayment = (e) => {
        e.preventDefault();
        setCreatePaymentModalOpen(true);
    }

    const mobileScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Box>
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { xs: 'start', md: 'start' },
                    justifyContent: 'center',
                    padding: '15px 0',
                }}
            >
                <Grid item xs={mobileScreen ? 12 : 4} sx={{ pb: mobileScreen && 2 }}>
                    <Box>
                        <Typography sx={{ fontWeight: 700 }}>Card Details</Typography>
                        <Typography>Select default payment method</Typography>
                    </Box>
                </Grid>

                <Grid item xs={mobileScreen ? 12 : 8}>
                    {cardDetails.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                border: item.isChecked ? '2px solid #7F56D9' : '2px solid #EAECF0',
                                borderRadius: '10px',
                                padding: '10px',
                                mb: 1
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'start', gap: 2 }}>
                                <Box>
                                    <img src={item.img} alt='' />
                                </Box>
                                <Box>
                                    <Typography sx={{ fontWeight: 600 }}>
                                        {item.name}
                                    </Typography>
                                    <Typography>{item.expires}</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography sx={{ fontWeight: 700, color: '#475467' }}>Set as default</Typography>
                                        <Button variant="text">
                                            Edit
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ textAlign: 'right' }}>
                                <Checkbox checked={item.isChecked} onChange={() => handleCheckboxChange(index)} />
                            </Box>
                        </Box>
                    ))}
                    <Button variant="text" startIcon={<AddIcon />} onClick={handleAddNewPayment}>
                        Add new payment method
                    </Button>
                </Grid>

            </Grid>
            <Box className="modal">
                <CreatePayment
                    createPaymentModalOpen={createPaymentModalOpen}
                    handleCreatePaymentModalClose={handleCreatePaymentModalClose} />
            </Box>
        </Box>
    );
}

export default CardDetail;
