import { authApi } from "../interceptor/auth.interceptor";
import fakeData from "./fakeData.json";

class UserService {

// In your UserService (or authApi) file
static async getProfile() {
  return await authApi
    .get("viewProfile")
    .then((response) => {
      return response?.data;
    });
}


  static async updateProfile(formData) {
    return await authApi
      .patch(`updateProfile`, formData,{
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async changePassword(formData) {
    console.log(formData)
    return await authApi
      .patch('changePassword', formData, null)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error('Error changing password:', error);
        throw error;
      });
  }  

// In your UserService (or authApi) file
static async getTimezones() {
  return await authApi
    .get("timezone/details")
    .then((response) => response?.data)
    .catch((error) => {
      console.error("Error fetching timezone data:", error);

      // Mock timezone data if the API call fails
      const mockTimezoneData = {
        results: [
          { timezoneName: "America/New_York", id: 1 },
          { timezoneName: "Europe/London", id: 2 },
          { timezoneName: "Asia/Tokyo", id: 3 },
          { timezoneName: "Australia/Sydney", id: 4 },
        ]
      };

      return mockTimezoneData; // Return the mock data in case of failure
    });
}


static async getRoles() {
  return await authApi
    .get("user/roles")
    .then((response) => response?.data)
    .catch((error) => {
      console.error("Error fetching roles data:", error);

      // Mock roles data in case of API failure
      const mockRolesData = {
        results: [
          { roleName: "Admin", roleId: 1 },
          { roleName: "Client", roleId: 3 }
        ]
      };

      return mockRolesData; // Return the mock data on failure
    });
}


    static async getFakeData() {
        return fakeData;
    }

}

export default UserService;
