import React, { useState, useEffect } from "react";
import { Alert, Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, TextField } from "@mui/material";
import AuthService from "../../services/api/auth.service";
import { login } from "../../redux/slices/auth";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import {
  errorMessage,
} from "../../config/config";
import { getPermission } from "../../utils/cryptograpgy";
const Signup = ({
  refreshTokenFromRedux,
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars

  const [passwordMode, setPasswordMode] = useState({
    password: "password",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (refreshTokenFromRedux) {
        setTimeout(() => {
          if(getPermission("STRETCH_CLIENT")) {
            navigate("/client-dashboard-projects");
          }
          else {
            navigate("/admin-dashboard");
          }
        }, 100);
      } else {
        setIsLoaded(true);
      }
    }
    return () => {
      mounted = false;
    };
  }, [refreshTokenFromRedux, navigate]);

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] === "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const onSubmit = (values) => {
    // console.log("values", values);
    
    const formData = {
      companyName: values.name,
      email: values.email,
      password: values.pass
    };

    setIsSubmitting(true);
    AuthService.register(formData).then(
      (data) => {
        setIsSubmitting(false);
        if (data && data == "User registered successfully!") {
            setResponseMessage({
              type: "success",
              message: data
                ? data
                : "Signup Successfull." ,
            });
        } else {
          setResponseMessage({
            type: "error",
            message: errorMessage,
          });
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("Error Message", error.response.data.message);
        setResponseMessage({
          type: "error",
          message: error?.response?.data?.message
            ? error.response.data.message
            : errorMessage,
        });
        setIsSubmitting(false);
        return Promise.reject();
      }
    );
  };

  return (
    <>
      {isLoaded && (
        <Box sx={{display: 'flex'}} className="container">
          <Box className="flex-container" sx={{
            display: "flex",
            flexDirection: "column"
          }}>
            {(
              <Box className="form-container">
                <Box sx={{ mb: [0, 5] }}>
                  <Box className="login-logo">
                    <img
                     src="/images/stretchNew.png"
                      alt="logo"
                      style={{ height: 82 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 600,
                      fontSize: "36px",
                      lineHeight: "44px",
                      mt: 3,
                    }}
                    className="logo-title"
                  >
                    Sign up
                  </Box>
                </Box>
                {/* <Divider color={"grey"} /> */}
                {responseMessage && (
                  <Alert
                    severity={responseMessage.type}
                    variant="filled"
                    sx={{ mt: 1 }}
                  >
                    <strong>{responseMessage.message}</strong>
                  </Alert>
                )}
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Box> 
                    <Box className="small-sub">
                        Company name*
                    </Box>
                    <TextField
                        required
                        fullWidth
                        error={!!errors["name"]}
                        helperText={errors["name"] ? errors["name"].message : ""}
                        {...register("name", {
                        required: "Name is required",
                        minLength: {
                          value: 3,
                          message: "Minimum 3 characters required"
                        }
                        })}
                        size="small"
                        sx={{ mb: 2 }}
                        type="name"
                        placeholder="Enter company name"
                        variant="outlined"
                        InputProps={{
                        style: { fontSize: "18px" },
                        className: "form-control",
                        onKeyDown: (event) => {
                            if (event.key === "Enter") {
                            event.preventDefault();
                            handleSubmit(onSubmit)();
                            }
                        },
                        }}
                    />
                  </Box>
                  <Box> 
                    <Box className="small-sub">
                        Company email*
                    </Box>
                    <TextField
                        required
                        fullWidth
                        error={!!errors["email"]}
                        helperText={errors["email"] ? errors["email"].message : ""}
                        {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Invalid email"
                        }
                        })}
                        size="small"
                        sx={{ mb: 2 }}
                        type="email"
                        placeholder="Enter your email"
                        variant="outlined"
                        InputProps={{
                        style: { fontSize: "18px" },
                        className: "form-control",
                        onKeyDown: (event) => {
                            if (event.key === "Enter") {
                            event.preventDefault();
                            handleSubmit(onSubmit)();
                            }
                        },
                        }}
                    />
                  </Box>
                   <Box> 
                    <Box className="small-sub" >
                        Password
                        </Box>
                        <TextField
                            required
                            fullWidth
                            sx={{ mb: 2 }}
                            size="small"
                            type={passwordMode.password}
                            variant="outlined"
                            placeholder="Enter your password"
                            error={!!errors["pass"]}
                            helperText={errors["pass"] ? errors["pass"].message : ""}
                            {...register("pass", {
                            required: "Password required",
                            minLength: {
                              value: 8
                            }
                            })}
                            InputProps={{
                            style: { fontSize: "18px" },
                            className: "form-control",
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handlePasswordMode("password")}
                                    aria-label="toggle password"
                                    edge="end"
                                    sx={{ ml: [0, 2], mr: [0, 0.5], color: "grey" }}
                                    className="icon"
                                >
                                    {passwordMode.password === "password" ? (
                                    <VisibilityOffIcon />
                                    ) : (
                                    <VisibilityIcon />
                                    )}
                                </IconButton>
                                </InputAdornment>
                            ),
                            onKeyDown: (event) => {
                                if (event.key === "Enter") {
                                event.preventDefault();
                                handleSubmit(onSubmit)();
                                }
                            },
                            }}
                        />
                        <Box
                        sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "px",
                        color: '#98A2B3'
                        }}
                    
                        >
                        Must be at least 8 characters
                    </Box>
                   </Box>
                  
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                  </Box>
                  <LoadingButton
                    disabled={!isValid}
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isSubmitting}
                    className="form-button"
                    sx={{
                      mt: 3.5,
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontWeight: 600,
                      borderRadius: 1.5
                    }}
                  >
                    Get started
                  </LoadingButton>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3
                    }}> 
                    <Box sx ={{
                      color:  '#344054',
                      fontWeight: 400,
                      fontSize: [14, 14],
                      lineHeight: "20px",
                      mr: 1
                    }}>
                     Already have an account?
                    </Box>
                    <Link to="/">
                      <Box sx ={{
                        color: 'primary.main',
                        fontWeight: 600,
                        fontSize: [14, 14],
                        lineHeight: "20px",
                        cursor: "pointer"
                      }}>
                        Log in
                      </Box>
                    </Link>
                    
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  refreshTokenFromRedux: state.auth.auth.refreshToken
});

export default connect(mapStateToProps, { login })(
  Signup
);
