import { Box, Button, Divider, FormControlLabel, FormHelperText, Grid, Switch, TextField, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import SettingsHeader from '../../../components/common/SettingsHeader/SettingsHeader'
import '../Settings.scss'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';

function Notifications() {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const notifications = [
        { image: '/images/featured-icon.png', name: 'Technology and Theme', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
        { image: '/images/featured-icon.png', name: 'Technology and Innovation', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
        { image: '/images/featured-icon.png', name: 'Technology and Innovation', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
        { image: '/images/featured-icon.png', name: 'Technology and Innovation', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
        { image: '/images/featured-icon.png', name: 'Technology and Innovation', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
        { image: '/images/featured-icon.png', name: 'Technology and Innovation', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
        { image: '/images/featured-icon.png', name: 'Technology and Innovation', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
        { image: '/images/featured-icon.png', name: 'Technology and Innovation', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
        { image: '/images/featured-icon.png', name: 'Technology and Innovation', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
        { image: '/images/featured-icon.png', name: 'Technology and Innovation', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
        { image: '/images/featured-icon.png', name: 'Technology and Innovation', time: '2 mins ago', mssg: 'Your campaign has been finished.', action: 'Upload Report', now: 'now' },
    ]

    const mobileScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const onSubmit = (data) => {
        console.log(data);
    };

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 36,
        height: 18,
        padding: 0,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 1,
            '&.Mui-checked': {
                transform: 'translateX(18px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#7F56D9',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 15,
            height: 15,
            borderRadius: '50%',
            transition: theme.transitions.create(['width'], {
                duration: 100,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 18 / 2,
            opacity: 1,
            backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    }));

    return (
        <Box className='dashboard-container'>
            <SettingsHeader />
            <Grid container spacing={2} sx={{ background: '#F9FAFB', borderRadius: 3, padding: 3 }}>
                <Grid item xs={mobileScreen ? 12 : 6}>
                    <Box sx={{ pb: 3 }}>
                        <Typography variant='h5' sx={{ fontWeight: 600 }}>
                            Recent activity
                        </Typography>
                        <Typography variant='body1'>
                            You can check latest notifications here.
                        </Typography>
                    </Box>

                    <Divider sx={{ borderRadius: 1 }} />

                    {notifications.map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', flexDirection: mobileScreen ? 'column' : 'row', alignItems: mobileScreen ? 'start' : 'center', gap: mobileScreen ? 0 : 2, pt: 2, pb: 1 }}>
                            <Box>
                                <img src={item.image} alt="img" />
                            </Box>
                            <Box sx={{ display: mobileScreen && 'flex', flexDirection: mobileScreen && 'column' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography sx={{ color: '#344054', fontWeight: 500 }} >
                                        {item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name}
                                    </Typography>
                                    <Typography sx={{ color: '#475467' }}>{item.time}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', color: '#475467', gap: 4 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', color: '#475467', gap: 1, marginTop: '-8px' }}>
                                        <Typography>{item.mssg}</Typography>
                                        <Button sx={{ textTransform: 'capitalize' }}>{item.action}</Button>
                                        <Typography>{item.now}</Typography>
                                    </Box>
                                    <Box>
                                        <FiberManualRecordIcon
                                            sx={{ marginRight: '3px', color: 'green', transform: 'scale(0.5)' }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Grid>

                <Grid item xs={mobileScreen ? 12 : 6}>
                    <Box sx={{ pb: 3 }}>
                        <Typography variant='h5' sx={{ fontWeight: 600 }}>
                            Notification settings
                        </Typography>
                        <Typography variant='body1'>
                            We may still send you important notifications about your account outside of your notification settings.
                        </Typography>
                    </Box>

                    <Divider sx={{ borderRadius: 1 }} />

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container sx={{ py: 3 }}>
                            <Grid item xs={5}>
                                <Box sx={{ paddingRight: '20px' }}>
                                    <Typography variant="subtitle2">
                                        New Campaign
                                    </Typography>
                                    <Typography variant='body1'>
                                        These are notifications for comments on your posts and replies to your comments.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                    <FormControlLabel control={<AntSwitch sx={{ mr: 1 }} inputProps={{ 'aria-label': 'ant design' }} defaultChecked {...register("cmpPush")} />} label="Push" />
                                    <FormControlLabel control={<AntSwitch sx={{ mr: 1 }} inputProps={{ 'aria-label': 'ant design' }} {...register("cmpEmail")} />} label="Email" />
                                    <FormControlLabel control={<AntSwitch sx={{ mr: 1 }} inputProps={{ 'aria-label': 'ant design' }} {...register("cmpSms")} />} label="SMS" />
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider sx={{ borderRadius: 1 }} />

                        <Grid container sx={{ py: 3 }}>
                            <Grid item xs={5}>
                                <Box sx={{ paddingRight: '20px' }}>
                                    <Typography variant="subtitle2">
                                        Tags
                                    </Typography>
                                    <Typography variant='body1'>
                                        These are notifications for when someone tags you in a comment, post or story.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                    <FormControlLabel label="Push" control={<AntSwitch sx={{ mr: 1 }} inputProps={{ 'aria-label': 'ant design' }} defaultChecked {...register("tagPush")} />} />
                                    <FormControlLabel label="Email" control={<AntSwitch sx={{ mr: 1 }} inputProps={{ 'aria-label': 'ant design' }} {...register("tagEmail")} />} />
                                    <FormControlLabel label="SMS" control={<AntSwitch sx={{ mr: 1 }} inputProps={{ 'aria-label': 'ant design' }} {...register("tagSMS")} />} />
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider sx={{ borderRadius: 1 }} />

                        <Box sx={{ background: '#FFFFFF', borderRadius: 3, padding: 3, border: '1px solid #EAECF0', mt: 2 }}>
                            <Box>
                                <Box>
                                    <Typography variant='h5' sx={{ fontWeight: 600 }}>We’ve just released a new update!</Typography>
                                    <Typography variant='body1'>Check out the all new dashboard view. Pages and now load faster.</Typography>
                                </Box>
                                <Typography variant='body1' sx={{ fontWeight: 600, pt: 2 }}>
                                    Subscribe to updates
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <TextField
                                        variant='outlined'
                                        sx={{ width: { sm: 150, md: 200, lg: 245 } }}
                                        size='small'
                                        type='email'
                                        placeholder='test@gmail.com'
                                        {...register('email', { pattern: /^\S+@\S+$/i })}
                                    />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ fontWeight: 600, borderRadius: '8px' }}
                                        type='submit'
                                    >
                                        Subscribe
                                    </Button>
                                </Box>
                                {errors.email && (
                                    <FormHelperText error>Please enter a valid email address.</FormHelperText>
                                )}
                            </Box>
                        </Box>
                    </form>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Notifications