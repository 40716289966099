import SearchIcon from "@mui/icons-material/Search";
import {Box} from "@mui/material";
import React from 'react';
import {alpha, experimentalStyled as styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {useState} from "react";
import ClearIcon from '@mui/icons-material/Clear';
const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '32ch',
            '&:focus': {
                width: '32ch',
            },
        },
    },
}));

const SearchComponent = ({searchData}) => {
    const [search, setSearchData] = useState([]);

    const requestSearch = (searchedVal) => {
        searchData(searchedVal.target.value);
        setSearchData(searchedVal.target.value);
    };
    return (<>
        <Box>

            <Search sx={{border: ' 1px solid #D0D5DD!important'}}
                    value={search}
                    onChange={(searchVal) => requestSearch(searchVal)}
            >
                <SearchIconWrapper>
                    <SearchIcon sx={{color: '#D0D5DD!important'}}/>
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{'aria-label': 'search'}}
                />

            </Search>
        </Box>
    </>);
}

export default SearchComponent;
