import { api, authApi } from "../interceptor/auth.interceptor";

class PlatformService {

  static async getPlatformList(formData) {
    return await authApi
      .get("platform/list?userId=" + formData.userId)
      .then((response) => {
        return response?.data;
      });
  }
  
  static async getAllPlatform() {
    return await authApi
      .get("platform/all")
      .then((response) => {
        return response?.data;
      });
  }

  static async validatePlatform(file) {
    const formData = new FormData();
    formData.append('platformDetailsCsv', file);
    
    return await authApi
      .post("projectCampaigns/validate/platform-details", formData,
        {
          headers: {
              'accept': 'application/json',
              'Content-Type': 'multipart/form-data',
          },
      }
      )
      .then((response) => {
        return response?.data;
      });
  }

  static async downloadPlatformTemplate(ids) {
    return await authApi
      .get(`download/platFormTemplate/${ids}`, { responseType: 'blob' })
      .then((response) => {
        return response?.data;
      });
  }

  static async createPlatform(formData) {
    return await authApi
      .post("platform/create", formData)
      .then(response => {
        return response?.data;
      })
  }
}

export default PlatformService;
