import React, { useEffect, useState } from "react" // React and hooks
import { useDispatch } from "react-redux" // Redux-related imports
import { useNavigate } from "react-router-dom" // Routing-related imports

// MUI components and icons
import { Box, Button, Grid, Typography } from "@mui/material"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import SaveAltIcon from "@mui/icons-material/SaveAlt"

// Custom components
import GenericTable from "../../common/GenericTable/genericTable"

// Redux actions
import { setIsLoadingState } from "../../../redux/slices/common"

// Services
import ProjectService from "../../../services/api/project.service"

const ClientWiseProjectList = (searchValue) => {
  const dispatch = useDispatch()

  const ProjectTemplate = ({ row }) => {
    return (
      <Grid container>
        <Typography variant="h6" sx={{ fontSize: "1.5rem", fontWeight: 600 }}>
          {row.projectAndCampaigns.projectName}
        </Typography>
      </Grid>
    )
  }
  const StartDateTemplate = ({ row }) => {
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography variant="body2" gutterBottom>
            Start Date
          </Typography>
        </Grid>
        <Grid container item alignItems="center" spacing={1}>
          <Grid item>
            <CalendarTodayIcon />
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ fontSize: "1rem" }}>
              {row.projectAndCampaigns.projectStartDate || ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const EndDateTemplate = ({ row }) => {
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography variant="body2" gutterBottom>
            End Date
          </Typography>
        </Grid>
        <Grid container item alignItems="center" spacing={1}>
          <Grid item>
            <CalendarTodayIcon />
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ fontSize: "1rem" }}>
              {row.projectAndCampaigns.projectEndDate || ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const ActionTemplate = ({ row }) => {
    const handleReportClick = (event) => {
      event.stopPropagation()
      console.log("Report button clicked for:", row)
    }
    return (
      <Grid container display="flex" justifyContent="space-around">
        <Grid item>
          <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 600 }}>
            {row.projectAndCampaigns.totalCampaigns} Campaign Details
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ textTransform: "none" }}
            startIcon={<SaveAltIcon color="success" />}
            onClick={handleReportClick}
          >
            Report
          </Button>
        </Grid>
      </Grid>
    )
  }

  const tableConfig = {
    ShowHeaderTemplate: false,
    Columns: [
      {
        Key: "name",
        Title: "Name",
        CellTemplate: ProjectTemplate,
      },
      {
        Key: "startDate",
        Title: "Start Date",
        CellTemplate: StartDateTemplate,
      },
      {
        Key: "endDate",
        Title: "endDate",
        CellTemplate: EndDateTemplate,
      },
      {
        Key: "view",
        Title: "View",
        CellTemplate: ActionTemplate,
      },
    ],
    PageSize: 5,
  }

  const navigate = useNavigate()
  const [projectList, setProjectList] = useState([])
  const [totalTableRecords, setTotalTableRecords] = useState(0)
  const [pageQueryParams, setPageQueryParams] = useState({
    page: 0,
    size: tableConfig?.PageSize,
  })

  useEffect(() => {
    const sValue = searchValue.searchValue
    const queryParams = [
      sValue ? `projectName=${sValue}` : null,
      `page=${pageQueryParams.page}`,
      `size=${pageQueryParams.size}`,
    ]
      .filter(Boolean)
      .join("&")
    dispatch(setIsLoadingState(true))
    ProjectService.getAllClientProjectList(queryParams)
      .then(
        (data) => {
          setTotalTableRecords(data.data.totalElements)
          dispatch(setIsLoadingState(false))
          setProjectList(data.data.content)

          return Promise.resolve()
        },
        (error) => {
          console.log("Error Message", error.response.data.message)

          return Promise.reject()
        }
      )
      .finally(() => {
        dispatch(setIsLoadingState(false))
      })
  }, [dispatch, pageQueryParams, searchValue])

  const onChangePage = (e) => {
    if (e) {
      setPageQueryParams({
        ...pageQueryParams,
        page: e - 1,
      })
    }
  }

  const onSelectRow = (e) => {
    if (e) {
      navigate(
        `/campaigns/${e.projectAndCampaigns.projectName}/${e.projectAndCampaigns.projectId}`
      )
    }
  }

  return (
    <Box>
      {projectList.length > 0 ? (
        <Box>
          <GenericTable
            tableConfig={tableConfig}
            totalRecords={totalTableRecords}
            tableData={projectList}
            onRowClick={(e) => {
              onSelectRow(e)
            }}
            getCurrentPageNumber={(e) => {
              onChangePage(e)
            }}
          />
        </Box>
      ) : (
        <Typography
          variant="h6"
          textAlign="center"
          sx={{ fontSize: "1rem", padding: 10 }}
        >
          No Data Found
        </Typography>
      )}
    </Box>
  )
}
export default ClientWiseProjectList
