import React from 'react';
import { Box } from "@mui/material";

const AuthCommonPart = () => {
  return (<>
    <Box className="flex-container right-container-bg" >
      <Box sx={
        {
          maxWidth: "528px"
        }
      }>
        <Box className="login-logo">
          <img
            src="/images/stars.png"
            alt="logo"
            style={{ height: 82 }}
          />
        </Box>
        <Box sx={{
          fontWeight: 500,
          fontSize: [60, 60],
          lineHeight: "72px",
          color: "white",
          mt: 1,
        }}>Start turning your ideas into reality</Box>
        <Box sx={{
          fontWeight: 500,
          fontSize: [18, 18],
          lineHeight: "28px",
          color: "white",
          mt: 1
        }}>Create a free account and get full access to all features for 30-days.
          No credit card needed. Get started in 2 minutes.</Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px", mt: 2 }}>
          <Box className="login-logo">
            <img
              src="/images/avatars.png"
              alt="logo"
              style={{ height: 40 }}
            />
          </Box>
          <Box sx={{
            fontWeight: 500,
            fontSize: [18, 18],
            lineHeight: "28px",
            color: "white",
            mt: 1
          }}> Join 40,000+ users </Box>
        </Box>

      </Box>
      <Box sx={{ position: 'absolute', bottom: "5%", left: "50%", transform: 'rotate(0deg) translateX(-50%)', zIndex: 2 }} className="login-logo">
        <img
          src="/images/arrow.png"
          alt="logo"
          style={{ height: "25vh", width: "25vw" }}
        />
      </Box>
    </Box>
  </>)
}

export default AuthCommonPart;