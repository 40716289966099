const { REACT_APP_APP_NAME, REACT_APP_BASE_URL, REACT_APP_API_URL, REACT_APP_IMAGE_URL, REACT_APP_MAPBOX_API_TOKEN  } = process.env;

// URL CONFIG
export const apiUrl = REACT_APP_API_URL;
export const baseUrl = REACT_APP_BASE_URL;
export const imageUrl = REACT_APP_IMAGE_URL;


// APP CONFIG
export const appName = REACT_APP_APP_NAME;
export const mapboxApiToken = REACT_APP_MAPBOX_API_TOKEN;
export const classPrefix = "stretch";
export const accessToken = 'accessToken';
export const refreshToken = 'refreshToken';
export const userDetails = 'userDetails';
export const accessTokenTimeout = 14 * 60 * 1000; // 15 minutes
export const refreshTokenTimeout = 24 * 60 * 1000; // 25 minutes

export const accessTokenTimerTransition = 10 * 1000; // 10 Seconds
export const userIdleTimeCountDownStartTime = 40 * 1000; // 40 Seconds
export const userIdleTimeout = 10 * 60 * 1000; // 10 minutes

export const errorMessage = 'Something went wrong. Please try again!';
