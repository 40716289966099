import React, {useState, useEffect} from 'react';
import {ToggleButtonGroup, styled} from '@mui/material'
import {Box, Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import {Paper} from '@mui/material';
import Stack from '@mui/material/Stack';
import styles from './ClientDashboard.module.scss';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Pagination from "../../components/common/GenericTable/Pagination/Pagination";
import GenericTable from "../../components/common/GenericTable/genericTable";
import SearchComponent from "../../components/common/SearchComponent/searchComponent";
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CampaignViewDetailsModal from '../../components/modals/campaignViewDetails/campaignViewDetails';
import {useDispatch, useSelector} from "react-redux";
import {setIsLoadingState} from "../../redux/slices/common";
import CampaignService from "../../services/api/campaign.service";
import moment from "moment";

const ToggleButton = styled(MuiToggleButton)(({selectedcolor}) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: selectedcolor,
    },
}));

const theme = createTheme({
    palette: {
        text: {
            primary: "#00ff00",
        },
    },
});
// grid show

const CustomToggle = styled(ToggleButton)({
    color: '#6941C6',
})

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    borderRadius: 5,
    color: theme.palette.text.secondary,
}));

const ClientReports = () => {
    const user = useSelector(state => state.auth.auth.user);
    const dispatch = useDispatch();

    // table config
    const CompanyTemplate = ({row}) => {
        return (
            <>
                <Stack spacing={1}>
                    <Box style={{
                        fontWeight: '700',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        lineHeight: '30px',
                        color: '#101828'
                    }}>
                        {row.name}
                    </Box>
                    <Chip label={row.status} style={{
                        background: '#344054',
                        color: '#ffffff',
                        width: 'fit-content'
                    }}/>
                </Stack>
            </>
        );
    }
    const StartDateTemplate = ({row}) => {
        return (
            <>
                <Box sx={{marginTop: '3px'}}>
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#475467',
                        }}>Starting Date</Box>
                    <Stack direction="row" spacing={1} alignItems={'center'}
                           sx={{marginTop: '3px'}}>
                        <CalendarTodayIcon sx={{fontSize: 20}}/>
                        <Box sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#101828',
                        }}>{row.startDate ? moment(row.startDate).format('DD/MM/YYYY') : ''}</Box>
                    </Stack>
                </Box>
            </>
        );
    }
    const EndDateTemplate = ({row}) => {
        return (
            <>
                <Box sx={{marginTop: '3px'}}>
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#475467',
                        }}>End Date</Box>
                    <Stack direction="row" spacing={1} alignItems={'center'}
                           sx={{marginTop: '3px'}}>
                        <CalendarTodayIcon sx={{fontSize: 20}}/>
                        <Box sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#101828',
                        }}>{row.endDate ? moment(row.endDate).format('DD/MM/YYYY') : ''}</Box>
                    </Stack>
                </Box>
            </>
        );
    }
    const ActionTemplate = ({row}) => {
        return (
            <>
                <Box sx={{borderLeft: '2px solid #D0D5DD', paddingLeft: '20px'}}>
                    <Stack spacing={2} direction="row">
                        <Button variant="text"
                                onClick={(e) => {
                                    onViewDetailsClick(e, row);
                                }}
                                sx={{
                                    opacity: ["RUNNING", "ACTIVE"].includes(row.status) ? 1 : 1,
                                    fontWeight: '600!important',
                                    fontSize: '14px!important',
                                    lineHeight: '20px!important',
                                    color: '#101828!important',
                                    textTransform: 'none!important',
                                }}> View Details</Button>
                        <Button sx={{
                            fontWeight: '600!important',
                            fontSize: '14px!important',
                            lineHeight: '20px!important',
                            color: '#101828!important',
                            textTransform: 'none!important',
                        }} startIcon={<SaveAltIcon color="success"/>}>
                            Report
                        </Button>
                    </Stack>
                </Box>
            </>
        );
    }

    const onSelectRow = (e) => {
    }

    const tableConfig = {
        ShowHeaderTemplate: false,
        Columns: [
            {
                Key: 'name',
                Title: 'Name',
                CellTemplate: CompanyTemplate,
            },
            {
                Key: 'startDate',
                Title: 'Start Date',
                CellTemplate: StartDateTemplate
            },
            {
                Key: 'endDate',
                Title: 'endDate',
                CellTemplate: EndDateTemplate
            },
            {
                Key: 'view',
                Title: 'View',
                CellTemplate: ActionTemplate
            },
        ],
        PageSize: 5,
    }
    const [adminViewModalOpen, setAdminViewModalOpen] = useState(false);
    const [adminDetails, setAdminDetails] = React.useState();
    const [value, setFilter] = React.useState("list");
    const [showListView, setShowViewOption] = React.useState(true);
    const [projectListData, setProjectList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalTableRecords, setTotalTableRecords] = useState(0);
    const [searchValue, setSearchValue] = useState(null);
    const [pageQueryParams, setPageQueryParams] = useState({page:0, size: tableConfig?.PageSize});
    useEffect(() => {
        if (user?.id) {
            let queryParams;
            if(searchValue){
                queryParams = `&page=${pageQueryParams.page}&size=${pageQueryParams.size}&searchText=${searchValue}&status=ENDED`;
            }else {
                queryParams = `&page=${pageQueryParams.page}&size=${pageQueryParams.size}&status=ENDED`;
            }
            dispatch(setIsLoadingState(true));
            CampaignService.getAllClientCampaignList(user?.id, queryParams).then(
                (data) => {
                    setTotalTableRecords(data.results.totalRecords);
                    dispatch(setIsLoadingState(false));
                    setProjectList(data.results.content);
                    return Promise.resolve();
                },
                (error) => {
                    console.log("Error Message", error.response.data.message);
                    return Promise.reject();
                }
            ).finally(() => {
                dispatch(setIsLoadingState(false));
            });
        }

    }, [user?.id, pageQueryParams, searchValue]);

    const handleFilterAlignment = (event, newAlignment) => {
        setFilter(newAlignment);
        if (newAlignment === 'list') {
            setCurrentPage(1);
            setPageQueryParams({
                ...pageQueryParams,
                page: 0,
            });
            setShowViewOption(true);
        } else {
            setCurrentPage(1);
            setPageQueryParams({
                ...pageQueryParams,
                page: 0,
            });
            setShowViewOption(false);
        }
    };


    const onViewDetailsClick = (e, row) => {
        e.preventDefault();
        setAdminViewModalOpen(true);
        setAdminDetails(row);
    }

    const handleAdminDetailsModalClose = () => {
        setAdminViewModalOpen(false);
    };
    const handleSearchChange = (value) => {
        if (value) {
            setSearchValue(value);
        } else {
            setSearchValue(null);
        }
    };
    const handelOnChangePage = (page) => {
        if (page) {
            setCurrentPage(page)
            setPageQueryParams({
                ...pageQueryParams,
                page: ( page - 1),
            });
        }
    }
    const onChangePage = (e) => {
        if (e) {
            setPageQueryParams({
                ...pageQueryParams,
                page: ( e - 1),
            });
        }
    }
    return (<>
            <Box sx={{display: 'flex'}}>
                <Box className="flex-container" sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    p: "2% 4% 2% 4%"
                }}>
                    <Stack direction="row" sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "12px",
                        width: '100%',
                        flexDirection: {xs: 'column', sm: 'row'}
                    }}>
                        <Box sx={{
                            fontWeight: '600',
                            fontSize: '18px',
                            lineHeight: '28px',
                            color: '#000000',
                        }}>Campaign Reports</Box>
                        <Box sx={{justifyContent: 'center', textAlign: 'right', mt: {xs: 2, sm: 0}}}>
                            <Box>
                                <Stack direction="row" spacing={2}>
                                    <Box>
                                        <SearchComponent searchData={handleSearchChange}/>
                                    </Box>
                                    <Box>
                                        <ThemeProvider theme={theme}>
                                            <ToggleButtonGroup
                                                value={value}
                                                exclusive
                                                onChange={handleFilterAlignment}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                <ToggleButton value="list" selectedcolor="#6941C6">
                                                    <TableRowsOutlinedIcon/>
                                                </ToggleButton>
                                                <ToggleButton value="grid" selectedcolor="#6941C6">
                                                    <WindowOutlinedIcon/>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </ThemeProvider>
                                    </Box>
                                </Stack>

                            </Box>

                        </Box>
                    </Stack>
                    {/*table start*/}
                    {showListView ?
                        <Box style={{width: "100%"}}>
                            {projectListData.length > 0 ?
                                <Box style={{width: "100%", marginTop: '30px'}}>
                                    <GenericTable tableConfig={tableConfig}
                                                  tableData={projectListData}
                                                  totalRecords={totalTableRecords}
                                                  getCurrentPageNumber={(e) => {onChangePage(e)}}
                                                  onRowClick={(e) => {onSelectRow(e)}}
                                    />
                                </Box> :
                                <Box sx={{
                                    textAlign: "center",
                                    marginTop: "30px",
                                    fontWeight: '600',
                                    fontSize: '20px',
                                    lineHeight: '28px',
                                    color: '#000000',
                                }}> No Data Found
                                </Box>
                            }
                        </Box> : null
                    }


                    {/*grid show*/}
                    {!showListView ?
                        <Box>
                            {projectListData.length > 0 ?
                                <Box sx={{flexGrow: 1, marginTop: '30px'}}>
                                    <Grid container spacing={{xs: 2, sm: 2, md: 3}}>
                                        {projectListData && projectListData.map((row, rowIndex) => (
                                            <Grid item key={rowIndex}>
                                                <Box sx={{
                                                    maxWidth: "302px",
                                                    cursor: 'pointer',
                                                    p: 2,
                                                    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                                                    border: "1px solid #F2F4F7",
                                                    borderRadius: "12px"
                                                }}>
                                                    <Box sx={{marginTop: '8px'}}>
                                                        <Stack spacing={1}>
                                                            <Box className={styles.TextTitle} sx={{
                                                                fontSize: '20px',
                                                                fontWeight: "700",
                                                                color: "black",
                                                                lineHeight: "30px"
                                                            }}>{row['name']}</Box>
                                                            <Chip label={row.status} style={{
                                                                background: (row.status === 'ENDED') ? '#344054' : (row.status === 'RUNNING' ? '#039855' :
                                                                    (row.status === 'UNDER_REVIEW' ? '#E04F16' : (row.status === 'DRAFT' ? '#444CE7' : '#039855'))),
                                                                color: '#ffffff',
                                                                width: 'fit-content'
                                                            }}/>
                                                        </Stack>
                                                    </Box>
                                                    <Stack sx={{marginTop: "12px", gap: 1}} direction="row"
                                                           justifyContent="space-between" alignItems="center">
                                                        <Box sx={{marginTop: '3px'}}>
                                                            <Box className={styles.DateTextTitle}
                                                                 sx={{color: '#667085', fontSize: "12px"}}>Starting
                                                                Date</Box>
                                                            <Stack direction="row" spacing={1} alignItems={'center'}
                                                                   sx={{marginTop: '4px'}}>
                                                                <CalendarTodayIcon sx={{fontSize: 20}}/>
                                                                <Box className={styles.DateText} sx={{
                                                                    color: '#344054',
                                                                    fontSize: "16px",
                                                                    fontWeight: "500"
                                                                }}>{row.startDate ? moment(row.startDate).format('DD/MM/YYYY') : '-'}</Box>
                                                            </Stack>
                                                        </Box>

                                                        <Box>
                                                            <Box className={styles.DateTextTitle}
                                                                 sx={{color: '#667085', fontSize: "12px"}}>Ending
                                                                Date</Box>
                                                            <Stack direction="row" spacing={1} alignItems={'center'}
                                                                   sx={{marginTop: '4px'}}>
                                                                <CalendarTodayIcon sx={{fontSize: 20}}/>
                                                                <Box className={styles.DateText} sx={{
                                                                    color: '#344054',
                                                                    fontSize: "16px",
                                                                    fontWeight: "500"
                                                                }}>{row.endDate ? moment(row.endDate).format('DD/MM/YYYY') : '-'}</Box>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                    <Divider sx={{marginTop: "12px", marginBottom: "12px"}}/>

                                                    <Stack sx={{marginTop: "12px", width: '100%'}} direction="row"
                                                           justifyContent="space-between" alignItems="center">
                                                        <Box sx={{justifyContent: 'center'}}>
                                                            <Button variant="text" className={styles.ViewBtn}
                                                                    sx={{
                                                                        color: ' #344054!important',
                                                                        textTransform: "none"
                                                                    }}
                                                                    onClick={(e) => {
                                                                        onViewDetailsClick(e, row);
                                                                    }}>
                                                                View Details
                                                            </Button>
                                                        </Box>
                                                        <Box sx={{justifyContent: 'center', textAlign: 'right'}}>
                                                            <Box>
                                                                <Button disabled={row.status == "Active"} sx={{
                                                                    color: "#344054!important",
                                                                    textTransform: "none"
                                                                }}
                                                                        startIcon={<FileUploadOutlinedIcon
                                                                            color={"success"}/>}>
                                                                    Upload
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Stack>

                                                </Box>
                                            </Grid>
                                        ))}

                                    </Grid>
                                    <Box>
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={totalTableRecords}
                                            pageSize={tableConfig?.PageSize}
                                            onPageChange={(page) => {
                                                handelOnChangePage(page)
                                            }}
                                        />
                                    </Box>
                                </Box> :
                                <Box sx={{
                                    textAlign: "center",
                                    marginTop: "30px",
                                    fontWeight: '600',
                                    fontSize: '20px',
                                    lineHeight: '28px',
                                    color: '#000000',
                                }}> No Data Found
                                </Box>
                            }
                        </Box> : null
                    }

                    {/*grid show end*/}
                </Box>
            </Box>

            <Box className="modal">
                <CampaignViewDetailsModal
                    clientViewModalOpen={adminViewModalOpen}
                    detailsData={adminDetails}
                    handleClientViewModalClose={handleAdminDetailsModalClose}/>
            </Box>

        </>

    );
}
export default ClientReports;
