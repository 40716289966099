import React from "react";
import { Controller } from "react-hook-form";
import SearchIcon from '@mui/icons-material/Search';
import {TextField, InputAdornment, Autocomplete} from "@mui/material";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

export const RHFAutocompleteField = (props) => {
  const { control, options, name, disabled, iconName } = props;
  return (
    <Controller
      name={name}
      control={control}
      // rules={{
      //   required: "this field is required"
      // }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field;
        
        return (
          <>
            <Autocomplete
            disabled={disabled}
              value={
                value
                  ? options.find((option) => {
                      return value === option.id;
                    }) ?? null
                  : null
              }
              getOptionLabel={(option) => {
                return option.name;
              }}
              onChange={(event, newValue) => {
                onChange(newValue ? newValue.id : null);
              }}
              id="controllable-states-demo"
              options={options}
              renderInput={(params) => (
                <TextField
                        disabled={disabled}
                        {...params}
                        id="search"
                        size='small'
                        placeholder="Search"
                        sx={{ width: "100%" }}
                        inputRef={ref}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                  {
                                    iconName != "timezone" && <SearchIcon style={{ fill: "#D0D5DD" }} />
                                  }
                                  {
                                    iconName == "timezone" && <AccessTimeOutlinedIcon style={{ fill: "#D0D5DD" }} />
                                  }
                                </InputAdornment>
                            ),
                        }}
                    />
              )}
            />
            {error ? (
              <span style={{ color: "red" }}>{error.message}</span>
            ) : null}
          </>
        );
      }}
    />
  );
};