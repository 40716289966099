import React, {useEffect, useState} from "react";
import {Box, Button, IconButton, Link, Modal,} from "@mui/material";
import './adminViewDetails.scss';
import Stack from "@mui/material/Stack";
import LinkIcon from '@mui/icons-material/Link';
import Chip from "@mui/material/Chip";
import LocationMap from "../../common/map/LocationMap";
import DoneIcon from '@mui/icons-material/Done';
import Grid from '@mui/material/Grid';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmationModal from "../confirmationModal/confirmationModal";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {Controller, useForm} from "react-hook-form";
import CommonFileUploader from "../../common/CommonFileUploader/CommonFileUploader";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {setIsLoadingState} from "../../../redux/slices/common";
import CampaignService from "../../../services/api/campaign.service";
import {useDispatch} from "react-redux";
import moment from "moment";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InsertLinkIcon from '@mui/icons-material/InsertLink';



const AdminViewDetailsModal = ({
                                   detailsData,
                                   pendingReview,
                                   adminViewModalOpen,
                                   getApprovalDetails,
                                   handleAdminViewModalClose
                               }) => {
    const {register, handleSubmit, watch, getValues, setValue, control} = useForm();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [isApprovedModal, setIsApprovedModal] = useState(true);
    const [approvalDetails, setApprovalDetails] = useState({
        campaignId: '',
        isApproved: false,
        isReject: false,
        rejectReason: '',
    });
    const dispatch = useDispatch();
    const [viewDetailsData, setAdminCampaignViewDetailsData] = useState('');

    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [open, setOpen] = React.useState(false);

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        if (detailsData?.campaignId) {
            dispatch(setIsLoadingState(true));
            CampaignService.getCampaignViewDetails(detailsData?.campaignId).then(
                (data) => {

                    // data.data.campaignId = detailsData?.campaignId;
                    setAdminCampaignViewDetailsData(data.data);
                    if(data.data?.locationDetails){
                        let pois = [];
                        data.data?.locationDetails.forEach((r) => {
                            pois.push({
                                latitude: parseFloat(r.latitude),
                                longitude: parseFloat(r.longitude)
                            })
                        })
                        setLocations(pois);
                    }

                    dispatch(setIsLoadingState(false));
                    return Promise.resolve();
                },
                (error) => {
                    console.log("Error Message", error.response.data.message);
                    return Promise.reject();
                }
            ).finally(() => {
                dispatch(setIsLoadingState(false));
            });
        }

    }, [detailsData?.campaignId]);
    const handleOnApproved = () => {
        handleAdminViewModalClose(true);
        setLocations([]);
        setConfirmModalOpen(true);
        setIsApprovedModal(true);
    }
    const handleOnRejected = () => {       
        handleAdminViewModalClose(true);
        setLocations([]);
        setConfirmModalOpen(true);
        setIsApprovedModal(false);
    }

    const handleOnClose = () => {
        setAdminCampaignViewDetailsData('');
        handleAdminViewModalClose(true);
        setLocations([]);
        setConfirmModalOpen(false);
        setFileData(null);
    }
    const handleOnSubmit = () => {
        setTimeout(() => {
            setOpen(true);
            handleAdminViewModalClose(true);
            setLocations([]);
            setConfirmModalOpen(false);
            setFileData(null);
        }, 300)

    }

    const handleOnApprovedModalClose = () => {
        setConfirmModalOpen(false);
    };

    const handleFileChange = (file) => {
        if (file) {
            setFileName(file);
            const reader = new FileReader();
            reader.onloadend = (e) => {
                console.log(reader.result);
                setFileData(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleOnConfirmationApproved = (confirm) => {
        if (confirm) {
            approvalDetails.isApproved = true;
            approvalDetails.isReject = false;
            approvalDetails.rejectReason = '';
            approvalDetails.campaignId = viewDetailsData.campaignId ? viewDetailsData.campaignId : viewDetailsData.campaignId;
            setApprovalDetails(approvalDetails)
            getApprovalDetails(approvalDetails)
        }
    }
    const onHandelRejectReason = (reason) => {
        if (reason) {
            approvalDetails.campaignId = viewDetailsData.campaignId ? viewDetailsData.campaignId : viewDetailsData.campaignId;
            approvalDetails.isApproved = false;
            approvalDetails.isReject = true;
            approvalDetails.rejectReason = reason;
            setApprovalDetails(approvalDetails)
            getApprovalDetails(approvalDetails)
        }
    }

    function downloadPoiFile(camId, isTarget, fileName) {
        if (camId) {
            dispatch(setIsLoadingState(true));
            CampaignService.getCampaignPoiDownloadFile(camId, isTarget).then(
                (response) => {
                    const temp = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = temp;
                    link.setAttribute('download', fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    dispatch(setIsLoadingState(false));
                    return Promise.resolve();
                },
                (error) => {
                    console.log("Error Message", error.response.data.message);
                    return Promise.reject();
                }
            ).finally(() => {
                dispatch(setIsLoadingState(false));
            });
        }
    }

    return (
        <>
            {viewDetailsData ? <Modal
                    open={adminViewModalOpen}
                    onClose={handleAdminViewModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        backdropFilter: "blur(15px)",
                    }}
                >
                    <Box className="admin-details-modal">
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <Box className="login-logo">
                                <img
                                    src="/images/featured-icon.png"
                                    alt="logo"
                                    style={{height: 48}}
                                />
                            </Box>
                            <Box sx={{
                                cursor: "pointer"
                            }} className="login-logo" onClick={handleOnClose}>
                                <img
                                    src="/images/close-icon.png"
                                    alt="logo"
                                    style={{height: 48}}
                                />
                            </Box>
                        </Box>
                        <Box sx={{marginTop: '8px'}}>
                            <Stack spacing={1}>
                                <Box className="com_name">{viewDetailsData ? viewDetailsData.campaignTitle : ''}</Box>
                                {/*{detailsData && pendingReview === true ?*/}
                                <Chip
                                    label={(viewDetailsData.campaignRemark === 'ENDED') ? 'Ended' : (viewDetailsData.campaignRemark === 'RUNNING' ? 'Running' :
                                        (viewDetailsData.campaignRemark === 'UNDER_REVIEW' ? 'Under Review' : (viewDetailsData.campaignRemark === 'DRAFT' ? 'Draft' :
                                            (viewDetailsData.campaignRemark === 'PENDING' ? 'Pending' : (viewDetailsData.campaignRemark === 'REJECT' ? 'Reject' : 'Paused')))))
                                    } style={{
                                    background: (viewDetailsData.campaignRemark === 'ENDED') ? '#344054' : (viewDetailsData.campaignRemark === 'RUNNING' ? '#039855' :
                                        (viewDetailsData.campaignRemark === 'UNDER_REVIEW' ? '#E04F16' : (viewDetailsData.campaignRemark === 'DRAFT' ? '#444CE7' :
                                            (viewDetailsData.campaignRemark === 'PENDING' ? '#344054' : (viewDetailsData.campaignRemark === 'REJECT' ? '#B00020' : '#344054'))))),

                                    color: '#ffffff',
                                    width: 'fit-content'
                                }}/>
                            </Stack>
                        </Box>

                        {locations.length > 0 &&        <Box sx={{marginTop: '8px'}}>
                            <LocationMap locations={locations}/>
                        </Box>}


                        {viewDetailsData ? (viewDetailsData.campaignRemark !== 'ENDED') ?
                            <Box sx={{flexGrow: 1, marginTop: '8px'}}>
                                <Grid container spacing={2} columns={16}>
                                    <Grid item xs={8}>

                                        <Stack
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={1}
                                        >
                                            <Box className="date_title"> Starting Date</Box>
                                            <Stack direction="row" spacing={1} alignItems={'center'}>
                                                <CalendarTodayIcon sx={{fontSize: 20}}/>
                                                <Box
                                                    className="date_text">{viewDetailsData.campaignStartDate ? (moment(viewDetailsData.campaignStartDate).format('DD/MM/YYYY')) : ''}</Box>
                                            </Stack>

                                        </Stack>

                                        {viewDetailsData.platFormDetails.length > 0 ?
                                    <Box>

                                        {viewDetailsData.platFormDetails.map((row, rowIndex) => (
                                            <Box key={rowIndex}>
                                                {row?.platform ?
                                                    <Stack direction="row" spacing={1} key={rowIndex}
                                                           alignItems={'center'}
                                                           sx={{marginTop: '4px'}}>
                                                        {/* <img
                                                            src="/images/input.png"
                                                            alt="logo"
                                                            style={{height: 16}}
                                                        /> */}
                                                        <CheckBoxIcon color="primary"/>
                                                        <Box className="link_text">{row?.platform.name}</Box>
                                                    </Stack>
                                                    : (row.url ? ((row?.url.length > 0) ?
                                                        <Stack direction="row" spacing={1} key={rowIndex}
                                                               alignItems={'center'}
                                                               sx={{marginTop: '4px'}}>
                                                            {/* <img
                                                                src="/images/input.png"
                                                                alt="logo"
                                                                style={{height: 16}}
                                                            /> */}
                                                            <Box className="link_text">{row?.name}</Box>
                                                        </Stack>
                                                        : '') : '')
                                                }
                                            </Box>
                                        ))}
                                    </Box>

                                    : ''} 
                                        
                                        {/* {viewDetailsData.platFormDetails.length > 0 ?
                                            <Box>

                                                {viewDetailsData.platFormDetails.map((row, rowIndex) => (
                                                    <Box key={rowIndex}>
                                                        {row?.dcmFileName ?
                                                            <Stack direction="row" spacing={1} key={rowIndex}
                                                                   alignItems={'center'}
                                                                   sx={{marginTop: '4px'}}>
                                                                <img
                                                                    src="/images/input.png"
                                                                    alt="logo"
                                                                    style={{height: 16}}
                                                                />
                                                                <CheckBoxIcon color="primary"/>

                                                                <Box className="link_text">{row?.platform.name}</Box>

                                                            </Stack>
                                                            : (row.url ? ((row?.url.length > 0) ?
                                                                <Stack direction="row" spacing={1} key={rowIndex}
                                                                       alignItems={'center'}
                                                                       sx={{marginTop: '4px'}}>
                                                                    <img
                                                                        src="/images/input.png"
                                                                        alt="logo"
                                                                        style={{height: 16}}
                                                                    />
                                                                    {console.log(row?.platform.name)}
                                                                    <Box className="link_text">{row?.platform.name}</Box>
                                                                </Stack>
                                                                : '') : '')
                                                        }
                                                    </Box>
                                                ))}
                                            </Box>

                                            : ''} */}

                                    </Grid>
                                    <Grid item xs={8}>
                                        <Stack
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={1}
                                        >
                                            <Box className="date_title"> End Date</Box>
                                            <Stack direction="row" spacing={1} alignItems={'center'}
                                                   sx={{marginTop: '4px'}}>
                                                <CalendarTodayIcon sx={{fontSize: 20}}/>
                                                <Box
                                                    className="date_text">{viewDetailsData.campaignStartDate ? (moment(viewDetailsData.campaignStartDate).format('DD/MM/YYYY')) : ''}</Box>
                                            </Stack>
                                        </Stack>
                                        { viewDetailsData.platFormDetails.length > 0 ?
                                   <Box>

                                        {viewDetailsData.platFormDetails.map((row, rowIndex) => (
                                            <Stack direction="row" spacing={1} key={rowIndex} alignItems={'center'}
                                                   sx={{marginTop: '4px'}}>
                                               <InsertLinkIcon/>
                                                <a target="_blank" rel="noopener noreferrer"  href={row?.landingPage}>{row?.landingPage}</a>
                                            </Stack>
                                        ))}
                                    </Box>

                                   : ''

            }
                                        {/* {viewDetailsData && viewDetailsData.landingPageUrl.length > 0 ?
                                            <Box>

                                                {viewDetailsData && viewDetailsData.landingPageUrl.map((file, index) => (
                                                    <Box key={index}>
                                                        {file?.dcmFileName ?
                                                            <Stack direction="row" spacing={1} key={index}
                                                                   alignItems={'center'}
                                                                   sx={{marginTop: '4px'}}>
                                                                <LinkIcon sx={{fontSize: 20}}/>
                                                                <Box className="link_text" sx={{cursor: 'pointer'}}>
                                                                    {file.dcmFileName}
                                                                </Box>
                                                            </Stack>
                                                            : (file.url ? ((file?.url.length > 0) ?
                                                                <Box>
                                                                    <Stack direction="row" spacing={1} alignItems={'center'}
                                                                           sx={{marginTop: '4px'}}>
                                                                        <LinkIcon sx={{fontSize: 20}}/>
                                                                        <Box>
                                                                            {file.url.map((url, index) => (
                                                                                <Stack spacing={0} key={index}>
                                                                                    <Box className="link_text"
                                                                                         sx={{cursor: 'pointer'}}>
                                                                                        <Link className="link_text" href="#"
                                                                                              underline="none">
                                                                                            {file.url[index]}
                                                                                        </Link>
                                                                                    </Box>
                                                                                </Stack>
                                                                            ))}
                                                                        </Box>

                                                                    </Stack>
                                                                </Box>
                                                                : '') : '')
                                                        }
                                                    </Box>
                                                ))}
                                            </Box>

                                            : ''
                                        } */}
                                    </Grid>
                                </Grid>
                            </Box>
                            : '' : ''
                        }
                        {/* {viewDetailsData && viewDetailsData?.targetFileName ?
                            <Box>
                                <Box sx={{marginTop: '8px'}} className="plateForm_text">Platforms to Select</Box>

                                <Box sx={{marginTop: '12px', display: "flex", justifyContent: "space-between"}}
                                     className="plateForm_box">

                                    <Box sx={{display: "flex"}}>
                                        <Box className="login-logo">
                                            <img
                                                src="/images/csv_icon.png"
                                                alt="logo"
                                                style={{height: 40}}
                                            />
                                        </Box>
                                        <Box sx={{margin: 'auto auto auto 12px'}}
                                             className="link_text">{viewDetailsData?.targetFileName}</Box>
                                    </Box>

                                    <Box>
                                        <IconButton aria-label="FileUploadOutlinedIcon" size="large"
                                                    onClick={() => downloadPoiFile(detailsData.id, true, viewDetailsData?.targetFileName)}>
                                            <SaveAltIcon color={'success'} fontSize="40px"/>
                                        </IconButton>
                                    </Box>

                                </Box>
                            </Box> : ''} */}

                        {/* {viewDetailsData && viewDetailsData?.competitorFileName ?
                            <Box>
                                <Box sx={{marginTop: '12px'}}>
                                    <Stack direction="row" spacing={1} alignItems={'center'}
                                           sx={{marginTop: '4px'}}>
                                        <img
                                            src="/images/input.png"
                                            alt="logo"
                                            style={{height: 16}}
                                        />
                                        <Box className="link_text">Competitor Analysis</Box>
                                    </Stack>
                                </Box>


                                <Box sx={{marginTop: '12px', display: "flex", justifyContent: "space-between"}}
                                     className="plateForm_box">

                                    <Box sx={{display: "flex"}}>
                                        <Box className="login-logo">
                                            <img
                                                src="/images/csv_icon.png"
                                                alt="logo"
                                                style={{height: 40}}
                                            />
                                        </Box>
                                        <Box sx={{margin: 'auto auto auto 12px'}}
                                             className="link_text">{viewDetailsData?.competitorFileName}</Box>
                                    </Box>

                                    <Box>
                                        <IconButton aria-label="FileUploadOutlinedIcon" size="large"
                                                    onClick={() => downloadPoiFile(detailsData.id, false, viewDetailsData?.competitorFileName)}>
                                            <SaveAltIcon color={'success'} fontSize="40px"/>
                                        </IconButton>
                                    </Box>

                                </Box>
                            </Box>
                            : ''} */}


                        {viewDetailsData.campaignRemark === 'ENDED' &&
                            <Box>
                                <Box sx={{mt: 2}} className="plateForm_text">Upload Report</Box>

                                <Box>
                                    <Controller
                                        name="file" // Name of the field
                                        control={control}
                                        render={({field}) => (

                                            <Box sx={{
                                                mt: 2
                                            }} className="upload_section">
                                                <CommonFileUploader
                                                    showFileList={true}
                                                    getFiles={(files) => {
                                                        handleFileChange(files);
                                                        field.onChange(files);
                                                    }}
                                                    name='MyFile'
                                                    fileTypes={["csv", "pdf"]}
                                                    label="reports"
                                                    containerheight="74"
                                                    borderColor="#EAECF0"
                                                />
                                                {/* <Box sx={{
                                                        marginTop: '12px',
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        cursor: 'pointer'
                                                    }}
                                                         className="upload_csv_box">

                                                        <Box sx={{display: "flex"}}>
                                                            <Box className="login-logo">
                                                                <img
                                                                    src="/images/upload_icon.png"
                                                                    alt="upload_icon"
                                                                    style={{height: 40}}
                                                                />
                                                            </Box>
                                                            <Box sx={{margin: 'auto', paddingLeft: '12px', display: "flex"}}
                                                                 className="link_text">
                                                                <Box className='csv_upload_text'>Click to upload</Box>
                                                                <Box ml={0.5} className='csv_upload_secondary6Text'>report
                                                                    details</Box>
                                                            </Box>
                                                        </Box>

                                                    </Box> */}
                                            </Box>
                                        )}
                                    />
                                </Box>


                                {fileData ?

                                    <Box sx={{marginTop: '30px', display: "flex", justifyContent: "space-between"}}
                                         className="plateForm_box">

                                        <Box sx={{display: "flex"}}>
                                            <Box className="login-logo">
                                                <img
                                                    src="/images/csv_icon.png"
                                                    alt="logo"
                                                    style={{height: 40}}
                                                />
                                            </Box>

                                            <Stack direction="column" spacing={1}
                                                   sx={{marginLeft: '12px'}}>
                                                <Box className="link_text">{fileName.name}</Box>
                                                <Box className="link_text"
                                                     sx={{color: '#475467!important', marginTop: '0px!important'}}>800
                                                    KB</Box>
                                            </Stack>

                                        </Box>

                                        <Box>
                                            <IconButton aria-label="FileUploadOutlinedIcon" size="large">
                                                <SaveAltIcon color={'success'} fontSize="40px"/>
                                            </IconButton>
                                        </Box>

                                    </Box>

                                    : null}


                                <Box sx={{marginTop: '36px',}}>
                                    <Button
                                        onClick={handleOnSubmit}
                                        type='submit'
                                        variant='contained'
                                        size='large'
                                        sx={{width: '100%', fontWeight: 600, borderRadius: '8px'}}
                                    >
                                        Submit
                                    </Button>
                                </Box>

                            </Box>
                        }
                        {
                            detailsData?.campaignRemark === "UNDER_REVIEW"  &&
                            <Box sx={{marginTop: '36px'}}>

                                <Box className="submit-container">
                                    <Box className="submit-container-left">
                                        <button className="btn_approved" onClick={handleOnApproved}>
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <DoneIcon sx={{fontSize: 20}} color={"success"}/>
                                                <Box className="btn_approved_text"> Approve Campaign</Box>
                                            </Stack>
                                        </button>
                                    </Box>

                                    <Box className="submit-container-right">
                                        <button className="btn_approved" onClick={handleOnRejected}>
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <ClearIcon sx={{fontSize: 20, color: "#B42318!important"}}/>
                                                <Box className="btn_approved_text">Reject</Box>
                                            </Stack>
                                        </button>
                                    </Box>
                                </Box>
                            </Box>
                        }

                    </Box>
                </Modal>
                : ''
            }
            <Box>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{width: '100%'}}
                    >
                        File upload successfully done.
                    </Alert>
                </Snackbar>
            </Box>
            <Box className="modal">
                <ConfirmationModal
                    isApprovedMode={isApprovedModal}
                    confirmationModalOpen={confirmModalOpen}
                    rejectReason={onHandelRejectReason}
                    handleIfConfirmationApproved={handleOnConfirmationApproved}
                    handleConfirmationModalClose={handleOnApprovedModalClose}>
                </ConfirmationModal>
            </Box>
        </>
    );
}

export default AdminViewDetailsModal;
