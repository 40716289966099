import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './Pagination.scss';
import { Box, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <Box 
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textAlign: "center"
                }}
            >
                <Box>
                    <Button className={classnames({ disabled: currentPage === 1 })}
                        onClick={onPrevious}
                        sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            border: " 1px solid #D0D5DD",
                            color: '#101828',
                            textTransform: 'none',
                            padding: "8px 14px 8px 14px"
                        }} startIcon={<ArrowBackIcon sx={{ color: "#344054", fontSize: "20px" }} />}>
                        Previous
                    </Button>
                </Box>
                <Box>
                    <ul
                        className={classnames('pagination-container', { [className]: className })}
                    >
                        {/*<li*/}
                        {/*    className={classnames('pagination-item', {*/}
                        {/*        disabled: currentPage === 1*/}
                        {/*    })}*/}
                        {/*    onClick={onPrevious}*/}
                        {/*>*/}
                        {/*    <div className="arrow left"/>*/}
                        {/*</li>*/}
                        {paginationRange.map((pageNumber, pageIndex) => {
                            if (pageNumber === DOTS) {
                                return <li key={pageIndex} className="pagination-item dots">&#8230;</li>;
                            }

                            return (
                                <li
                                    className={classnames('pagination-item', {
                                        selected: pageNumber === currentPage
                                    })}
                                    key={pageIndex}
                                    onClick={() => onPageChange(pageNumber)}
                                >
                                    {pageNumber}
                                </li>
                            );
                        })}
                        {/*<li*/}
                        {/*    className={classnames('pagination-item', {*/}
                        {/*        disabled: currentPage === lastPage*/}
                        {/*    })}*/}
                        {/*    onClick={onNext}*/}
                        {/*>*/}
                        {/*    <div className="arrow right"/>*/}
                        {/*</li>*/}
                    </ul>
                </Box>
                <Box>

                    <Box>
                        <Button onClick={onNext}
                            className={classnames({ disabled: currentPage === lastPage })}
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                border: " 1px solid #D0D5DD",
                                color: '#101828',
                                textTransform: 'none',
                                cursor: "pointer",
                                padding: "8px 14px 8px 14px"
                            }} endIcon={<ArrowForwardIcon sx={{ color: "#344054", fontSize: "20px" }} />}>
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>

    );
};

export default Pagination;
