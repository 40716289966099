import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import UserService from '../../../services/api/user.service';
import { useCookies } from "react-cookie";
import { useIdleTimer } from 'react-idle-timer';
import { accessToken, accessTokenTimeout, accessTokenTimerTransition, refreshToken, refreshTokenTimeout, userIdleTimeCountDownStartTime, userIdleTimeout } from '../../../config/config';
import { loadedLoggedIn, login, logout, setUser } from '../../../redux/slices/auth';
import { Alert, Box, LinearProgress, Snackbar } from '@mui/material';
import { setMessageSnackbarStateState } from '../../../redux/slices/common';
import { getCookie } from '../../../utils/cookie';
import { setRefreshTokenSchedule, setUserInteractionSchedule } from '../../../redux/slices/schedule';
import AuthService from '../../../services/api/auth.service';
import { getExpiryTime } from '../../../utils/date';
import Navbar from '../../../pages/navbar/navbar';
import io from "socket.io-client";

const DashboardLayout = ({ refreshTokenFromRedux, user, message, messageSnackbarState, loadingState, refreshTokenSchedule, userInteractionSchedule, setUser, setMessageSnackbarStateState, setRefreshTokenSchedule, setUserInteractionSchedule, logout, loadedLoggedIn }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(true);

  const [refreshTokenLoading, setRefreshTokenLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    if (loadingState) {
      console.log(loadingState);
    }
  }, [])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const refreshTokenFromCookie = getCookie(refreshToken);
      // console.log("------>refreshTokenFromCookie", refreshTokenFromCookie);
      // console.log("------>refreshTokenFromRedux", refreshTokenFromRedux);

      if (!refreshTokenFromRedux || !refreshTokenFromCookie) {
        if (window.location.href.includes('from=email')) {
          const redirectUrl = window.location.origin + window.location.pathname;
          localStorage.setItem('redirectUrl', redirectUrl);
        }
        // getAccessTokenByRefreshToken();

        setTimeout(() => {
          navigate('/');
          logoutCookie();
        }, 100);
      } else {
        setIsLoaded(true);
        if (!user) {
          UserService.getProfile().then(
            (data) => {

              if (data) {
                setUser(data.data);
                loadedLoggedIn({
                  loaded: true,
                  loggedIn: true
                });
                setTimeout(() => {
                  localStorage.removeItem('redirectUrl');
                  localStorage.setItem('clickEvent', '');
                }, 2000);
              } else {
                logoutCookie();
              }
              return Promise.resolve();
            },
            (error) => {
              logoutCookie();
              return Promise.reject();
            }
          );
        }
      }
    }
    return () => {
      mounted = false;
    }
  }, [refreshTokenFromRedux, cookies, location]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const refreshTokenFromCookie = getCookie(refreshToken);
      if (refreshTokenFromCookie) {
        getAccessTokenByRefreshToken();
        const refreshTokenInterval = setInterval(() => {
          getAccessTokenByRefreshToken();
        }, (accessTokenTimeout - accessTokenTimerTransition));
        setRefreshTokenSchedule(refreshTokenInterval);
      }
    }
    return () => {
      mounted = false;
    }
  }, []);

  const getAccessTokenByRefreshToken = () => {
    const refreshTokenFromCookie = getCookie(refreshToken);
    const accessTokenFromCookie = getCookie(refreshToken);
    if (refreshTokenFromCookie && !refreshTokenLoading) {
      setRefreshTokenLoading(true);
      const formData = {
        refreshToken: refreshTokenFromCookie,
        accessToken: accessTokenFromCookie
      };
      AuthService.getRefreshToken(formData).then(
        (data) => {
          const accessTokenExpiryTime = getExpiryTime(accessTokenTimeout);
          const refreshTokenExpiryTime = getExpiryTime(refreshTokenTimeout);
          setCookie(accessToken, data.data.data.accessToken, { path: '/', expires: accessTokenExpiryTime });
          setCookie(refreshToken, data.data.data.refreshToken, { path: '/', expires: refreshTokenExpiryTime });
          login({
            loaded: true,
            loggedIn: true,
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          });
          setRefreshTokenLoading(false);
          return Promise.resolve();
        },
        (error) => {
          console.log(error)
          logoutCookie();
        }
      );
    } else {
      console.log('error')
      logoutCookie();
    }
  };

  // const onIdle = () => {
  //   console.log('User is idle.');
  //   const userIdleInterval = setInterval(() => {
  //     logoutCookie();
  //   }, userIdleTimeout);
  //   setUserInteractionSchedule(userIdleInterval);
  // };

  // const onActive = () => {
  //   console.log('User is active.');
  //   if (userInteractionSchedule) {
  //     clearInterval(userInteractionSchedule);
  //     setUserInteractionSchedule(null);
  //   }
  // };

  // const idleTimer = useIdleTimer({
  //   onIdle,
  //   onActive,
  //   timeout: userIdleTimeCountDownStartTime,
  //   throttle: 500
  // });

  const logoutCookie = () => {
    removeCookie(accessToken, { path: '/' });
    removeCookie(refreshToken, { path: '/' });
    setUser(null);
    if (refreshTokenSchedule) {
      clearInterval(refreshTokenSchedule);
      // if (userInteractionSchedule) {
      //   clearInterval(userInteractionSchedule);
      // }
      setRefreshTokenSchedule(null);
      // setUserInteractionSchedule(null);
    }
    logout();
  }

  useEffect(() => {
    // const socket = io.connect("https://dev-stretch-api.memob.com/ws");
    // console.log(socket);
    // socket.on("/all/messages/36", (data) => {
    //   // setMessageReceived(data.message);
    //   console.log('sdfsdf', data);
    // });
    // return () => {
    //   socket.disconnect();
    // };

    // const socket = new WebSocket("wss://dev-stretch-api.memob.com");
    // socket.addEventListener("/all/messages/36", event => {
    //   console.log("Message from server ", event.data)
    // });

  }, []);

  return (<>
    {isLoaded &&
      <>
        <Navbar />

        <div className={`site-layout`} style={{ paddingTop: '80px' }}>
          {loadingState ? <Box style={{ width: "100%" }} ><LinearProgress /></Box> : null}
          <Outlet />
        </div>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={messageSnackbarState}
          onClose={() => setMessageSnackbarStateState(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={() => setMessageSnackbarStateState(false)} severity={message?.type} variant="filled" sx={{ width: '100%' }}>{message?.title}</Alert>
        </Snackbar>
      </>
    }
  </>)
}

const mapStateToProps = (state) => ({
  refreshTokenFromRedux: state.auth.auth.refreshToken,
  user: state.auth.auth.user,
  message: state.message.message,
  messageSnackbarState: state.common.messageSnackbarState,
  loadingState: state.common.loadingState,
  refreshTokenSchedule: state.schedule.refreshTokenSchedule,
  userInteractionSchedule: state.schedule.userInteractionSchedule
});

export default connect(mapStateToProps, { setUser, setMessageSnackbarStateState, setRefreshTokenSchedule, setUserInteractionSchedule, loadedLoggedIn, login, logout })(DashboardLayout);
