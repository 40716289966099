import React, {useState} from 'react';
import {ToggleButtonGroup, styled} from '@mui/material'
import {Box, Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import {Paper} from '@mui/material';
import Stack from '@mui/material/Stack';
import styles from './CampaignList.module.scss';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GenericTable from "../../components/common/GenericTable/genericTable";
import {useNavigate, useParams} from "react-router-dom";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SearchComponent from "../../components/common/SearchComponent/searchComponent";
import Pagination from "../../components/common/GenericTable/Pagination/Pagination";
import CampaignViewDetailsModal from "../../components/modals/campaignViewDetails/campaignViewDetails";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {useEffect} from "react";
import CustomToggleButton from "../../components/common/ToggleButton/toggleButton";
import {setIsLoadingState} from "../../redux/slices/common";
import {useDispatch, useSelector} from "react-redux";
import CampaignService from "../../services/api/campaign.service";
import moment from "moment";


const ToggleButton = styled(MuiToggleButton)(({selectedcolor}) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: selectedcolor,
    },
}));

const theme = createTheme({
    palette: {
        text: {
            primary: "#00ff00",
        },
    },
});

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    borderRadius: 5,
    color: theme.palette.text.secondary,
}));

// table config

const CampaignList = () => {
    const navigate = useNavigate();
    const {projectId} = useParams();
    const {projectName} = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.auth.user);
    const CompanyTemplate = ({row}) => {
        return (
            <>
                <Stack spacing={1}>
                    <Box style={{
                        fontWeight: '700',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        lineHeight: '30px',
                        color: '#101828'
                    }}>
                        {row.campaignTitle}
                    </Box>

                    <Chip label={(row.campaignRemark === 'ENDED') ? 'Ended' : (row.campaignRemark === 'RUNNING' ? 'Running' :
                        (row.campaignRemark === 'UNDER_REVIEW' ? 'Under Review' : (row.campaignRemark === 'DRAFT' ? 'Draft' :
                            (row.campaignRemark === 'PENDING' ? 'Pending' : (row.campaignRemark === 'REJECTED' ? 'REJECTED' :(row.campaignRemark === 'APPROVED' ? 'Approved' : 'Paused'))))))}

                          style={{
                              background: (row.campaignRemark === 'ENDED') ? '#344054' : (row.campaignRemark === 'RUNNING' ? '#039855' :
                                  (row.campaignRemark === 'UNDER_REVIEW' ? '#E04F16' : (row.campaignRemark === 'DRAFT' ? '#444CE7' :
                                      (row.campaignRemark === 'PENDING' ? '#344054' : (row.campaignRemark === 'REJECTED' ? '#B00020' :  (row.campaignRemark === 'APPROVED' ? '#039855' : '#344054')))))),
                              color: '#ffffff',
                              width: 'fit-content'
                          }}/>
                </Stack>
            </>
        );
    }
    const StartDateTemplate = ({row}) => {
        return (
            <>
                <Box sx={{marginTop: '3px'}}>
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#475467',
                        }}>Starting Date</Box>
                    <Stack direction="row" spacing={1} alignItems={'center'}
                           sx={{marginTop: '3px'}}>
                        <CalendarTodayIcon sx={{fontSize: 20}}/>
                        <Box sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#101828',
                        }}>{row.campaignStartDate ? (moment(row.campaignStartDate).format('DD/MM/YYYY')) : '-'}</Box>
                    </Stack>
                </Box>
            </>
        );
    }
    const EndDateTemplate = ({row}) => {
        return (
            <>
                <Box sx={{marginTop: '3px'}}>
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#475467',
                        }}>End Date</Box>
                    <Stack direction="row" spacing={1} alignItems={'center'}
                           sx={{marginTop: '3px'}}>
                        <CalendarTodayIcon sx={{fontSize: 20}}/>
                        <Box sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#101828',
                        }}>{row.campaignEndDate ? moment(row.campaignEndDate).format('DD/MM/YYYY') : '-'}</Box>
                    </Stack>
                </Box>
            </>
        );
    }
    const ActionTemplate = ({row}) => {
        ;
        return (
            <>
                <Box sx={{borderLeft: '2px solid #D0D5DD', paddingLeft: '20px'}}>
                    <Stack spacing={2} direction="row">
                        {(row.campaignRemark !== 'DRAFT') && <Button variant="text"
                                                             onClick={(e) => {
                                                                 onViewDetailsClick(e, row);
                                                             }}
                                                             sx={{
                                                                 fontWeight: '600',
                                                                 fontSize: '14px',
                                                                 lineHeight: '20px',
                                                                 color: '#101828',
                                                                 textTransform: 'none',
                                                             }} disableRipple> View Details</Button>
                        }
                        {['ENDED', 'RUNNING', 'ACTIVE','APPROVED'].includes(row.campaignRemark) && <Button sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#101828',
                            textTransform: 'none',
                        }} startIcon={<SaveAltIcon color="success"/>}>
                            {(row.campaignRemark === 'ENDED') ? 'Reports' : 'Tags'}
                        </Button>
                        }

                        {(row.campaignRemark === 'DRAFT' || row.campaignRemark === 'REJECTED' || row.campaignRemark === 'UNDER_REVIEW') && <Button variant="text"
                                                             onClick={(e) => {
                                                                 gotoCampaignEdit(e, row);
                                                             }}
                                                             sx={{
                                                                 fontWeight: '600',
                                                                 fontSize: '14px',
                                                                 lineHeight: '20px',
                                                                 color: '#6941C6',
                                                                 textTransform: 'none',
                                                             }} disableRipple> Continue Editing</Button>
                        }
                    </Stack>
                </Box>
            </>
        );
    }

    const tableConfig = {
        ShowHeaderTemplate: false,
        Columns: [
            {
                Key: 'name',
                Title: 'Name',
                CellTemplate: CompanyTemplate,
            },
            {
                Key: 'startDate',
                Title: 'Start Date',
                CellTemplate: StartDateTemplate
            },
            {
                Key: 'endDate',
                Title: 'endDate',
                CellTemplate: EndDateTemplate
            },
            {
                Key: 'view',
                Title: 'View',
                CellTemplate: ActionTemplate
            },
        ],
        PageSize: 5,
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [value, setFilter] = React.useState("list");
    const [showListView, setShowViewOption] = React.useState(true);
    const [clientViewModalOpen, setClientViewModalOpen] = useState(false);
    const [clientDetails, setClientDetails] = React.useState();
    const [campaignLists, setCampList] = useState([]);
    const [filterBy, setFilterBy] = React.useState('All');
    const [totalTableRecords, setTotalTableRecords] = useState(0);
    const [searchValue, setSearchValue] = useState(null);
    const [pageQueryParams, setPageQueryParams] = useState({page: 0, size: tableConfig?.PageSize});
    useEffect(() => {
        // if (user?.id) {
            let queryParams;
            if (searchValue) {
                queryParams = `projectCode=${projectId}&campaignName=${searchValue}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`;
            } else {
                queryParams = `projectCode=${projectId}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`;
            }
            if (filterBy !== 'All') {
                queryParams = `campaignRemark=${filterBy}&projectCode=${projectId}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`
            }

            dispatch(setIsLoadingState(true));

            CampaignService.getAllClientCampaignList(queryParams).then(
                (data) => {
                    setTotalTableRecords(data.data.totalElements);
                    dispatch(setIsLoadingState(false));
            
                    if (data.data.content.length > 0 && data.data.content[0].campaignDetails) {
                        setCampList(data.data.content[0].campaignDetails);
                    } else {
                        // Handle the case where content is empty or doesn't have campaignDetails
                        setCampList([]); // Set an empty list or any fallback behavior as needed
                    }
            

                    return Promise.resolve();
                },
                (error) => {
                    console.log("Error Message", error.response.data.message);
                    return Promise.REJECTED();
                }
            ).finally(() => {
                dispatch(setIsLoadingState(false));
            });
        // }

    }, [ pageQueryParams, searchValue, filterBy]);

    const handelOnChangePage = (page) => {
        if (page) {
            setCurrentPage(page)
            setPageQueryParams({
                ...pageQueryParams,
                page: (page - 1),
            });
        }
    }
    const handleFilterAlignment = (event, newAlignment) => {
        setFilter(newAlignment);
        if (newAlignment === 'list') {
            setCurrentPage(1);
            setPageQueryParams({
                ...pageQueryParams,
                page: 0,
            });
            setShowViewOption(true);
        } else {
            setCurrentPage(1);
            setPageQueryParams({
                ...pageQueryParams,
                page: 0,
            });
            setShowViewOption(false);
        }
    };

    const CustomToggle = styled(ToggleButton)({
        color: '#6941C6',
    })


    const onViewDetailsClick = (e, row) => {
        e.preventDefault();
        setClientViewModalOpen(true);
        setClientDetails(row);

    }
    const onSelectRow = (e) => {

    }
    const onChangePage = (e) => {
        if (e) {
            setPageQueryParams({
                ...pageQueryParams,
                page: (e - 1),
            });
        }
    }
    const gotoCampaignEdit = (e, row) => {
        e.preventDefault();
        navigate(`/edit-campaign/${row.campaignId}`)
    }

    const handleClientDetailsModalClose = () => {
        setClientViewModalOpen(false);
    };
    const onHandleFilterBy = (e) => {
        if (e) {
            setFilterBy(e);
        }
    }
    const handleChange = (value) => {
        if (value) {
            setSearchValue(value);
        } else {
            setSearchValue(null);
        }
    };


    return (<>
        <Box sx={{display: 'flex'}}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                p: "2% 4% 2% 4%"
            }}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Box direction="row" justifyContent="start" style={{
                        flexDirection: "column",
                        fontWeight: '600',
                        fontSize: '18px',
                        lineHeight: '28px',
                        color: '#000000',
                        textAlign: 'right'

                    }}>{projectName}'s Campaigns</Box>
                </Grid>

                <Stack direction="row" sx={{marginTop: "12px", width: '100%', flexDirection: {xs: 'column', sm: 'row'}}}
                       justifyContent="space-between">
                    <Box sx={{justifyContent: 'center'}}>
                        <CustomToggleButton filterBy={onHandleFilterBy}/>
                    </Box>
                    <Box sx={{justifyContent: 'center', textAlign: 'right', mt: {xs: 2, sm: 0}}}>
                        <Box>
                            <Stack direction="row" spacing={2}>
                                <Box>
                                    <SearchComponent searchData={handleChange}/>
                                </Box>
                                <Box>
                                    <ThemeProvider theme={theme}>
                                        <ToggleButtonGroup
                                            value={value}
                                            exclusive
                                            onChange={handleFilterAlignment}
                                            variant="outlined"
                                            color="primary"
                                        >
                                            <ToggleButton value="list" selectedcolor="#6941C6">
                                                <FormatAlignJustifyIcon/>
                                            </ToggleButton>
                                            <ToggleButton value="grid" selectedcolor="#6941C6">
                                                <BorderAllIcon/>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </ThemeProvider>
                                </Box>
                            </Stack>

                        </Box>

                    </Box>
                </Stack>

                {/*table start*/}
                {showListView ?
                    <Box>
                        {campaignLists.length > 0 ?
                            <Box style={{width: "100%", marginTop: '30px'}}>
                                <GenericTable tableConfig={tableConfig}
                                              tableData={campaignLists}
                                              totalRecords={totalTableRecords}
                                              getCurrentPageNumber={(e) => {
                                                  onChangePage(e)
                                              }}
                                              onRowClick={(e) => {
                                                  onSelectRow(e)
                                              }}
                                />
                            </Box> :

                            <Box sx={{
                                textAlign: "center",
                                marginTop: "30px",
                                fontWeight: '600',
                                fontSize: '20px',
                                lineHeight: '28px',
                                color: '#000000',
                            }}> No Data Found
                            </Box>
                        } </Box> : null
                }



{!showListView ? (
            <Box>
                {campaignLists.length > 0 ? (
                    <Box sx={{ flexGrow: 1, marginTop: '30px' }}>
                        <Grid container spacing={{ xs: 2, sm: 2, md: 3 }}>
                            {campaignLists.map((row) => (
                                <Grid item key={row.id || row.campaignTitle}> {/* Ensure unique key */}
                                    <Box sx={{
                                        p: 3,
                                        boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                                        border: "1px solid #F2F4F7",
                                        borderRadius: "12px"
                                    }}>
                                        <Box sx={{ marginTop: '8px' }}>
                                            <Stack spacing={1}>
                                                <Box className={styles.TextTitle} sx={{ fontSize: '18px' }}>{row.campaignTitle}</Box>
                                                <Chip
                                                    label={
                                                        row.campaignRemark === 'ENDED' ? 'Ended' :
                                                        row.campaignRemark === 'RUNNING' ? 'Running' :
                                                        row.campaignRemark === 'UNDER_REVIEW' ? 'Under Review' :
                                                        row.campaignRemark === 'DRAFT' ? 'Draft' :
                                                        row.campaignRemark === 'PENDING' ? 'Pending' :
                                                        row.campaignRemark === 'REJECTED' ? 'REJECTED' : 
                                                        row.campaignRemark === 'APPROVED' ? 'APPROVED' : 'Paused'
                                                    }
                                                    style={{
                                                        background: row.campaignRemark === 'ENDED' ? '#344054' :
                                                        row.campaignRemark === 'RUNNING' ? '#039855' :
                                                        row.campaignRemark === 'UNDER_REVIEW' ? '#E04F16' :
                                                        row.campaignRemark === 'DRAFT' ? '#444CE7' :
                                                        row.campaignRemark === 'PENDING' ? '#344054' :
                                                        row.campaignRemark === 'REJECTED' ? '#B00020' :
                                                        row.campaignRemark === 'APPROVED' ? '#039855' :'#344054',
                                                        color: '#ffffff',
                                                        width: 'fit-content'
                                                    }}
                                                />
                                            </Stack>
                                        </Box>
                                        <Box sx={{ marginTop: "12px", display: "flex", gap: 1 }} justifyContent="space-between" alignItems="center">
                                            <Box sx={{ marginTop: '3px' }}>
                                                <Box className={styles.DateTextTitle} sx={{ color: '#667085' }}>Starting Date</Box>
                                                <Stack direction="row" spacing={1} alignItems={'center'} sx={{ marginTop: '4px' }}>
                                                    <CalendarTodayIcon sx={{ fontSize: 20 }} />
                                                    <Box className={styles.DateText} sx={{ color: '#344054' }}>
                                                        {row.campaignStartDate ? moment(row.campaignStartDate).format('DD/MM/YYYY') : '-'}
                                                    </Box>
                                                </Stack>
                                            </Box>

                                            <Box>
                                                <Box className={styles.DateTextTitle} sx={{ color: '#667085' }}>Ending Date</Box>
                                                <Stack direction="row" spacing={1} alignItems={'center'} sx={{ marginTop: '4px' }}>
                                                    <CalendarTodayIcon sx={{ fontSize: 20 }} />
                                                    <Box className={styles.DateText} sx={{ color: '#344054' }}>
                                                        {row.campaignEndDate ? moment(row.campaignEndDate).format('DD/MM/YYYY') : '-'}
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ marginTop: "12px", marginBottom: "12px" }} />

                                        {['UNDER_REVIEW', 'DRAFT','REJECTED'].includes(row.campaignRemark) ? (
                                            <Box sx={{ textAlign: 'center' }}>
                                                {(row.campaignRemark === 'UNDER_REVIEW' || row.campaignRemark === 'REJECTED' ||row.campaignRemark === 'DRAFT') ? (
                                                   <> <Button
                                                        variant="text"
                                                        className={styles.ViewBtn}
                                                        sx={{ color: ' #344054' }}
                                                        onClick={(e) => onViewDetailsClick(e, row)}
                                                    >
                                                        View Details
                                                    </Button>
                                                     <Button
                                                     variant="text"
                                                     className={styles.ViewBtn}
                                                     sx={{ color: ' #6941C6' }}
                                                     onClick={(e) => gotoCampaignEdit(e, row)}
                                                 >
                                                     Continue Editing
                                                 </Button></>
                                                ) : (
                                                    <Button
                                                        variant="text"
                                                        className={styles.ViewBtn}
                                                        sx={{ color: ' #6941C6' }}
                                                        onClick={(e) => gotoCampaignEdit(e, row)}
                                                    >
                                                        Continue Editing
                                                    </Button>
                                                )}
                                            </Box>
                                        ) : (
                                            <Stack sx={{ marginTop: "12px", width: '100%' }} direction="row" justifyContent="space-between" alignItems="center">
                                                <Box sx={{ justifyContent: 'center' }}>
                                                    <Button
                                                        variant="text"
                                                        className={styles.ViewBtn}
                                                        sx={{ color: ' #344054' }}
                                                        onClick={(e) => onViewDetailsClick(e, row)}
                                                    >
                                                        View Details
                                                    </Button>
                                                </Box>
                                                <Box sx={{ justifyContent: 'center', textAlign: 'right' }}>
                                                    <Button
                                                        sx={{ color: "#344054", textTransform: 'none' }}
                                                        startIcon={<FileUploadOutlinedIcon color={"success"} />}
                                                    >
                                                        {row.campaignRemark === 'ENDED' ? 'Reports' : 'Tags'}
                                                    </Button>
                                                </Box>
                                            </Stack>
                                        )}
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        <Box sx={{ mt: 2 }}>
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={totalTableRecords}
                                pageSize={tableConfig?.PageSize}
                                onPageChange={handelOnChangePage}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ textAlign: "center", marginTop: "30px", fontWeight: '600', fontSize: '20px', lineHeight: '28px', color: '#000000' }}>
                        No Data Found
                    </Box>
                )}
            </Box>
        ) : null}


            </Box>
        </Box>

        <Box className="modal">
            <CampaignViewDetailsModal
                clientViewModalOpen={clientViewModalOpen}
                detailsData={clientDetails}
                handleClientViewModalClose={handleClientDetailsModalClose}/>
        </Box>

    </>);
}
export default CampaignList;

