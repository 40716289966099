import React from "react";
import { Controller } from "react-hook-form";
import {
    Box, Radio, RadioGroup, FormControlLabel, FormControl, Tooltip, Checkbox
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info'
import CommonFileUploader from "../../../common/CommonFileUploader/CommonFileUploader";

const LocationDetails = ({ control, getValues, setPOIsData, setCompetitorPOIsData }) => {

    const getPOIs = (files) => {
        setPOIsData(files);
    }

    const getCompetitorAnalysis = (files) => {
        setCompetitorPOIsData(files);
    }

    return (
        <>
            <Box sx={{ mt: 4 }} className="create-campaign-title">Target POIs</Box>
            <Controller
                name="POIs" // Name of the field
                control={control}
                render={({ field }) => (
                    <> 
                     <CommonFileUploader 
                        getFiles={(files) => {
                            field.onChange(files);
                            getPOIs(files); // Update state
                        }}
                        fileTypes={["csv"]}
                        file={field.value} // Use field.value as the file prop
                        showFileList={true}
                        label="pois"
                        containerheight="74"
                        borderColor= "#EAECF0"
                    />
                    <a href="https://dev-stretch-api.memob.com/assets/file/Mik.csv">Download Sample CSV File</a>
                    </>
                )}
            />

            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ marginTop: "16px" }} className="create-campaign-title">Reporting Type</Box>
                <Tooltip title="Tooltips are used to describe or identify an element. In most scenarios, tooltips help the user understand meaning, function, or alt-text.">
                    <InfoIcon />
                </Tooltip>
            </Box>

            <FormControl sx={{ ml: 4 }}>
                {/* Wrap RadioGroup with Controller and specify the name */}
                <Controller
                    name="reportingType"
                    control={control}
                    defaultValue="Standard" // Set default value here
                    render={({ field }) => (
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            {...field}
                        >
                            <FormControlLabel value="Standard" control={<Radio />} label="Standard" />
                            <FormControlLabel value="Enriched Report" control={<Radio />} label="Enriched Report" />
                        </RadioGroup>
                    )}
                />
            </FormControl>

            <Box>
                {/* Wrap Checkbox with Controller and specify the name */}
                <Controller
                    name="competitorAnalysis"
                    control={control}
                    defaultValue={false} // Set default value here
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="Competitor Analysis"
                        />
                    )}
                />
            </Box>

            {/* Render CommonFileUploader conditionally based on checkbox state */}
            {getValues("competitorAnalysis") &&  (
                <Controller
                    name="competitorPOIs" // Name of the field
                    control={control}
                    render={({ field }) => (
                        <>
                            <CommonFileUploader 
                                getFiles={(files) => {
                                    field.onChange(files);
                                    getCompetitorAnalysis(files); // Update state
                                }}
                                fileTypes={["csv"]}
                                file={field.value} // Use field.value as the file prop
                                showFileList={true}
                                label="pois"
                                containerheight="74"
                                borderColor= "#EAECF0"
                            />
                            <a href="https://dev-stretch-api.memob.com/assets/file/Mik.csv">Download Sample CSV File</a> 
                        </>
                    )}
                />
            )}
        </>
    );
}

export default LocationDetails;