import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import '../create-campaign-modal/CreateCampaign.scss';
import TeamMemberService from '../../../services/api/team-member.service';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../../redux/slices/message';
import { setMessageSnackbarStateState } from '../../../redux/slices/common';
import { errorMessage } from '../../../config/config';
import { setIsLoadingState } from "../../../redux/slices/common"
import { LoadingButton } from '@mui/lab';


function AddMember({
    isEditMode,
    selectedMember,
    createMemberModalOpen,
    handleCreateMemberModalClose,
    setTeamMembers,
    updateSuccessFull
}) {
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue, formState: { errors }, control, reset } = useForm();
    const user = useSelector((state) => state.auth.auth.user);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (selectedMember) {
            setValue('companyName', selectedMember?.companyName);
            setValue('firstName', selectedMember?.firstName);
            setValue('lastName', selectedMember.lastName);
            setValue('memberEmail', selectedMember?.email);

        }
    }, [selectedMember, setValue]);

    const handleOnClose = () => {
        handleCreateMemberModalClose(true);
        reset();
    };

    const createTeamMember = (data) => {
        setLoading(true)
        const params = {
            companyName: data?.companyName,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.memberEmail

        }

        TeamMemberService.createTeamMember(params)
            .then(res => {
                if (res) {
                    setLoading(false)
                    dispatch(setMessage({
                        type: "success",
                        title: res.message
                    }));
                    dispatch(setMessageSnackbarStateState(true));
                    dispatch(setIsLoadingState(false))
                    let queryParams = `page=0&size=8`

            TeamMemberService.getTeamMembers(queryParams)
            .then((res) => {
                if (res && res?.data?.content?.length > 0) {
                    // setLoading(false);
                    const loadedRows = [];
                    res.data.content.forEach((member) => {
                        loadedRows.push({
                            id: member.id,
                            image: member.profileImageURL,
                            name: member.firstName + ' ' + member.lastName,
                            status: member.enabled,
                            // projectCount: member.projectCount,
                            // campaignCount: member.campaignCount,
                            email: member.email
                        });
                    });
                    setTeamMembers(loadedRows);
                }
            })
            .finally(() => {
                // setLoading(false);
            });
                } else {
                    setLoading(false)
                    dispatch(setMessage({
                        type: "error",
                        title: errorMessage
                    }));
                    dispatch(setMessageSnackbarStateState(true));
                }
                handleOnClose();
            })
            .catch(err => {
                setLoading(false)
                dispatch(setMessage({
                    type: "error",
                    title: err?.response?.data?.message || errorMessage
                }));
                dispatch(setMessageSnackbarStateState(true));
            })
        // reset();
    }

    const editTeamMember = (data) => {
        const params = {
            name: data?.companyName,
            email: data?.memberEmail,
        }

        TeamMemberService.updateTeamMember(selectedMember?.id, params)
            .then(res => {
                dispatch(setMessage({
                    type: "success",
                    title: res
                }));
                dispatch(setMessageSnackbarStateState(true));
                // updateSuccessFull({
                //     name: data?.memberName,
                //     email: data?.memberEmail,
                //     isActive: data?.memberStatus === 1,
                //     id: user?.id,
                // });
                handleOnClose();
            })
            .catch(err => {
                console.error(err)
            })
    }


    const onSubmit = (data) => {
        
        if (!isEditMode) {
            createTeamMember(data);
            console.log('created data:', data)
        } else {
            editTeamMember(data);
        }

       
    }

    return (
        <Modal
            open={createMemberModalOpen}
            onClose={handleCreateMemberModalClose}
            sx={{
                backdropFilter: 'blur(15px)',
            }}
        >
            <Box className='create-campaign-modal'>
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    type='reset'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {isEditMode ? (
                            <Box className='login-logo'>
                                <img
                                    src='/images/edit-member.png'
                                    alt='logo'
                                    style={{ height: 48 }}
                                />
                            </Box>
                        ) : (
                            <Box className='login-logo'>
                                <img
                                    src='/images/add-member.png'
                                    alt='logo'
                                    style={{ height: 48 }}
                                />
                            </Box>
                        )}
                        <Box
                            sx={{
                                cursor: 'pointer',
                            }}
                            className='login-logo'
                            onClick={handleOnClose}
                        >
                            <img
                                src='/images/close-icon.png'
                                alt='logo'
                                style={{ height: 48 }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box variant='h5' sx={{ fontWeight: 600, fontSize: '30px', mb: 4, mt: 1 }}>
                            {isEditMode === true ? 'Edit ' : 'Add New '} Member
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Box htmlFor='clientName' sx={{ fontWeight: 500, fontSize: "18px", color: "#344054" }}>
                                Company Name
                            </Box>

                            <Box className='input'>
                                <TextField
                                    variant='outlined'
                                    sx={{ width: '100%', mt: '10px' }}
                                    placeholder='Enter your company name'
                                    {...register('companyName', { required: true })}
                                />
                                {errors.companyName && (
                                    <FormHelperText error>This feild is required</FormHelperText>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Box htmlFor='clientName' sx={{ fontWeight: 500, fontSize: "18px", color: "#344054" }}>
                                First Name
                            </Box>

                            <Box className='input'>
                                <TextField
                                    variant='outlined'
                                    sx={{ width: '100%', mt: '10px' }}
                                    placeholder='Enter your first name'
                                    {...register('firstName', { required: false })}
                                />
                                {errors.firstName && (
                                    <FormHelperText error>This feild is required</FormHelperText>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Box htmlFor='clientName' sx={{ fontWeight: 500, fontSize: "18px", color: "#344054" }}>
                                Last Name
                            </Box>

                            <Box className='input'>
                                <TextField
                                    variant='outlined'
                                    sx={{ width: '100%', mt: '10px' }}
                                    placeholder='Enter your last name'
                                    {...register('lastName', { required: false })}
                                />
                                {errors.lastName && (
                                    <FormHelperText error>This feild is required</FormHelperText>
                                )}
                            </Box>
                        </Box>


                        <Box sx={{ mb: 2 }}>
                            <Box htmlFor='clientName' sx={{ fontWeight: 500, fontSize: "18px", color: "#344054" }}>
                                Email Address
                            </Box>

                            <Box className='input'>
                                <TextField
                                    variant='outlined'
                                    sx={{ width: '100%', mt: '10px' }}
                                    type='email'
                                    placeholder='Enter your email address'
                                    {...register('memberEmail', { required: true, pattern: /^\S+@\S+$/i, message: 'Please type valid email' })}
                                />
                                {errors.memberEmail && (
                                    <FormHelperText error>{errors.memberEmail.message || "This field is required."}</FormHelperText>
                                )}
                            </Box>
                        </Box>


                        {/* <Box>
                            <Box htmlFor='clientName' sx={{fontWeight: 500, fontSize: "18px", color: "#344054"}}>
                                Status
                            </Box>

                            <Box className='input'>
                                <FormControl sx={{width: '100%', mt: '10px'}} >
                                    <Controller
                                        control={control}
                                        name="memberStatus"
                                        defaultValue="1"
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                displayEmpty
                                            >
                                                <MenuItem value={1}>Activate</MenuItem>
                                                <MenuItem value={0}>Offline</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Box>
                            {errors.memberStatus && (
                                <FormHelperText error>Please select status.</FormHelperText>
                            )}
                        </Box> */}
                        <LoadingButton loading={loading} type='submit' variant='contained' size='large' sx={{
                            width: "100%",
                            fontWeight: 600,
                            borderRadius: '8px',
                            textTransform: "none",
                            mt: 4
                        }}>
                            Save
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default AddMember;
