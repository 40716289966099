import './scss/style.scss';
import React, {useState} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import store from './redux/store';
import MainLayout from './components/layouts/main-layout/MainLayout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Inter',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#6927DA'
    }
  }
});
const App = () => {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
              <ThemeProvider theme={theme}>
              <MainLayout />
              </ThemeProvider>
          
        </BrowserRouter>
      </CookiesProvider>
    </Provider>
  )
}

export default App;
