import { authApi } from '../interceptor/auth.interceptor';

class ClientService {

  static async getClientDashBoardData(clientId) {
    const queryParams = `companyId=${clientId}`;
    if(!clientId) {
      return await authApi
      .get(`client/dashboard/ProjectWiseCampaignSummary`)
      .then((response) => {
        return response?.data;
      });
    }else{
      return await authApi
      .get(`client/dashboard/ProjectWiseCampaignSummary?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
    }
   
  }

  static async getClients(userId) {
    return await authApi
      .get(`client/list?userId=${userId}`)
      .then((response) => {
        return response?.data;
      });
  }
  static async createClient(queryParams) {
    try {
      const response = await authApi.post(`/registration`, queryParams, {
        headers: {
          'registration-for': 'client', 
        },
      });
      
      return response.data; // Directly return the data from the response
    } catch (error) {
      console.error('Error creating team member:', error);
      throw error; // Re-throw the error after logging it
    }
  }


  static async updateClient(userId, formData) {
    return await authApi
    .put(`client/edit?clientId=${userId}`, formData)
    .then((response) => {
      return response?.data;
    });
  }
    static async getAllClients(queryParams ) {
        return await authApi
            .get(`admin/dashboard/allClients?${queryParams}`)
            .then((response) => {return response?.data})
            .catch((error) => {
              console.error('Error fetching all clients:', error);
      })
    }
}

export default ClientService;
