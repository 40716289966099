import {
  Description,
  DescriptionWrapper,
  HoverMsg,
  UploaderWrapper
} from './style';
import { acceptedExt, checkType, getFileSizeMB } from './utils';
import React, { useEffect, useRef, useState } from 'react';
import DrawTypes from './DrawTypes';
import ImageAdd from './ImageAdd';
import useDragging from './useDragging';

import { Box } from '@mui/material';

function ProjectDescription() {
  return (<>
    <Box sx={{
      height: "74px"
    }}>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Box
          sx={{
            mr: 2,
            width: "40px",
            height: "40px",
            border: "1px solid #EAECF0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px"
          }}
          // className='login-logo'
        >
          <img
            src='/images/project_upload.png'
            alt='logo'
            style={{ width: 20, height: 20 }}
          />
        </Box>
        <Box>
          <Box sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#475467"
          }}>
            <a style={{
              fontWeight: 600,
              fontSize: 14
            }}>Click to upload</a> or drag and drop
          </Box>
          <Box  sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "#475467"
          }}>PNG, JPG(max. 800x400px)</Box>
        </Box>
      </Box>

    </Box>

  </>);
}
function DcmDescription() {
  return (<>
    <Box sx={{
      height: "74px"
    }}>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Box
          sx={{
            mr: 2,
            width: "40px",
            height: "40px",
            // border: "1px solid #EAECF0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // borderRadius: "5px"
          }}
          // className='login-logo'
        >
          <img
            src='/images/upload_icon.png'
            alt='logo'
            style={{ width: 40, height: 40 }}
          />
        </Box>
        <Box>
          <Box sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#475467"
          }}>
            <a style={{
              fontWeight: 600,
              fontSize: 14,
              color:"black"
            }}>Click to upload</a> DCM Tracker in a csv format
          </Box>
        </Box>
      </Box>

    </Box>

  </>);
}

function PoisDescription() {
  return (<>
    <Box sx={{
      height: "74px"
    }}>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Box
          sx={{
            mr: 2,
            width: "40px",
            height: "40px",
            // border: "1px solid #EAECF0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // borderRadius: "5px"
          }}
          // className='login-logo'
        >
          <img
            src='/images/upload_icon.png'
            alt='logo'
            style={{ width: 40, height: 40 }}
          />
        </Box>
        <Box>
          <Box sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#475467"
          }}>
            <a style={{
              fontWeight: 600,
              fontSize: 14,
              color:"black"
            }}>Click to upload POIs</a> or drag and drop CSV
          </Box>
        </Box>
      </Box>

    </Box>

  </>);
}

function ReportsDescription() {
  return (<>
    <Box sx={{
      height: "74px"
    }}>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Box
          sx={{
            mr: 2,
            width: "40px",
            height: "40px",
            // border: "1px solid #EAECF0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // borderRadius: "5px"
          }}
          // className='login-logo'
        >
          <img
            src='/images/upload_icon.png'
            alt='logo'
            style={{ width: 40, height: 40 }}
          />
        </Box>
        <Box>
          <Box sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#475467"
          }}>
            <a style={{
              fontWeight: 600,
              fontSize: 14,
              color:"black"
            }}>Click to upload</a> report details
          </Box>
        </Box>
      </Box>

    </Box>

  </>);
}

function drawDescription(currFile, uploaded, typeError, disabled, label) {
  return typeError ? (
    <span>File type/size error, Hovered on types!</span>
  ) : (
    <Description>
      {false ? (
        <span>Upload disabled</span>
      ) :
      //  !currFile && !uploaded ?
      
      (
        <>
          {label == "project" && <ProjectDescription />}
          {label == "dcm" && <DcmDescription />}
          {label == "pois" && <PoisDescription />}
          {label == "reports" && <ReportsDescription />}

        </>
      ) 
      // : (
      //   <>
      //     <span>Uploaded Successfully!</span> Upload another?
      //   </>
      // )
      
      }
    </Description>
  );
}

function FileUploader(props) {
  const {
    name,
    hoverTitle,
    types,
    handleChange,
    classes,
    children,
    maxSize,
    minSize,
    fileOrFiles,
    onSizeError,
    onTypeError,
    onSelect,
    onDrop,
    disabled,
    label,
    multiple,
    required,
    onDraggingStateChange,
    dropMessageStyle
  } = props;

  const labelRef = useRef(null);
  const inputRef = useRef(null);
  const [uploaded, setUploaded] = useState(false);
  const [currFiles, setFile] = useState(null);
  const [error, setError] = useState(false);

  const validateFile = (file) => {
    if (types && !checkType(file, types)) {
      setError(true);
      if (onTypeError) onTypeError('File type is not supported');
      return false;
    }
    if (maxSize && getFileSizeMB(file.size) > maxSize) {
      setError(true);
      if (onSizeError) onSizeError('File size is too big');
      return false;
    }
    if (minSize && getFileSizeMB(file.size) < minSize) {
      setError(true);
      if (onSizeError) onSizeError('File size is too small');
      return false;
    }
    return true;
  };

  const handleChanges = (files) => {
    let checkError = false;
    if (files) {
      if (files instanceof File) {
        checkError = !validateFile(files);
      } else {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          checkError = !validateFile(file) || checkError;
        }
      }
      if (checkError) return false;
      if (handleChange) handleChange(files);
      setFile(files);

      setUploaded(true);
      setError(false);
      return true;
    }
    return false;
  };

  const blockEvent = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };
  const handleClick = (ev) => {
    ev.stopPropagation();
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  const handleInputChange = (ev) => {
    const allFiles = ev.target.files;
    const files = multiple ? allFiles : allFiles[0];
    const success = handleChanges(files);
    if (onSelect && success) onSelect(files);
  };
  const dragging = useDragging({
    labelRef,
    inputRef,
    multiple,
    handleChanges,
    onDrop
  });

  useEffect(() => {
    onDraggingStateChange?.(dragging);
  }, [dragging]);

  useEffect(() => {
    if (fileOrFiles) {
      setUploaded(true);
      setFile(fileOrFiles);
    } else {
      if (inputRef.current) inputRef.current.value = '';
      setUploaded(false);
      setFile(null);
    }
  }, [fileOrFiles]);

  return (
    <UploaderWrapper
      overRide={children}
      className={`${classes || ''} ${disabled ? 'is-disabled' : ''}`}
      ref={labelRef}
      htmlFor={name}
      onClick={blockEvent}
    >
      <input
        onClick={handleClick}
        onChange={handleInputChange}
        accept={acceptedExt(types)}
        ref={inputRef}
        type="file"
        name={name}
        disabled={disabled}
        multiple={multiple}
        required={required}
      />
      {dragging && (
        <HoverMsg style={dropMessageStyle}>
          <span>{hoverTitle || 'Drop Here'}</span>
        </HoverMsg>
      )}
      {!children && (
        <>
          <ImageAdd />
          <DescriptionWrapper error={error}>
            {drawDescription(currFiles, uploaded, error, disabled, label)}
            {/* <DrawTypes types={types} minSize={minSize} maxSize={maxSize} /> */}
          </DescriptionWrapper>
        </>
      )}
      {children}
    </UploaderWrapper>
  );
}

export default FileUploader;