import React, { useState, useEffect } from "react";
import { Alert, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/api/auth.service";
import { login } from "../../redux/slices/auth";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import {useParams} from "react-router-dom";

import {
  errorMessage
} from "../../config/config";

const ResetPassword = ({
  refreshTokenFromRedux,
}) => {
  let token = useParams().token;
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [passwordMode, setPasswordMode] = useState({
    password: "password",
  });
  const [confirmPasswordMode, setConfirmPasswordMode] = useState({
    confirmPassword: "password",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //     if (refreshTokenFromRedux) {
  //       // console.log("refreshTokenFromRedux", refreshTokenFromRedux);
  //       setTimeout(() => {
  //         navigate("/client-dashboard");
  //       }, 100);
  //     } else {
  //       setIsLoaded(true);
  //     }
  //   }
  //   return () => {
  //     mounted = false;
  //   };
  // }, [refreshTokenFromRedux, navigate]);

  const onSubmit = (values) => {    
    const formData = {
      password: values.password,
      confirmPassword: values.confirmPassword,
      token: token
    };
    setIsSubmitting(true);
    AuthService.resetPassword_api(formData).then(
      (data) => {
        setIsSubmitting(false);
        // console.log('login', data?.results);
        if (data) {
            setResponseMessage({
              type: "success",
              message: data.message
                ? data.message 
                : "Requested Successfully."
            });
          
        } else {
          setResponseMessage({
            type: "error",
            message: errorMessage,
          });
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("Error Message", error.response.data.message);
        setResponseMessage({
          type: "error",
          message: error?.response?.data?.message
            ? error.response.data.message
            : errorMessage,
        });
        setIsSubmitting(false);
        return Promise.reject();
      }
    );
  };

  const password = watch("password", "");

  const validateConfirmPassword = (value) => {
    return value === password || "Passwords do not match";
    // console.log(value, password, x);
  }

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] === "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const handleConfirmPasswordMode = (state) => {
    let confirmPasswordModeDummy = { ...confirmPasswordMode };
    if (confirmPasswordModeDummy[state] === "password") {
      confirmPasswordModeDummy[state] = "text";
    } else {
      confirmPasswordModeDummy[state] = "password";
    }
    setConfirmPasswordMode(confirmPasswordModeDummy);
  };

  return (
    <>
        <Box sx={{display: 'flex'}} className="">
          <Box className="flex-container" sx={{
            display: "flex",
            flexDirection: "column"
          }}>
            {(
              <Box sx={{
                maxWidth: "360px"
              }} className="form-container">
                <Box sx={{ mb: [0, 5] }}>
                  <Box className="login-logo">
                    <img
                     src="/images/stretchNew.png"
                      alt="logo"
                      style={{ height: 82 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "44px",
                      mt: 3,
                    }}
                    className="logo-title"
                  >
                   Set New Password
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "px",
                      mt: 1,
                      color: '#98A2B3'
                    }}
                   
                  >
                   Please enter your new password and confirm the password again.
                  </Box>
                </Box>
                {/* <Divider color={"grey"} /> */}
                {responseMessage && (
                  <Alert
                    severity={responseMessage.type}
                    variant="filled"
                    sx={{ mt: 1 }}
                  >
                    <strong>{responseMessage.message}</strong>
                  </Alert>
                )}
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                   <Box> 
                    <Box className="small-sub" >
                        Password
                        </Box>
                        <TextField
                            required
                            fullWidth
                            sx={{ mb: 2 }}
                            size="small"
                            type={passwordMode.password}
                            variant="outlined"
                            placeholder="Enter your password"
                            error={!!errors["password"]}
                            helperText={errors["password"] ? errors["password"].message : ""}
                            {...register("password", {
                            required: "Password required",
                            minLength: {
                              value: 8,
                              message: "Minimum length is 8"
                            }
                            })}
                            InputProps={{
                            style: { fontSize: "18px" },
                            className: "form-control",
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handlePasswordMode("password")}
                                    aria-label="toggle password"
                                    edge="end"
                                    sx={{ ml: [0, 2], mr: [0, 0.5], color: "grey" }}
                                    className="icon"
                                >
                                    {passwordMode.password === "password" ? (
                                    <VisibilityOffIcon />
                                    ) : (
                                    <VisibilityIcon />
                                    )}
                                </IconButton>
                                </InputAdornment>
                            ),
                            onKeyDown: (event) => {
                                if (event.key === "Enter") {
                                event.preventDefault();
                                handleSubmit(onSubmit)();
                                }
                            },
                            }}
                        />
                   </Box>
                   <Box> 
                    <Box className="small-sub" >
                        Confirm Password
                        </Box>
                        <TextField
                            required
                            fullWidth
                            sx={{ mb: 2 }}
                            size="small"
                            type={confirmPasswordMode.confirmPassword}
                            variant="outlined"
                            placeholder="Enter your password"
                            error={!!errors["confirmPassword"]}
                            helperText={errors["confirmPassword"] ? errors["confirmPassword"].message : ""}
                            {...register("confirmPassword", {
                            required: "Password required",
                            validate: validateConfirmPassword,
                            minLength: {
                              value: 8,
                              message: "Minimum length is 8"
                            }
                            })}
                            InputProps={{
                            style: { fontSize: "18px" },
                            className: "form-control",
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handleConfirmPasswordMode("confirmPassword")}
                                    aria-label="toggle password"
                                    edge="end"
                                    sx={{ ml: [0, 2], mr: [0, 0.5], color: "grey" }}
                                    className="icon"
                                >
                                    {confirmPasswordMode.confirmPassword === "password" ? (
                                    <VisibilityOffIcon />
                                    ) : (
                                    <VisibilityIcon />
                                    )}
                                </IconButton>
                                </InputAdornment>
                            ),
                            onKeyDown: (event) => {
                                if (event.key === "Enter") {
                                event.preventDefault();
                                handleSubmit(onSubmit)();
                                }
                            },
                            }}
                        />
                   </Box>
                  <LoadingButton
                    disabled={!isValid}
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isSubmitting}
                    className="form-button"
                    sx={{
                      mt: 3.5,
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontWeight: 600,
                      borderRadius: 1.5
                    }}
                  >
                   Submit
                  </LoadingButton>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3
                    }}> 
                    <Box sx ={{
                      color:  '#344054',
                      fontWeight: 400,
                      fontSize: [14, 14],
                      lineHeight: "20px",
                      mr: 1
                    }}>
                     Already have an account?
                    </Box>
                    <Link to="/">
                      <Box sx ={{
                        color: 'primary.main',
                        fontWeight: 600,
                        fontSize: [14, 14],
                        lineHeight: "20px",
                        cursor: "pointer"
                      }}>
                        Log in
                      </Box>
                    </Link>
                    
                  </Box>
                </Box>
              </Box>
            )}
           
          </Box>
          {/* <AuthCommonPart /> */}
        </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  refreshTokenFromRedux: state.auth.auth.refreshToken
});

export default connect(mapStateToProps, { login })(
  ResetPassword
);
