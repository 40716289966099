import React, { useState, useEffect } from "react";
import { Alert, Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, TextField } from "@mui/material";
import AuthService from "../../services/api/auth.service";
import { login, setUser } from "../../redux/slices/auth";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import {
  accessToken,
  accessTokenTimeout,
  errorMessage,
  refreshToken,
  refreshTokenTimeout,
  userDetails,
} from "../../config/config";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { encryptString } from "../../utils/cryptograpgy";
import { getExpiryTime } from "../../utils/date";
import { getPermission } from "../../utils/cryptograpgy";
import { decodeJwt } from '../../utils/decodeJwt';
import { getCookie } from "../../utils/cookie";

const Login = ({
  login,
  setUser,
  refreshTokenFromRedux
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();

  const [passwordMode, setPasswordMode] = useState({
    password: "password",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [decodedToken, setDecodedToken] = useState(null);


  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (refreshTokenFromRedux) {
        // console.log("refreshTokenFromRedux", refreshTokenFromRedux);
        setTimeout(() => {
          if(getPermission("STRETCH_CLIENT")) {
            navigate("/client-dashboard-projects");
          }
          else {
            navigate("/admin-dashboard");
          }
        }, 100);
      } else {
        navigate("/");
        setIsLoaded(true);
      }
    }
    return () => {
      mounted = false;
    };
  }, [refreshTokenFromRedux, navigate]);

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] === "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const gotoResetPassword = () => {
    navigate("/forget-password");
  }

  const onSubmit = (values) => {
    const formData = {
      email: values.email,
      password: values.pass,
    };

    setIsSubmitting(true);
    AuthService.login(formData).then(
      (data) => {
        setIsSubmitting(false);
        if (data) {
          const decoded = decodeJwt(data.data.accessToken);

          const updatedDecodedToken = {
            ...decoded, // Spread all existing properties of decoded token
            UserInfo: {
              ...decoded.UserInfo,
              id: 1, // Add or override any properties within UserInfo
              accessToken: data.data.accessToken,
              refreshToken: data.data.refreshToken,
            }
          };

          // Update state with the new decoded token
          setDecodedToken(updatedDecodedToken);

          // Use the updated token directly instead of relying on state
          const userDataResponse = updatedDecodedToken;

          // Handle success response
          if (userDataResponse) {
            setResponseMessage({
              type: "success",
              message: data.message ? data.message : "Login Successfully.",
            });
            setUserData(data?.results);

            setTimeout(() => {
              setLoginData(userDataResponse.UserInfo);
            }, 1000);
          } else {
            setResponseMessage({
              type: "error",
              message: "Login failed.",
            });
            setUserData(null);
            setIsSubmitting(false);
          }
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("Error Message", error.response.data.message);
        setResponseMessage({
          type: "error",
          message: error?.response?.data?.message
            ? error.response.data.message
            : "An error occurred.",
        });
        setIsSubmitting(false);
        setUserData(null);
        return Promise.reject();
      }
    );
  };


  const setLoginData = (userData) => {
    if (userData) {
      const accessTokenExpiryTime = getExpiryTime(accessTokenTimeout);
      const refreshTokenExpiryTime = getExpiryTime(refreshTokenTimeout);
      setCookie(accessToken, userData.accessToken, {
        path: "/",
        expires: accessTokenExpiryTime,
      });
      setCookie(refreshToken, userData.refreshToken, {
        path: "/",
        expires: refreshTokenExpiryTime,
      });
      login({
        loaded: true,
        loggedIn: true,
        accessToken: userData.accessToken,
        refreshToken: userData.refreshToken,
      });
      setUser(userData);
      // dispatch(setUser(userData));
      console.log('userData', userData);
      // console.log('permissionResource', userData.permissionResource);
      const encryptedPermissionResource = encryptString(
        userData.userRole
      );
      console.log('encryptedPermissionResource', encryptedPermissionResource);
      localStorage.setItem(
        "p2e3r21m2i31s166s223i345o226112n",
        encryptedPermissionResource
      );

      const redirectUrl = localStorage.getItem("redirectUrl");
      if (redirectUrl) {
        window.location = redirectUrl;
      } else {
        if(userData.userRole === "STRETCH_CLIENT"){
          navigate("/client-dashboard-projects");
        }
        else {
          navigate("/admin-dashboard");
        }
      }
    } else {
      logout();
    }
  };
  const logout = () => {
    removeCookie(accessToken, { path: "/" });
    removeCookie(refreshToken, { path: "/" });
    setUser(null);
    setUserData(null);
  };

  return (
    <>
      {isLoaded && (
        <Box sx={{ display: 'flex' }} className="container">
          <Box className="flex-container" sx={{
            display: "flex",
            flexDirection: "column"
          }}>
            {(
              <Box className="form-container">
                <Box sx={{ mb: [0, 5] }}>
                  <Box className="login-logo">
                    <img
                      src="/images/stretchNew.png"
                      alt="logo"
                      style={{ height: 80 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "30px",
                      mt: 1,
                    }}
                    className="logo-title"
                  >
                    Welcome back
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "px",
                      mt: 1,
                      color: '#98A2B3'
                    }}

                  >
                    Welcome back! Please enter your details.
                  </Box>
                </Box>
                {/* <Divider color={"grey"} /> */}

                {responseMessage && (
                  <Alert
                    severity={responseMessage.type}
                    variant="filled"
                    sx={{ mt: 1 }}
                  >
                    <strong>{responseMessage.message}</strong>
                  </Alert>
                )}
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Box className="small-sub">
                    Email
                  </Box>
                  <TextField
                    required
                    fullWidth
                    error={!!errors["email"]}
                    helperText={errors["email"] ? errors["email"].message : ""}
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Invalid email"
                      }
                    })}
                    size="small"
                    sx={{ mb: 4 }}
                    type="email"
                    placeholder="Enter your email"
                    variant="outlined"
                    InputProps={{
                      style: { fontSize: "18px" },
                      className: "form-control",
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleSubmit(onSubmit)();
                        }
                      },
                    }}
                  />
                  <Box className="small-sub" >
                    Password
                  </Box>
                  <TextField
                    required
                    fullWidth
                    sx={{ mb: 2 }}
                    size="small"
                    type={passwordMode.password}
                    variant="outlined"
                    placeholder="Enter your password"
                    error={!!errors["pass"]}
                    helperText={errors["pass"] ? errors["pass"].message : ""}
                    {...register("pass", {
                      required: "Password required",
                      minLength: {
                        value: 8,
                        message: "Minimum length is 8"
                      }
                    })}
                    InputProps={{
                      style: { fontSize: "18px" },
                      className: "form-control",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handlePasswordMode("password")}
                            aria-label="toggle password"
                            edge="end"
                            sx={{ ml: [0, 2], mr: [0, 0.5], color: "grey" }}
                            className="icon"
                          >
                            {passwordMode.password === "password" ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleSubmit(onSubmit)();
                        }
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        color: "#344054",
                        fontSize: "14px",
                        fontWeight: "500px"

                      }}
                      className="form-control"
                      control={<Checkbox defaultChecked />}
                      label="Remember me for 30 days"
                    />
                    <Box sx={{
                      color: '#6927DA',
                      fontWeight: 600,
                      fontSize: [14, 14],
                      lineHeight: "20px",
                      cursor: "pointer"
                    }} onClick={gotoResetPassword}>Forgot password</Box>
                  </Box>
                  <LoadingButton
                    disabled={!isValid}
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isSubmitting}
                    className="form-button"
                    size="small"
                    sx={{
                      mt: 3.5,
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontWeight: 600,
                      borderRadius: 1.5
                    }}
                  >
                    Sign in
                  </LoadingButton>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3
                  }}>
                    <Box sx={{
                      color: '#344054',
                      fontWeight: 400,
                      fontSize: [14, 14],
                      lineHeight: "20px",
                      mr: 1
                    }}>
                      Don't have an account?
                    </Box>
                    <Link to="/signup">
                      <Box sx={{
                        fontWeight: 600,
                        fontSize: [14, 14],
                        lineHeight: "20px",
                        cursor: "pointer"
                      }}>
                        Sign up
                      </Box>
                    </Link>

                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  refreshTokenFromRedux: state.auth.auth.refreshToken
});

export default connect(mapStateToProps, { login, setUser })(
  Login
);
