import { api, authApi } from "../interceptor/auth.interceptor";
import { apiUrl } from "../../config/config";

class AuthService {

  static async login(formData) {
    return await api
      .post("/auth/login", formData)
      .then(response => {
        return response?.data;
      })
  }

  static async register(formData) {
    return await api
      .post("all/auth/signup", formData)
      .then(response => {
        return response?.data;
      })
  }

  static async logout() {
    return await authApi
      .post("all/auth/signout")
      .then((response) => {
        return response?.data;
      })
  }

  static async resetPassword_api(formData) {
    await fetch(apiUrl + "all/auth/reset-password", {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData),
    }).then((response) => {
      response.json()
      // window.location.href = '/';
    });
  }

  static async forgetPassword(formData) {
    return await api
      .get(apiUrl + "auth/forgotPassword/" + formData)
      .then((response) => {
        return response?.data;
      });
  }

  static async getRefreshToken(formData) {
    try {
  
      // Define the API URL with the refreshToken as a query parameter
      const url = `/refreshToken?refreshToken=${formData.refreshToken}`;
  
      // Send the POST request with refreshToken in query and accessToken in headers
      const response = await api.post(
        url,
        null, // No body is required for this request, so passing null
        {
          headers: {
            'Authorization': `Bearer ${formData.accessToken}`
          },
        }
      );
  
      return response;
    } catch (error) {
      // Log the error message and optionally handle it as needed
      console.error("Error in getRefreshToken:", error?.response?.data || error.message);
      throw error; // Rethrow the error so that the caller can handle it if needed
    }
  }  

  static async demoUserCall(queryParams) {
    return await authApi
      .get(`user/demo-users?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async approveUser(queryParams, formData) {
    return await authApi
      .put(`user/approve?${queryParams}`,formData)
      .then((response) => {
        return response?.data;
      });
  }

  static async updateDemoUser(queryParams){
    return await authApi
    .put(`user/update?${queryParams}`)
    .then((response) => {
      return response?.data;
    })
  }

}

export default AuthService;