import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import routes from '../../../route/routes';

const MainLayout = ({ currentTheme }) => {

  const getPath = (route) => {
    return route.path ? route.path : ""
  }

  return (<>
    <div className={`theme-${currentTheme} main-layout`}>
      {routes && routes.length > 0 &&
        <Routes>
          {routes.map((route1, index1) => {
            let path = "";
            if (route1.layout) {
              let Layout1 = route1.layout;
              path = getPath(route1);
              return (
                <Route element={<Layout1 />} key={index1}>
                  {route1.children && route1.children.length > 0 && route1.children.map((route2, index2) => {
                    if (route2.layout) {
                      let Layout2 = route2.layout;
                      path = getPath(route1) + getPath(route2);
                      return (
                        <Route element={<Layout2 />} key={index2}>
                          {route2.children && route2.children.length > 0 && route2.children.map((route3, index3) => {
                            if (route3.layout) {
                              let Layout3 = route3.layout;
                              path = getPath(route1) + getPath(route2) + getPath(route3);
                              <Route element={<Layout3 />} key={index3}>
                                {route3.children && route3.children.length > 0 && route3.children.map((route4, index4) => {
                                  let Component4 = route4.element;
                                  path = getPath(route1) + getPath(route2) + getPath(route3) + getPath(route4);
                                  return (
                                    <Route path={path} exact={route4.exact ? route4.exact : false} element={<Component4 />} key={index4} />
                                  )
                                })}
                              </Route>
                            } else {
                              let Component3 = route3.element;
                              path = getPath(route1) + getPath(route2) + getPath(route3);
                              return (
                                <Route path={path} exact={route3.exact ? route3.exact : false} element={<Component3 />} key={index3} />
                              )
                            }
                          })}
                        </Route>
                      )
                    } else {
                      let Component2 = route2.element;
                      path = getPath(route1) + getPath(route2);
                      return (
                        <Route path={path} exact={route2.exact ? route2.exact : false} element={<Component2 />} key={index2} />
                      )
                    }
                  })}
                </Route>
              )
            } else {
              let Component1 = route1.element;
              path = getPath(route1);
              return (
                <Route path={path} exact={route1.exact ? route1.exact : false} element={<Component1 />} key={index1} />
              )
            }
          })}
        </Routes>
      }
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  currentTheme: state.theme.currentTheme,
  mobileMenuBarState: state.common.mobileMenuBarState,
  mobileGeneralStatState: state.common.mobileGeneralStatState,
  mobileAudienceDetailsState: state.common.mobileAudienceDetailsState
});

export default connect(mapStateToProps, null)(MainLayout);