import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { baseUrl, mapboxApiToken } from "../../../config/config";
import { Box } from "@mui/material";

mapboxgl.accessToken = `pk.eyJ1IjoicmFmaXVsLW1lbW9iIiwiYSI6ImNsY3NoeGgwZDBmaTQzeHAxZ2JmcnlibTAifQ.l8EHVjWKI-Dur3R_tMBIPQ`;

const LocationMap = (params) => {
    const {locations} = params;
    const mapContainerRef = useRef(null);

  useEffect(() => {
    if(locations && locations.length > 0) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v10',
        center: [locations[0].longitude, locations[0].latitude], // default center
        zoom: 4 // default zoom
      });
  
      // Add markers for each location
      locations.forEach(location => {
        new mapboxgl.Marker()
          .setLngLat([location.longitude, location.latitude])
          .addTo(map);
      });
  
      // Fit map to the bounds of all markers
      // const bounds = new mapboxgl.LngLatBounds();
      // locations.forEach(location => {
      //   bounds.extend([location.longitude, location.latitude]);
      // });
      // map.fitBounds(bounds, { padding: 50 });
  
      // Clean up on unmount
      return () => map.remove();
    }
    
  }, [locations, mapboxgl]);
    return (
        <div style={{height:'300px'}} ref={mapContainerRef} className="map-container" />
    );
};

export default LocationMap;
