import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthCommonPart from '../../common/AuthCommonPart';
import "./AuthLayout.scss";
import { Box } from '@mui/material';
import Footer from '../../../pages/auth-footer/footer';
import { useLocation } from 'react-router-dom';

const AuthLayout = ({ refreshToken }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (refreshToken) {
        navigate('/');
      }
    }
    return () => {

    }
  }, [refreshToken]);

  return (<>
    <Box sx={{
      display: "flex",
      width: "100%"
    }} className={`auth-layout`}>
      <Box className="half-width">
        <Outlet />
        <Box className="half-width" sx={{
          position: "absolute",
          bottom: "1%",
          padding: 3,
          zIndex: 2
        }}>
          <Footer />
        </Box>

      </Box>
      <Box className="auth-common-part">
        {/* <AuthCommonPart /> */}
        <img
          src={
            currentPath == "/signup" ? "/images/signup.png" :
              currentPath == "/forget-password" ? "/images/reset_password.png" :
                currentPath == "/reset-password" ? "/images/set_password.png" : "/images/signin.png"
          }
          alt="logo"
          style={{ height: "100vh", width: "100vw" }}
        />
      </Box>
    </Box>
  </>)
}

const mapStateToProps = (state) => ({
  refreshToken: state.auth.auth.refreshToken
});

export default connect(mapStateToProps, null)(AuthLayout);