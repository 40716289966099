import React, {useState, useMemo, useEffect} from 'react';
import {LinearProgress, styled} from '@mui/material'
import {Box, Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import MuiToggleButton from "@mui/material/ToggleButton";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {useNavigate, useParams} from "react-router-dom";
import UserService from "../../services/api/user.service";
import GenericTable from "../../components/common/GenericTable/genericTable";
import SearchComponent from "../../components/common/SearchComponent/searchComponent";
import AdminViewDetailsModal from "../../components/modals/adminViewDetails/adminViewDetails";
import ConfirmationModal from "../../components/modals/confirmationModal/confirmationModal";
import {useDispatch, useSelector} from "react-redux";
import {NumericFormat} from "react-number-format";
import moment from "moment";
import {setIsLoadingState} from "../../redux/slices/common";
import ProjectService from "../../services/api/project.service";
import ClientService from "../../services/api/client.service";


const ToggleButton = styled(MuiToggleButton)(({selectedcolor}) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: selectedcolor,
    },
}));
;
const CustomToggle = styled(ToggleButton)({
    color: '#6941C6',
})
const ProjectListView = () => {
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.auth.user);
    const CompanyTemplate = ({row}) => {
        return (
            <>
                <Stack spacing={1}>
                    <Box style={{
                        fontWeight: '700',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        lineHeight: '30px',
                        color: '#101828'
                    }}>
                        {row.projectAndCampaigns.projectName}
                    </Box>
                </Stack>
            </>
        );
    }
    const StartDateTemplate = ({row}) => {
        return (
            <>
                <Box sx={{marginTop: '3px'}}>
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#475467',
                        }}>Starting Date</Box>
                    <Stack direction="row" spacing={1} alignItems={'center'}
                           sx={{marginTop: '3px'}}>
                        <CalendarTodayIcon sx={{fontSize: 20}}/>
                        <Box sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#101828',
                        }}>{row.projectAndCampaigns.projectStartDate ? (moment(row.projectAndCampaigns.projectStartDate).format('DD/MM/YYYY')) : '-'}</Box>
                    </Stack>
                </Box>
            </>
        );
    }
    const EndDateTemplate = ({row}) => {
        return (
            <>
                <Box sx={{marginTop: '3px'}}>
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#475467',
                        }}>End Date</Box>
                    <Stack direction="row" spacing={1} alignItems={'center'}
                           sx={{marginTop: '3px'}}>
                        <CalendarTodayIcon sx={{fontSize: 20}}/>
                        <Box sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#101828',
                        }}>{row.projectAndCampaigns.projectEndDate ? moment(row.projectAndCampaigns.projectEndDate).format('DD/MM/YYYY') : '-'}</Box>
                    </Stack>
                </Box>
            </>
        );
    }
    const ActionTemplate = ({row}) => {
        return (
            <>
                <Box sx={{borderLeft: '2px solid #D0D5DD', paddingLeft: '20px'}}>
                    <Stack spacing={2} direction="row">
                        <Button variant="text"
                                sx={{
                                    opacity: 1,
                                    fontWeight: '600!important',
                                    fontSize: '14px!important',
                                    lineHeight: '20px!important',
                                    color: '#101828!important',
                                    textTransform: 'none!important',
                                }} disableRipple>
                            {row.projectAndCampaigns.totalCampaigns} Campaign Details
                        </Button>
                    </Stack>
                </Box>
            </>
        );
    }

    const tableConfig = {
        ShowHeaderTemplate: false,
        Columns: [
            {
                Key: 'name',
                Title: 'Name',
                CellTemplate: CompanyTemplate,
            },
            {
                Key: 'startDate',
                Title: 'Start Date',
                CellTemplate: StartDateTemplate
            },
            {
                Key: 'endDate',
                Title: 'endDate',
                CellTemplate: EndDateTemplate
            },
            {
                Key: 'view',
                Title: 'View',
                CellTemplate: ActionTemplate
            },
        ],
        PageSize: 5,
    }
    const navigate = useNavigate();
    const [alignment, setAlignment] = React.useState("All");
    const [value, setFilter] = React.useState("list");
    const [showListView, setShowViewOption] = React.useState(true);
    const [adminProjectDetails, setAdminProjectDetails] = useState([]);
    const [adminViewModalOpen, setAdminViewModalOpen] = useState(false);
    const [viewDetailsInfo, setViewDetailsInfo] = useState();
    const [isApprovedModal, setIsApprovedModal] = useState(true);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [clientInfo, setclientInfo] = useState();
    const [ListTableData, setProjectListTableData] = useState([]);
    const [projectListData, setProjectListData] = useState([]);
    const [totalTableRecords, setTotalTableRecords] = useState(0);
    const [searchValue, setSearchValue] = useState(null);
    const [pageQueryParams, setPageQueryParams] = useState({page:0, size: tableConfig?.PageSize});


    const {clientId} = useParams();
    const {clientName} = useParams();


    useEffect(() => {
        if (clientId) {
            let queryParams;
            if(searchValue){
                queryParams = `clientId=${clientId}&page=${pageQueryParams.page}&size=${pageQueryParams.size}&searchText=${searchValue}`;
            }else {
                queryParams = `clientId=${clientId}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`;
            }

            dispatch(setIsLoadingState(true));
            ProjectService.getAllProjectList(queryParams).then(
                (data) => {
                    setTotalTableRecords(data.data.totalElements);
                    dispatch(setIsLoadingState(false));
                    setProjectListData(data.data.content);
                    return Promise.resolve();
                },
                (error) => {
                    console.log("Error Message", error.response.data.message);
                    return Promise.reject();
                }
            ).finally(() => {
                dispatch(setIsLoadingState(false));
            });
        }

    }, [clientId, pageQueryParams, searchValue]);

    useEffect(() => {
        dispatch(setIsLoadingState(true));
        ClientService.getClientDashBoardData(clientId).then(
            (data) => {
                
                let campaignAnalytics = [];
                
                // Adjusting the structure to map the new response keys and values
                Object.entries(data.data).forEach(([key, val]) => {
        
                    switch (key) {
                        case 'totalCampaignCount':
                            campaignAnalytics.push({ title: "Total Campaigns", noOfCampaigns: val });
                            break;
                        case 'totalPendingCount':
                            campaignAnalytics.push({ title: "Pending for Approval Campaigns", noOfCampaigns: val });
                            break;
                        case 'totalActiveCount':
                            campaignAnalytics.push({ title: "Active Campaigns", noOfCampaigns: val });
                            break;
                        // Add new cases here for other keys in the response
                        default:
                            // Handle any other keys if necessary
                            break;
                    }
                });
        
                setAdminProjectDetails(campaignAnalytics);
                return Promise.resolve();
            },
            (error) => {
                console.log("Error Message", error.response.data.message);
                return Promise.reject();
            }
        ).finally(() => {
            dispatch(setIsLoadingState(false));
        });
    }, []);

    // useEffect(() => {
    //     if (user?.id) {
    //         dispatch(setIsLoadingState(true));
    //         TeamMemberService.getMembersDashboardData(user?.id).then(
    //             (data) => {
    //                 let campaignAnalytics = [];
    //                 // eslint-disable-next-line array-callback-return
    //                 Object.entries(data.results).map(([key, val]) => {
    //                     if (key === 'totalCampaigns') {
    //                         campaignAnalytics.push({title: "Total Campaigns", noOfCampaigns: val})
    //                     }
    //                     if (key === 'pendingCampaigns') {
    //                         campaignAnalytics.push({title: "Pending for Approval Campaigns", noOfCampaigns: val})
    //                     }
    //                     if (key === 'activeCampaigns') {
    //                         campaignAnalytics.push({title: "Active Campaigns", noOfCampaigns: val})
    //                     }
    //                 });
    //                 clientCampaignAnalytics(campaignAnalytics);
    //                 return Promise.resolve();
    //             },
    //             (error) => {
    //                 console.log("Error Message", error.response.data.message);
    //                 return Promise.reject();
    //             }
    //         ).finally(() => {
    //             dispatch(setIsLoadingState(false));
    //         });
    //     }
    
    // }, [user?.id]);

    // useEffect(() => {
    //     UserService.getFakeData().then(
    //         (data) => {
    //             setAdminProjectDetails(data.adminAnalytics);
    //             return Promise.resolve();
    //         },
    //         (error) => {
    //             console.log("Error Message", error.response.data.message);
    //             return Promise.reject();
    //         }
    //     );
    // }, []);

    const onChangePage = (e) => {
        if (e) {
            setPageQueryParams({
                ...pageQueryParams,
                page: ( e - 1),
            });
        }
    }

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const handleFilterAlignment = (event, newAlignment) => {
        setFilter(newAlignment);
        if (newAlignment === 'list') {
            setShowViewOption(true);
        } else {
            setShowViewOption(false);
        }
    };


    const handleChange = (value) => {
        if (value) {
            setSearchValue(value);
        } else {
            setSearchValue(null);
        }
    };

    const OnViewHandelClick = () => {
        setAdminViewModalOpen(true);
        setIsApprovedModal(false);

    }
    const onClickHandelApproveEvent = () => {
        setAdminViewModalOpen(false);
        setIsApprovedModal(true);
        setConfirmModalOpen(true);
    }
    const onClickHandelRejectEvent = (e) => {
        setAdminViewModalOpen(false);
        setIsApprovedModal(false);
        setConfirmModalOpen(true);
    }
    const handleAdminDetailsModalClose = () => {
        setAdminViewModalOpen(false);
    };
    const handleOnApprovedModalClose = () => {
        setConfirmModalOpen(false);
    };
    const onSelectRow = (e) => {
        if (e) {
            if(clientId){
                navigate(`/campaign-list-view/${clientId}/${e.projectAndCampaigns.projectId}/${e.projectAndCampaigns.projectName}`);

            }
            console.log(e);
        }
    }

    return (<>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            p: "2% 4% 2% 4%"
        }}>

            {/* <Box>
                <Box sx={{
                    fontWeight: "600",
                    fontSize: "30px",
                    color: "black",
                    lineHeight: "38px"
                }}>
                    Welcome back, {user?.firstName}
                </Box>
                <Box sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#1D2939",
                    lineHeight: "24px"
                }}>
                    Track, manage and forecast your campaign details.
                </Box>
            </Box> */}
            <Box sx={{mt: 5}}>
                <Box sx={{flexGrow: 1}}>
                    
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 1, sm: 8, md: 12}}>
                        {adminProjectDetails.map((item, index) => (
                            <Grid item xs={2} sm={4} md={4} key={index}>
                                <Box sx={{
                                    p: 3,
                                    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                                    border: "1px solid #F2F4F7",
                                    borderRadius: "12px"
                                }}>
                                    <Box sx={{
                                        color: "#101828",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        lineHeight: "24px"
                                    }}>
                                        {item.title}
                                    </Box>
                                    <Box sx={{
                                    mb: 1.5,
                                    color: "#000000",
                                    fontSize: "36px",
                                    fontWeight: "600",
                                    lineHeight: "44px"
                                }}>
                                    {item.noOfCampaigns ? item.noOfCampaigns : 0}
                                </Box>
                                    {/* <Box sx={{
                                        mb: 1.5,
                                        color: "#000000",
                                        fontSize: "36px",
                                        fontWeight: "600",
                                        lineHeight: "44px"
                                    }}>
                                        <NumericFormat displayType="text" value={item.listOfNumber} allowLeadingZeros
                                                       thousandSeparator=","/>
                                    </Box> */}
                                    {/* <Box style={{
                                        fontWeight: "500",
                                        fontsize: "14px",
                                        lineHeight: "20px",
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            height: "20px"
                                        }}>
                                            {item.status === true ?
                                                <Box sx={{display: 'flex'}}>
                                                    <ArrowUpwardIcon sx={{fontSize: "20px", color: "#12B76A",}}/>
                                                    <Box sx={{
                                                        color: "#12B76A",
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                        lineHeight: "20px"
                                                    }}>
                                                        <NumericFormat displayType="text" value={item.percentageAmount}
                                                                       allowLeadingZeros thousandSeparator=","/>% </Box>
                                                </Box>
                                                :
                                                <Box sx={{display: 'flex'}}>
                                                    <ArrowDownwardIcon sx={{fontSize: "20px", color: "#F04438",}}/>
                                                    <Box
                                                        sx={{
                                                            color: "#B42318",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            lineHeight: "20px",

                                                        }}>
                                                        <NumericFormat displayType="text" value={item.percentageAmount}
                                                                       allowLeadingZeros thousandSeparator=","/>%</Box>
                                                </Box>
                                            }

                                            <Box sx={{
                                                paddingLeft: "5px",
                                                color: "#1D2939",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "20px"
                                            }}
                                            >vs last month</Box>
                                        </Box>

                                    </Box> */}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
          

                <Grid sx={{mt: 4}}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                >
                    <Box direction="row" justifyContent="start" style={{
                        flexDirection: "column",
                        textAlign: 'right'
                    }}>
                        <Stack direction="row" spacing={2}>
                            <Avatar sx={{width: 56, height: 56}} alt='profile' src={clientInfo?.profileImageURL}/>
                            <Box style={{
                                marginTop: '20px',
                                fontWeight: 600,
                                fontSize: '18px',
                                lineHeight: '28px',
                                color: '#475467'
                            }}>{clientName}</Box>
                        </Stack>
                    </Box>

                    <Box direction="row" justifyContent="end">
                        <SearchComponent searchData={handleChange} />
                    </Box>
                </Grid>

            {/*table start*/}
            {projectListData.length > 0 ?
            
                <Box style={{width: "100%", marginTop: '30px'}}>
                    <GenericTable tableConfig={tableConfig} totalRecords={totalTableRecords} tableData={projectListData}
                                  onRowClick={(e) => {onSelectRow(e)}}  getCurrentPageNumber={(e) => {onChangePage(e)}}/>
                </Box> :
                <Box sx={{
                    textAlign: "center",
                    marginTop: "30px",
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '28px',
                    color: '#000000',
                }}> No Data Found
                </Box>
            }
        </Box>
        {/*</Box>*/}

        {/*details modal*/}
        <Box className="modal">
            <AdminViewDetailsModal
                adminViewModalOpen={adminViewModalOpen}
                detailsData={viewDetailsInfo}
                handleAdminViewModalClose={handleAdminDetailsModalClose}/>
        </Box>
        {/*ConfirmationModal modal*/}
        <Box className="modal">
            <ConfirmationModal
                isApprovedMode={isApprovedModal}
                confirmationModalOpen={confirmModalOpen}
                handleConfirmationModalClose={handleOnApprovedModalClose}>
            </ConfirmationModal>
        </Box>

    </>);
}
export default ProjectListView;
