import React, {useState, useMemo, useEffect} from 'react';
import {ToggleButtonGroup, styled, IconButton, LinearProgress} from '@mui/material'
import {Box, Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import styles from './AdminDashBoard.scss';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {useNavigate, useParams} from "react-router-dom";
import Pagination from "../../components/common/GenericTable/Pagination/Pagination";
import GenericTable from "../../components/common/GenericTable/genericTable";
import SearchComponent from "../../components/common/SearchComponent/searchComponent";
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import AdminViewDetailsModal from "../../components/modals/adminViewDetails/adminViewDetails";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ConfirmationModal from "../../components/modals/confirmationModal/confirmationModal";
import CustomToggleButton from "../../components/common/ToggleButton/toggleButton";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {setIsLoadingState} from "../../redux/slices/common";
import CampaignService from "../../services/api/campaign.service";
import { setMessage } from '../../redux/slices/message';


const ToggleButton = styled(MuiToggleButton)(({selectedcolor}) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: selectedcolor,
    },
}));

const theme = createTheme({
    palette: {
        text: {
            primary: "#00ff00",
        },
    },
});
const CustomToggle = styled(ToggleButton)({
    color: '#6941C6',
})
const CampaignListView = () => {
    const navigate = useNavigate();
    const {clientId} = useParams();
    const {projectId} = useParams();
    const {projectName} = useParams()
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.auth.user);
    // table config

    const CompanyTemplate = ({row}) => {
        return (
            <>
                <Stack spacing={1}>
                    <Box style={{
                        fontWeight: '700',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        lineHeight: '30px',
                        color: '#101828'
                    }}>
                        {row.campaignTitle}
                    </Box>
                    <Chip label={(row.campaignRemark === 'ENDED') ? 'Ended' : (row.campaignRemark === 'RUNNING' ? 'Running' :
                        (row.campaignRemark === 'UNDER_REVIEW' ? 'Under Review' : (row.campaignRemark === 'DRAFT' ? 'Draft' :
                            (row.campaignRemark === 'PENDING' ? 'Pending' : (row.campaignRemark === 'REJECTED' ? 'REJECTED' : (row.campaignRemark === 'APPROVED' ? 'APPROVED' : 'Paused'))))))}

                          style={{
                              background: (row.campaignRemark === 'ENDED') ? '#344054' : (row.campaignRemark === 'RUNNING' ? '#039855' :
                                  (row.campaignRemark === 'UNDER_REVIEW' ? '#E04F16' : (row.campaignRemark === 'DRAFT' ? '#444CE7' :
                                      (row.campaignRemark === 'PENDING' ? '#344054' : (row.campaignRemark === 'REJECTED' ? '#B00020' : (row.campaignRemark === 'APPROVED' ? '#039855' : '#344054')))))),
                              color: '#ffffff',
                              width: 'fit-content'
                          }}/>
                </Stack>
            </>
        );
    }

    const StartDateTemplate = ({row}) => {
        return (
            <>
                <Box sx={{marginTop: '3px'}}>
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#475467',
                        }}>Starting Date</Box>
                    <Stack direction="row" spacing={1} alignItems={'center'}
                           sx={{marginTop: '3px'}}>
                        <CalendarTodayIcon sx={{fontSize: 20}}/>
                        <Box sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#101828',
                        }}>{row.campaignStartDate ? (moment(row.campaignStartDate).format('DD/MM/YYYY')) : '-'}</Box>
                    </Stack>
                </Box>
            </>
        );
    }

    const EndDateTemplate = ({row}) => {
        return (
            <>
                <Box sx={{marginTop: '3px'}}>
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#475467',
                        }}>Ended Date</Box>
                    <Stack direction="row" spacing={1} alignItems={'center'}
                           sx={{marginTop: '3px'}}>
                        <CalendarTodayIcon sx={{fontSize: 20}}/>
                        <Box sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#101828',
                        }}>{row.campaignEndDate ? (moment(row.campaignEndDate).format('DD/MM/YYYY')) : '-'}</Box>
                    </Stack>
                </Box>
            </>
        );
    }

    const ActionTemplate = ({row}) => {
        return (
            <>
                <Box sx={{borderLeft: '2px solid #D0D5DD', paddingLeft: '20px'}}>

                    {['DRAFT'].includes(row.campaignRemark) ?

                        <Button variant="text"
                                onClick={(e) => {
                                    gotoCampaignEdit(e, row);
                                }}
                                sx={{
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    color: '#6941C6',
                                    textTransform: 'none',
                                }} disableRipple> Continue Editing</Button>

                        :

                        <Stack spacing={2} direction="row">
                            <Button variant="text"
                                    onClick={(e) => {
                                        onViewDetailsClick(e, row);
                                    }}
                                    sx={{
                                        opacity: ["RUNNING", "Active","APPROVED"].includes(row.campaignRemark) ? 1 : 1,
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#101828',
                                        textTransform: 'none',
                                    }}> View Details</Button>

                            {['UNDER_REVIEW'].includes(row.campaignRemark) ?

                                <Stack direction="row" spacing={1}>
                                    <IconButton aria-label="DoneOutlinedIcon"
                                                onClick={(e) => {
                                                    onClickHandelApproveEvent(e);
                                                    setCamDetails(row);
                                                }}>
                                        <DoneOutlinedIcon color="success" style={{
                                            color: '#039855!important',
                                        }}/>
                                    </IconButton>
                                    <IconButton aria-label="ClearOutlinedIcon" color="primary"
                                                onClick={(e) => {
                                                    onClickHandelRejectEvent(e);
                                                    setCamDetails(row);
                                                }}>
                                        <ClearOutlinedIcon color="error" sx={{
                                            color: '#B42318!important',
                                        }}/>
                                    </IconButton>
                                </Stack> :
                                <Button
                                    // onClick={(e) => {
                                    //     onViewDetailsClick(e, row);
                                    // }}
                                    disabled={["RUNNING", "Active", "PENDING","APPROVED"].includes(row.campaignRemark)}
                                    sx={{
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        opacity: ["RUNNING", "Active", "PENDING","APPROVED"].includes(row.campaignRemark) ? 0.4 : 1,
                                        color: '#101828',
                                        textTransform: 'none',
                                    }} startIcon={<FileUploadOutlinedIcon color="success"/>}>
                                    Upload
                                </Button>
                            }
                        </Stack>
                    }
                </Box>
            </>
        );
    }

    const tableConfig = {
        ShowHeaderTemplate: false,
        Columns: [
            {
                Key: 'name',
                Title: 'Name',
                CellTemplate: CompanyTemplate,
            },
            {
                Key: 'startDate',
                Title: 'Start Date',
                CellTemplate: StartDateTemplate
            },
            {
                Key: 'endDate',
                Title: 'endDate',
                CellTemplate: EndDateTemplate
            },
            {
                Key: 'view',
                Title: 'View',
                CellTemplate: ActionTemplate
            },
        ],
        PageSize: 5,
    }

    const [adminViewModalOpen, setAdminViewModalOpen] = useState(false);
    const [isApprovedModal, setIsApprovedModal] = useState(true);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [camDetails, setCamDetails] = React.useState();

    const [value, setFilter] = React.useState("list");
    const [showListView, setShowViewOption] = React.useState(true);
    const [campaignLists, setCampList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterBy, setFilterBy] = React.useState('All');
    const [totalTableRecords, setTotalTableRecords] = useState(0);
    const [searchValue, setSearchValue] = useState(null);
    const [pageQueryParams, setPageQueryParams] = useState({page: 0, size: tableConfig?.PageSize});
    useEffect(() => {
        // if (user?.id) {
            let queryParams;
            if (searchValue) {
                queryParams = `projectCode=${projectId}&campaignName=${searchValue}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`;
            } else {
                queryParams = `projectCode=${projectId}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`;
            }
            if (filterBy !== 'All') {
                queryParams = `campaignRemark=${filterBy}&projectCode=${projectId}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`
            }
            dispatch(setIsLoadingState(true));
            CampaignService.getAllClientCampaignList(queryParams).then(
                (data) => {
                    setTotalTableRecords(data.data.totalElements);
                    dispatch(setIsLoadingState(false));
                    if (data.data.content.length > 0 && data.data.content[0].campaignDetails) {
                        // Filter campaignDetails by the "DRAFT" remark
                        const notDraftCampaigns = data.data.content[0].campaignDetails.filter(
                            (campaign) => campaign.campaignRemark !== "DRAFT"
                        );
                        setCampList(notDraftCampaigns);
                    } else {
                        // Handle the case where content is empty or doesn't have campaignDetails
                        setCampList([]); // Set an empty list or any fallback behavior as needed
                    }
                    

                    return Promise.resolve();
                },
                (error) => {
                    console.log("Error Message", error.response.data.message);
                    return Promise.REJECTED();
                }
            ).finally(() => {
                dispatch(setIsLoadingState(false));
            });
        // }

    }, [ pageQueryParams, searchValue, filterBy]);

    const handleFilterAlignment = (event, newAlignment) => {
        setFilter(newAlignment);
        if (newAlignment === 'list') {
            setShowViewOption(true);
        } else {
            setShowViewOption(false);
        }
    };

    const onViewDetailsClick = (e, row) => {
        e.preventDefault();
        setAdminViewModalOpen(true);
        setCamDetails(row);
    }
    const gotoCampaignEdit = (e, row) => {
        e.preventDefault();
        navigate("/edit-campaign")
    }
    const handleAdminDetailsModalClose = () => {
        setAdminViewModalOpen(false);
        setCamDetails(null);
    };

    const onClickHandelApproveEvent = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setAdminViewModalOpen(false);
        setIsApprovedModal(true);
        setConfirmModalOpen(true);
    }
    const onClickHandelRejectEvent = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setAdminViewModalOpen(false);
        setIsApprovedModal(false);
        setConfirmModalOpen(true);
    }
    const handleOnApprovedModalClose = () => {
        setConfirmModalOpen(false);
    };

    const onSelectRow = (e) => {
        if (e) {
            // console.log('row', e);
        }
    }
    const onChangePage = (e) => {
        if (e) {
            setPageQueryParams({
                ...pageQueryParams,
                page: (e - 1),
            });
        }
    }
    const handelOnChangePage = (page) => {
        if (page) {
            setCurrentPage(page)
            setPageQueryParams({
                ...pageQueryParams,
                page: (page - 1),
            });
        }
    }
    const onHandleFilterBy = (e) => {
        if (e && e !== 'draft') { // Add condition to filter out 'draft'
            setFilterBy(e);
        }
    }
    const handleSearchChange = (value) => {
        if (value) {
            setSearchValue(value);
        } else {
            setSearchValue(null);
        }

    };

    const handleApprovalDetails = (res) => {
        if(res.isApproved){
            requestForApproveCampaignStatus(res.campaignId);
        }
        if(res.isReject){
            requestForRejectCampaignStatus(res.campaignId, res.rejectReason);
        }
    }
    
    const onHandelRejectReason = (reason) => {
        if(reason){
            requestForRejectCampaignStatus(camDetails.campaignId, reason);
        }
    }
    const handleOnConfirmationApproved = (confirm) => {
        if (confirm) {
            requestForApproveCampaignStatus(camDetails.campaignId, true, false, '');
        }
    };

    const requestForApproveCampaignStatus = (campId) => {
  
        dispatch(setIsLoadingState(true));
        CampaignService.approveCampaignStatus(campId).then(
            (data) => {
                if(data){
                    dispatch(setMessage({
                        type: "success",
                        title: data.message
                    })); 
                    let queryParams;
                    if (searchValue) {
                        queryParams = `&page=${pageQueryParams.page}&size=${pageQueryParams.size}&campaignName=${searchValue}&projectId=${projectId}`;
                    } else {
                        queryParams = `projectCode=${projectId}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`;
                    }
                    if (filterBy !== 'All') {
                        queryParams = `campaignRemark=${filterBy}&projectCode=${projectId}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`
                    }
                    dispatch(setIsLoadingState(true));
                    CampaignService.getAllClientCampaignList(queryParams).then(
                        (data) => {
                            setTotalTableRecords(data.data.totalElements);
                            dispatch(setIsLoadingState(false));
                            if (data.data.content.length > 0 && data.data.content[0].campaignDetails) {
                                // Filter campaignDetails by the "DRAFT" remark
                                const notDraftCampaigns = data.data.content[0].campaignDetails.filter(
                                    (campaign) => campaign.campaignRemark !== "DRAFT"
                                );
                                setCampList(notDraftCampaigns);
                            } else {
                                // Handle the case where content is empty or doesn't have campaignDetails
                                setCampList([]); // Set an empty list or any fallback behavior as needed
                            }
                            
        
                            return Promise.resolve();
                        },
                        (error) => {
                            console.log("Error Message", error.response.data.message);
                            return Promise.REJECTED();
                        }
                    )                  }
                dispatch(setIsLoadingState(false));
                return Promise.resolve();
            },
            (error) => {
                console.log("Error Message", error.response.data.message);
                dispatch(setMessage({
                    type: "error",
                    title: error.message
                }));   
                return Promise.REJECTED();
            }

        ).finally(() => {
            dispatch(setIsLoadingState(false));
        });
    }

    const requestForRejectCampaignStatus = (campId, reason ) => {

        dispatch(setIsLoadingState(true));
        CampaignService.rejectCampaignStatus(campId, reason).then(
            (data) => {
                if(data){
                    dispatch(setMessage({
                        type: "success",
                        title: data.message
                    }));                }
                dispatch(setIsLoadingState(false));
                let queryParams;
                if (searchValue) {
                    queryParams = `&page=${pageQueryParams.page}&size=${pageQueryParams.size}&campaignName=${searchValue}&projectId=${projectId}`;
                } else {
                    queryParams = `projectCode=${projectId}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`;
                }
                if (filterBy !== 'All') {
                    queryParams = `campaignRemark=${filterBy}&projectCode=${projectId}&page=${pageQueryParams.page}&size=${pageQueryParams.size}`
                }
                dispatch(setIsLoadingState(true));
                CampaignService.getAllClientCampaignList(queryParams).then(
                    (data) => {
                        setTotalTableRecords(data.data.totalElements);
                        dispatch(setIsLoadingState(false));
                        if (data.data.content.length > 0 && data.data.content[0].campaignDetails) {
                            // Filter campaignDetails by the "DRAFT" remark
                            const notDraftCampaigns = data.data.content[0].campaignDetails.filter(
                                (campaign) => campaign.campaignRemark !== "DRAFT"
                            );
                            setCampList(notDraftCampaigns);
                        } else {
                            // Handle the case where content is empty or doesn't have campaignDetails
                            setCampList([]); // Set an empty list or any fallback behavior as needed
                        }
                        
    
                        return Promise.resolve();
                    },
                    (error) => {
                        console.log("Error Message", error.response.data.message);
                        return Promise.REJECTED();
                    }
                )
                return Promise.resolve();
            },
            (error) => {
                console.log("Error Message", error.response.data.message);
                dispatch(setMessage({
                    type: "error",
                    title: error.message
                }));    
                return Promise.REJECTED();
            }

        ).finally(() => {
            dispatch(setIsLoadingState(false));
        });
    }

    return (<>
        <Box sx={{display: 'flex'}}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                p: "2% 4% 2% 4%"
            }}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Box direction="row" justifyContent="start" style={{
                        flexDirection: "column",
                        fontWeight: '600',
                        fontSize: '28px',
                        lineHeight: '28px',
                        color: '#000000',
                        textAlign: 'right'
                    }}>
                        {projectName + ' ' + 'Campaigns' }
                    </Box>

                </Grid>
                <Stack direction="row"
                       sx={{marginTop: "12px", width: '100%', flexDirection: {xs: 'column', sm: 'row'}}}
                       justifyContent="space-between">
                    <Box sx={{justifyContent: 'center'}}>
                        <CustomToggleButton filterBy={onHandleFilterBy} remove={'DRAFT'}/>
                    </Box>
                    <Box sx={{justifyContent: 'center', textAlign: 'right', mt: {xs: 2, sm: 0}}}>
                        <Box>
                            <Stack direction="row" spacing={2}>
                                <Box>
                                    <SearchComponent searchData={handleSearchChange}/>
                                </Box>
                                <Box>
                                    <ThemeProvider theme={theme}>
                                        <ToggleButtonGroup
                                            value={value}
                                            exclusive
                                            onChange={handleFilterAlignment}
                                            variant="outlined"
                                            color="primary"
                                        >
                                            <ToggleButton value="list" selectedcolor="#6941C6">
                                                <TableRowsOutlinedIcon/>
                                            </ToggleButton>
                                            <ToggleButton value="grid" selectedcolor="#6941C6">
                                                <WindowOutlinedIcon/>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </ThemeProvider>
                                </Box>
                            </Stack>

                        </Box>

                    </Box>
                </Stack>

                {/*table start*/}
                {showListView ?
                    <Box>
                        {campaignLists.length > 0 ?
                            <Box style={{width: "100%", marginTop: '30px'}}>
                                <GenericTable tableConfig={tableConfig}
                                              tableData={campaignLists}
                                              totalRecords={totalTableRecords}
                                              getCurrentPageNumber={(e) => {
                                                  onChangePage(e)
                                              }}
                                              onRowClick={(e) => {
                                                  onSelectRow(e)
                                              }}
                                />
                            </Box> :

                            <Box sx={{
                                textAlign: "center",
                                marginTop: "30px",
                                fontWeight: '600',
                                fontSize: '20px',
                                lineHeight: '28px',
                                color: '#000000',
                            }}> No Data Found
                            </Box>
                        } </Box> : null
                }

                {/*grid show*/}
                {!showListView ?
                    <Box>
                        {campaignLists.length > 0 ?
                            <Box sx={{flexGrow: 1, marginTop: '30px'}}>
                                <Grid container spacing={{xs: 2, sm: 2, md: 3}}>
                                    {campaignLists && campaignLists.map((row, rowIndex) => (
                                        <Grid item key={rowIndex}>
                                            <Box sx={{
                                                maxWidth: "302px",
                                                cursor: 'pointer',
                                                p: 2,
                                                boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                                                border: "1px solid #F2F4F7",
                                                borderRadius: "12px"
                                            }}>
                                                <Box>
                                                   <Stack direction="row" spacing={2}>
                                                       {/* <Avatar sx={{width: 56, height: 56}} alt='Acme'
                                                               src={row.projectImage}/> */}
                                                       <Box style={{
                                                           marginTop: '20px',
                                                           fontWeight: '700',
                                                            fontFamily: 'Inter',
                                                            fontSize: '20px',
                                                            lineHeight: '30px',
                                                            color: '#101828'
                                                       }}>
                                                           {row.campaignTitle}
                                                       </Box>
                                                   </Stack>
                                                </Box>
                                                <Box sx={{marginTop: '8px'}}>
                                                    <Stack spacing={1}>
                                                        <Box className={styles.TextTitle} sx={{
                                                            fontSize: '20px',
                                                            fontWeight: "700"
                                                        }}>{row['Name']}</Box>
                                                        <Chip
                                                            label={(row.campaignRemark === 'ENDED') ? 'Ended' : (row.campaignRemark === 'RUNNING' ? 'Running' :
                                                                (row.campaignRemark === 'UNDER_REVIEW' ? 'Under Review' : (row.campaignRemark === 'DRAFT' ? 'Draft' :
                                                                    (row.campaignRemark === 'PENDING' ? 'Pending' : (row.campaignRemark === 'REJECTED' ? 'REJECTED' :(row.campaignRemark === 'APPROVED' ? 'Approved' : 'Paused'))))))
                                                            } style={{
                                                            background: (row.campaignRemark === 'ENDED') ? '#344054' : (row.campaignRemark === 'RUNNING' ? '#039855' :
                                                                (row.campaignRemark === 'UNDER_REVIEW' ? '#E04F16' : (row.campaignRemark === 'DRAFT' ? '#444CE7' :
                                                                    (row.campaignRemark === 'PENDING' ? '#344054' : (row.campaignRemark === 'REJECTED' ? '#B00020' : (row.campaignRemark === 'APPROVED' ? '#039855': '#344054')))))),
                                                            color: '#ffffff',
                                                            width: 'fit-content'
                                                        }}/>
                                                    </Stack>
                                                </Box>
                                                <Stack sx={{marginTop: "12px"}} direction="row"
                                                       justifyContent="space-between"
                                                       alignItems="center">
                                                    <Box sx={{marginTop: '3px'}}>
                                                        <Box className={styles.DateTextTitle} sx={{
                                                            color: '#667085',
                                                            fontSize: "12px",
                                                            fontWeight: "500"

                                                        }}>Starting Date</Box>
                                                        <Stack direction="row" spacing={1} alignItems={'center'}
                                                               sx={{marginTop: '4px'}}>
                                                            <CalendarTodayIcon sx={{fontSize: 20}}/>
                                                            <Box className={styles.DateText} sx={{
                                                                color: '#344054',
                                                                fontSize: "16px",
                                                                fontWeight: "500"
                                                            }}>{row.campaignStartDate ? (moment(row.campaignStartDate).format('DD/MM/YYYY')) : '-'}</Box>
                                                        </Stack>
                                                    </Box>

                                                    <Box>
                                                        <Box className={styles.DateTextTitle} sx={{
                                                            color: '#667085',
                                                            fontSize: "12px",
                                                            fontWeight: "500"

                                                        }}>Ending Date</Box>
                                                        <Stack direction="row" spacing={1} alignItems={'center'}
                                                               sx={{marginTop: '4px'}}>
                                                            <CalendarTodayIcon sx={{fontSize: 20}}/>
                                                            <Box className={styles.DateText} sx={{
                                                                color: '#344054',
                                                                fontSize: "16px",
                                                                fontWeight: "500"
                                                            }}>{row.campaignEndDate ? moment(row.campaignEndDate).format('DD/MM/YYYY') : '-'}</Box>
                                                        </Stack>
                                                    </Box>
                                                </Stack>
                                                <Divider sx={{marginTop: "12px", marginBottom: "12px"}}/>

                                                {['UNDER_REVIEW', 'DRAFT'].includes(row.campaignRemark) ?
                                                    <Box>
                                                        {row.campaignRemark === 'UNDER_REVIEW' ?

                                                            <Stack sx={{marginTop: "12px", width: '100%'}}
                                                                   direction="row"
                                                                   justifyContent="space-between"
                                                                   alignItems="center">
                                                                <Box sx={{justifyContent: 'center'}}>
                                                                    <Button variant="text"
                                                                            className={styles.ViewBtn}
                                                                            sx={{
                                                                                color: ' #344054',
                                                                                textTransform: "none"
                                                                            }}
                                                                            onClick={(e) => {
                                                                                onViewDetailsClick(e, row);
                                                                            }}>
                                                                        View Details
                                                                    </Button>
                                                                </Box>
                                                                <Box sx={{
                                                                    justifyContent: 'center',
                                                                    textAlign: 'right'
                                                                }}>
                                                                    <Box>
                                                                        <Stack direction="row" spacing={1}>
                                                                            <IconButton
                                                                                aria-label="DoneOutlinedIcon"
                                                                                onClick={(e) => {
                                                                                    onClickHandelApproveEvent(e);
                                                                                    setCamDetails(row);
                                                                                }}>
                                                                                <DoneOutlinedIcon color="success"
                                                                                                  style={{
                                                                                                      color: '#039855!important',
                                                                                                  }}/>
                                                                            </IconButton>
                                                                            <IconButton
                                                                                aria-label="ClearOutlinedIcon"
                                                                                color="primary"
                                                                                onClick={(e) => {
                                                                                    onClickHandelRejectEvent(e);
                                                                                    setCamDetails(row);
                                                                                }}>
                                                                                <ClearOutlinedIcon color="error"
                                                                                                   sx={{
                                                                                                       color: '#B42318!important',
                                                                                                   }}/>
                                                                            </IconButton>
                                                                        </Stack>
                                                                    </Box>
                                                                </Box>
                                                            </Stack>

                                                            :

                                                            <Box sx={{textAlign: 'center'}}>
                                                                <Button variant="text" className={styles.ViewBtn}
                                                                        sx={{
                                                                            color: ' #6941C6',
                                                                            textTransform: "none"
                                                                        }}
                                                                        onClick={(e) => {
                                                                            gotoCampaignEdit(e, row)
                                                                        }}>
                                                                    Continue Editing
                                                                </Button>
                                                            </Box>
                                                        }
                                                    </Box>
                                                    :
                                                    <Box>

                                                        {
                                                            ['RUNNING', 'Active', "APPROVED"].includes(row.campaignRemark) ?
                                                                <Box sx={{
                                                                    textAlign: 'center',
                                                                    justifyContent: 'center'
                                                                }}>
                                                                    <Button variant="text"
                                                                            className={styles.ViewBtn}
                                                                            sx={{
                                                                                color: ' #344054',
                                                                                textTransform: "none"
                                                                            }}
                                                                            onClick={(e) => {
                                                                                onViewDetailsClick(e, row);
                                                                            }}>
                                                                        View Details
                                                                    </Button>
                                                                </Box> :

                                                                <Stack sx={{marginTop: "12px", width: '100%'}}
                                                                       direction="row"
                                                                       justifyContent="space-between"
                                                                       alignItems="center">
                                                                    <Box sx={{justifyContent: 'center'}}>
                                                                        <Button variant="text"
                                                                                className={styles.ViewBtn}
                                                                                sx={{
                                                                                    color: ' #344054',
                                                                                    textTransform: "none"
                                                                                }}
                                                                                onClick={(e) => {
                                                                                    onViewDetailsClick(e, row);
                                                                                }}>
                                                                            View Details
                                                                        </Button>
                                                                    </Box>
                                                                    <Box sx={{
                                                                        justifyContent: 'center',
                                                                        textAlign: 'right'
                                                                    }}>
                                                                        <Box>
                                                                            <Button
                                                                                onClick={(e) => {
                                                                                    if (row.campaignRemark === 'ENDED') {
                                                                                        onViewDetailsClick(e, row);
                                                                                    }
                                                                                }}
                                                                                sx={{
                                                                                    color: "#344054",
                                                                                    textTransform: 'none'
                                                                                }}
                                                                                startIcon={<FileUploadOutlinedIcon
                                                                                    color={"success"}/>}>
                                                                                {(row.campaignRemark === 'ENDED') ? 'Upload' : 'Tags'}
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </Stack>
                                                        }
                                                    </Box>
                                                }
                                            </Box>
                                        </Grid>
                                    ))}

                                </Grid>
                                <Box sx={{
                                    mt: 2
                                }}>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={totalTableRecords}
                                        pageSize={tableConfig?.PageSize}
                                        onPageChange={(page) => {
                                            handelOnChangePage(page)
                                        }}
                                    />
                                </Box>
                            </Box> :

                            <Box sx={{
                                textAlign: "center",
                                marginTop: "30px",
                                fontWeight: '600',
                                fontSize: '20px',
                                lineHeight: '28px',
                                color: '#000000',
                            }}> No Data Found
                            </Box>
                        }
                    </Box> : null
                }

                {/*grid show end*/}

            </Box>
        </Box>

        <Box className="modal">
            <AdminViewDetailsModal
                adminViewModalOpen={adminViewModalOpen}
                detailsData={camDetails}
                getApprovalDetails={handleApprovalDetails}
                handleAdminViewModalClose={handleAdminDetailsModalClose}/>
        </Box>

        {/*ConfirmationModal modal*/}
        <Box className="modal">
            <ConfirmationModal
                isApprovedMode={isApprovedModal}
                confirmationModalOpen={confirmModalOpen}
                rejectReason={onHandelRejectReason}
                handleIfConfirmationApproved={handleOnConfirmationApproved}
                handleConfirmationModalClose={handleOnApprovedModalClose}>
            </ConfirmationModal>
        </Box>

    </>);
}
export default CampaignListView;
