import { authApi } from "../interceptor/auth.interceptor"

class CampaignService {
  static async createCampaign(
    {
      reportingType,
      competitorAnalysis,
      platformDetailsFile,
      locationDetailsFile,
      competitorPOIFile,
      ...restOfData
    },
    isDraft,
    campId
  ) {
    const formData = new FormData()
    formData.append("platformDetailsCsv", platformDetailsFile)
    formData.append("locationDetailsCsv", locationDetailsFile)
    if (competitorAnalysis && competitorPOIFile) {
      formData.append("competitorPOICsv", competitorPOIFile)
    }

    const createProjectData = {
      ...restOfData,
      ...(restOfData.projectId && { projectId: restOfData.projectId }),
    }

    formData.append("createProject", JSON.stringify(createProjectData))

    try {
      const response = await authApi.post(`projectCampaigns/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "reporting-type": reportingType,
          "competitor-Analysis": competitorAnalysis,
          "save-as-draft": isDraft ? "true" : "false",
          "campaign-id": campId
        },
      })
      return response?.data
    } catch (error) {
      throw error
    }
  }

  static async draftCampign(data, projectId, campId) {
    return await authApi
      .put(
        `projectCampaigns/update/draft?project_id=${projectId}&campaign_id=${campId}`,
        data
      )
      .then((response) => {
        return response?.data
      })
  }

  static async getCampaignDetails(campaignId) {
    return await authApi
      .get(`campaign/view-details?campaignId=${campaignId}`)
      .then((response) => {
        return response?.data
      })
  }

  static async getAllPendingReviews(queryParams) {
    return await authApi
      .get(`admin/dashboard/pendingReviews?${queryParams}`)
      .then((response) => {
        return response?.data
      })
  }

  static async getAllClientCampaignList(queryParams) {
    return await authApi
      .get(`client/dashboard/campaignList?${queryParams}`)
      .then((response) => {
        return response?.data
      })
  }

  static async getCampaignViewDetails(campaignId) {
    return await authApi
      .get(`projectCampaigns/${campaignId}/view`)
      .then((response) => {
        return response?.data
      })
  }

  static async approveCampaignStatus(queryParams) {
    return await authApi
      .patch(`campaigns/approve/${queryParams}`)
      .then((response) => {
        return response?.data
      })
  }

  static async rejectCampaignStatus(campId, reason) {
    const headers = { reason: reason }
    return await authApi
      .patch(`campaigns/reject/${campId}`, null, { headers })
      .then((response) => {
        return response?.data
      })
  }

  static async updateCampaign(formData, campaignId) {
    return await authApi

      .put(`projectCampaigns/update/draft?campaign_id=${campaignId}`, formData)
      .then((response) => {
        return response?.data
      })
  }

  static async getCampaignPoiDownloadFile(campaignId, isTarget) {
    return await authApi
      .get(
        `campaign/download-poi?campaignId=${campaignId}&isTarget=${isTarget}`
      )
      .then((response) => {
        return response
      })
  }

  static async validateLocation(file) {
    const formData = new FormData()
    formData.append("locationDetailsCsv", file)

    return await authApi
      .post("projectCampaigns/validate/location-details", formData, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response?.data
      })
  }

  static async validateCompetitor(file) {
    const formData = new FormData()
    formData.append("locationDetailsCsv", file)

    return await authApi
      .post("projectCampaigns/validate/competitor-details", formData, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response?.data
      })
  }
}

export default CampaignService
