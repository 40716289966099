import React, { useEffect, useState } from "react";
import {
    Box, Modal, Stepper, Step,
    Button, StepLabel
} from "@mui/material";
import { useForm } from "react-hook-form";
import './CreateCampaign.scss';
import SaveIcon from '@mui/icons-material/Save';
import CampaignDetails from "./campaign-details/CampaignDetails";
import PlatformDetails from "./platform-details/PlatformDetails";
import LocationDetails from "./location-details/LocationDetails";
import CustomStepIcon from "./CustomStepIcon";
import ProjectService from "../../../services/api/project.service";
import PlatformService from "../../../services/api/platform.service";
import CampaignService from "../../../services/api/campaign.service";
import { useDispatch } from "react-redux";
import { setMessage } from "../../../redux/slices/message";
import { setMessageSnackbarStateState } from "../../../redux/slices/common";
import { errorMessage } from "../../../config/config";
import { useSelector } from "react-redux";

const steps = ['Campaign Details', 'Platform Details', 'Location Details'];

const CreateCampaignModal = ({ createCampaignModalOpen, handleCreateCampaignModaClose }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.auth.user);

    const [isValidForm, setFormValidity] = useState(true);
    const [isPlatformsLoaded, setPlatfromsLoaded] = useState(false);
    const [isProjectsLoaded, setProjectsLoaded] = useState(false);

    const [platforms, setPlatfroms] = useState([]);
    const [projects, setProjects] = useState([]);

    const [POIs, setPOIS] = useState();
    const [competitorPOIs, setCompetitorPOIs] = useState();
    const [newProjectImage, setNewProjectImage] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const setPOIsData = (files) => {
        files.name ? setPOIS(files) : setPOIS(null);
    }

    const setCompetitorPOIsData = (files) => {
        files.name ? setCompetitorPOIs(files) : setCompetitorPOIs(null);
    }

    const setNewProjectImageData = (files) => {
        files.name ? setNewProjectImage(files) : setNewProjectImage(null);
    }

    const handlePlatforms = (index) => {
        const selectedPlatforms =  getValues("platforms");
        selectedPlatforms[index].isChecked = !selectedPlatforms[index].isChecked;
        if(selectedPlatforms[index].isChecked) {
            selectedPlatforms[index].fields = ["https://"]
        }
        else{
            selectedPlatforms[index].fields = []
        }
        setValue("platforms", selectedPlatforms);
    }

    const handleAddingNewField = (index) => {
        const selectedPlatforms =  getValues("platforms");
        selectedPlatforms[index].fields = [...selectedPlatforms[index].fields, "https://"];
        setValue("platforms", selectedPlatforms);
    }

    const handleDeletingNewField = (index, fieldIndex) => {
        const selectedPlatforms =  getValues("platforms");
        selectedPlatforms[index].fields.splice(fieldIndex, 1);

        if(selectedPlatforms[index].fields.length == 0){
            selectedPlatforms[index].isChecked = false;
        }
        setValue("platforms", selectedPlatforms);
    }

    const handleAddProjectField = (writtenValue) => {
        if (writtenValue) {
            // projects.push({
            //     id: projects.length + 10000,
            //     name: writtenValue
            // })
            // setProjects(projects);
            // setValue("projects", projects);
            // // setValue("showNewProjectName", true);
            setValue("new_project_name", writtenValue);
            setValue("project_name", projects.length + 9999);
            
        }
    };

    const { register, formState, setValue, getValues, control, watch, handleSubmit, formState: { errors }, reset } = useForm(
        {
            mode: 'onChange',
            defaultValues: {
                new_project_name: "",
                project_name: "",
                title: "",
                reportingType: "Standard",
                platforms: platforms,
                projects: projects,
                showNewProjectName: false
            }
        }
    );

    const formData = watch();

    useEffect(() => {
        // console.log(formData);
        setFormValidity(!checkFormValidity())
        
    }, [formData])


    // useEffect(() => {
    //     if(user != null) {
    //         PlatformService.getPlatformList({
    //             userId: user.id
    //         }).then(
    //             (data) => {
    //               setPlatfromsLoaded(true);
    //               if (data) {
    //                 populatePlatformsData(data);
    //               }
    //               return Promise.resolve();
    //             },
    //             (error) => {
    //               return Promise.reject();
    //             }
    //           );
    //     }
    // }, [user])

    // const populatePlatformsData = (platformsData) => {
    //     const allPlatforms = [];

    //     if(!platformsData || platformsData.length == 0) return;
    //     platformsData.forEach((platform, index)=> {
    //         allPlatforms.push({
    //             name: platform.name,
    //             id: index,
    //             fields: [],
    //             isChecked: false
    //         })    
    //     });
    //     setPlatfroms(allPlatforms);
    //     setValue("platforms", allPlatforms);
    //     setPlatfroms(allPlatforms);
    // }

    // useEffect(() => {
    //     if(user?.id) {
    //         ProjectService.getAllProjectList(
    //          user?.id
    //         ).then(
    //             (data) => {
    //               setProjectsLoaded(true);
    //               if (data) {
    //                 populateProjectsData(data.results);
    //               }
    //               return Promise.resolve();
    //             },
    //             (error) => {
    //               return Promise.reject();
    //             }
    //           );
    //     }

    // }, [user])

    // const populateProjectsData = (projectsData) => {
    //     const allProjects = [];

    //     if(!projectsData.content || projectsData.content.length == 0) return;
    //     projectsData.content.forEach(project => {
    //         if(project.name && project.id){
    //             allProjects.push({
    //                 name: project.name,
    //                 id: project.id,
    //                 image: project.imageURL
    //             }) 
    //         }   
    //     });
    //     const uniqueProjectList = makeListUnique(allProjects, 'name');
    //     setProjects(uniqueProjectList);
    //     setValue("projects", uniqueProjectList);
    //     setProjects(uniqueProjectList);
    // }

    // const makeListUnique = (list, key) => {
    //     const uniqueKeys = new Set(); 
    //     return list.filter((item) => {
    //       if (!uniqueKeys.has(item[key])) { 
    //         uniqueKeys.add(item[key]); 
    //         return true; 
    //       }
    //       return false; 
    //     });
    //   };
      

    const onSubmit = (values) => {
        const formData = values.email
        setIsSubmitting(true);
    };

    const handleOnClose = () => {
        resetForm();
        handleCreateCampaignModaClose(true);
    }

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        if(activeStep == 2) {
            makeCreateCampaignPayload("UNDER_REVIEW");
            return;
        }

        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const saveAsDraft = ()=> {
        makeCreateCampaignPayload("DRAFT");
    }

    const makeCreateCampaignPayload = (status) => {
        const formData = getValues();
        // console.log(formData);
        const dcmTrackers = [];
        const landingPageFiles = [];
        if(formData.platforms && formData.platforms.length > 0){
            formData.platforms.forEach(platform => {
                if(formData.dcm == "yes") {
                    if(platform.file){
                        landingPageFiles.push(platform.file);
                        dcmTrackers.push({
                            name: platform.name,
                            platformId: platform.id,
                            url: platform.fields
                        })
                    }
                }
                else if(platform.fields && platform.fields.length > 0){
                    dcmTrackers.push({
                        name: platform.name,
                        platformId: platform.id,
                        url: platform.fields
                    })
                } 
            });
        }

        const payload = {
            userId: user.id,
            name: formData.title,
            projectId: formData.project_name,
            status: status,
            startDate: formData.startDate.toString() && formData.startDate.toISOString().slice(0, -5).replace("T", " "),
            endDate: formData.endDate && formData.endDate.toISOString().slice(0, -5).replace("T", " "),
            reportType: formData.reportingType,
            isTracker: formData.dcm == "yes",
            isAddTag: formData.tag == "yes",
            landingPageUrl: dcmTrackers
        }

        const payloadData = new FormData();
        payloadData.append('targetPoi', formData.POIs);
        payloadData.append('competitorPoi', formData.competitorPOIs);
        payloadData.append('projImage', formData.projectImage);
        landingPageFiles.forEach(file => {
            if(file){
                payloadData.append('landingPageFile', file);
            }
        });

        if(formData.project_name < 9000) {
            payloadData.append("campaignDTO", JSON.stringify(payload));
            createCampaign(payloadData);
        }
        else {
            payload.projectId = null;
            payload.projectName = formData.new_project_name;
            payloadData.append("campaignDTO", JSON.stringify(payload));
            createCampaign(payloadData);
        }
    }

    const createCampaign = (payloadData) => {
        CampaignService.createCampign(payloadData).then(
            (data) => {
                resetForm();
              // console.log("data", data);
              if (data?.results) {
                dispatch(setMessage({
                  type: "success",
                  title: data?.message ? data.message : "Campaign created successfully",
                }));
                dispatch(setMessageSnackbarStateState(true));
                handleOnClose();
              } else {
                dispatch(setMessage({
                  type: "error",
                  title: errorMessage,
                }));
                dispatch(setMessageSnackbarStateState(true));
              }
              window.location.reload();
              return Promise.resolve();
            },
            (error) => {
              console.log(error);
              dispatch(setMessage({
                type: "error",
                title: errorMessage,
              }));
              dispatch(setMessageSnackbarStateState(true));
              return Promise.reject();
            }
        );
    }

    const checkFormValidity = () => {
        const validationResultForFirstPage = getFirstPageValidation();
        const validationResultForSecondPage =  getSecondPageValidation();
        const validationResultForThirdPage =  getThirdPageValidation();
        if(activeStep == 0) {
          return validationResultForFirstPage;
        }
        else if(activeStep == 1) {
             return validationResultForFirstPage && validationResultForSecondPage;
        }
        else if(activeStep == 2){
           return validationResultForFirstPage && validationResultForSecondPage && validationResultForThirdPage;
        }
       
    }

    const getFirstPageValidation = () => {
        if(formData.new_project_name && formData.project_name > 10000 && !formData.projectImage) return false;

        return (formData.new_project_name || formData.project_name)
                && formData.title
                && formData.startDate
                && formData.endDate;
    }

    const getSecondPageValidation = () => {
        const checkSingleFile = formData.platforms.filter(p => p.isChecked);
        if(!checkSingleFile || !checkSingleFile.length) return false;

        if(formData.dcm == "yes"){
           const checkedFile = formData.platforms.filter(p => p.isChecked && !p.file);
           return !(checkedFile && checkedFile.length > 0);
        }
        else {
            const checkedPlatform = formData.platforms.filter(p => p.isChecked && p.fields && (!p.fields[0] || ( p.fields[0] && p.fields[0].length < 9)));
            return !(checkedPlatform && checkedPlatform.length > 0);
        }    
    }

    const getThirdPageValidation = () => {
        if(formData.competitorAnalysis && !formData.competitorPOIs) return false;
        return POIs;
    }

    const resetForm = ()=> {
        setActiveStep(0);
        reset();
        setValue("platforms", platforms);
        setValue("projects", projects);
    }

    return (
        <Modal
            open={createCampaignModalOpen}
            onClose={handleCreateCampaignModaClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                backdropFilter: "blur(15px)",
            }}
        >
            <Box
                className="create-campaign-modal"
            >
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit(onSubmit)}
                    type='reset'
                >
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Box className="login-logo">
                            <img
                                src="/images/featured-icon.png"
                                alt="logo"
                                style={{ height: 48 }}
                            />
                        </Box>
                        <Box sx={{
                            cursor: "pointer"
                        }} className="login-logo" onClick={handleOnClose}>
                            <img
                                src="/images/close-icon.png"
                                alt="logo"
                                style={{ height: 48 }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepLabel StepIconComponent={CustomStepIcon} color="inherit" onClick={handleStep(index)}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {
                            activeStep == 0 && isProjectsLoaded && <CampaignDetails
                                control={control}
                                register={register}
                                projects={projects}
                                handleAddProjectField={handleAddProjectField}
                                getValues={getValues}
                                setValue={setValue}
                                setNewProjectImageData={setNewProjectImageData}
                            />
                        }
                        {
                            activeStep == 1 && isProjectsLoaded && <PlatformDetails
                                control={control}
                                handlePlatforms={handlePlatforms}
                                handleAddingNewField={handleAddingNewField}
                                handleDeletingNewField={handleDeletingNewField}
                                getValues={getValues}
                            />
                        }
                        {
                            activeStep == 2 && <LocationDetails
                                control={control}
                                getValues={getValues}
                                setPOIsData={setPOIsData}
                                setCompetitorPOIsData={setCompetitorPOIsData}
                            />
                        }

                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 2,
                            mt: 4
                        }}>
                            <Button disabled={isValidForm} onClick={saveAsDraft} sx={{
                                width: "50%",
                                textTransform: 'none'
                            }} variant="outlined" startIcon={<SaveIcon />}>
                                Save as draft
                            </Button>

                            <Button disabled={isValidForm} sx={{
                                width: "50%",
                                textTransform: "capitalize"
                            }} variant="contained" onClick={handleNext}>
                                {activeStep == 2 ? "Create" : "Next"}
                            </Button>
                        </Box>

                    </Box>

                </Box>
            </Box>
        </Modal>
    );
}

export default CreateCampaignModal;
