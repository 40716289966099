import * as React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import './toggleButton.scss';
import { Box, styled } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const ToggleButton = styled(MuiToggleButton)(({ selectedcolor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: selectedcolor,
    },
    'css-p56ftb-MuiButtonBase-root-MuiToggleButton-root': {
        border: 'none!important'
    }
}));

const theme = createTheme({
    palette: {
        text: {
            primary: "#6941C6",
        },
    },
});

const options = [
    { label: "All", value: "All" },
    { label: "Under Review", value: "UNDER_REVIEW" },
    { label: "Running", value: "RUNNING" },
    { label: "Draft", value: "DRAFT" },
    { label: "Approved", value: "APPROVED" },
    { label: "Ended", value: "ENDED" },
    { label: "Rejected", value: "REJECTED" }
];

const CustomToggleButton = ({ filterBy, remove }) => {
    const [alignment, setAlignment] = React.useState("All");
    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
            filterBy(newAlignment);
        }
    };

    const filteredOptions = remove
        ? options.filter(option => option.value !== remove)
        : options;

    return (
        <Box sx={{ padding: '4px', border: '1px solid #EAECF0', borderRadius: '8px' }}>
            <ThemeProvider theme={theme}>
                <ToggleButtonGroup
                    style={{
                        fontWeight: '600',
                        fontSize: '20px',
                        lineHeight: '20px',
                    }}
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    variant="outlined"
                    color="primary"
                >
                    {filteredOptions.map(option => (
                        <ToggleButton
                            key={option.value}
                            sx={{ textTransform: "none" }}
                            value={option.value}
                            selectedcolor="#6941C6"
                        >
                            {option.label}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </ThemeProvider>
        </Box>
    );
};

export default CustomToggleButton;
