import React, {useState, useRef} from "react";
import { Controller } from "react-hook-form";
import SearchIcon from '@mui/icons-material/Search';
import {Box, TextField, InputAdornment, Autocomplete } from "@mui/material";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AddIcon from '@mui/icons-material/Add';
import "./ProjectSelect.scss";

export const ProjectSelect = (props) => {
    const { control, options, name, disabled, iconName, addNewProject, getValues, setValue } = props;
    const inputRef = useRef(null);
    const [placeholder, setPlaceholder] = useState("Search");

    const handleAddClick = () => {
        setPlaceholder("Please write new project name");
        setValue(name, null);
    }

    const handleInputChange = (event, newInputValue) => {
        if(newInputValue == getValues("new_project_name")) return;
        
       if(!newInputValue) {
        setPlaceholder("Search");
       }
        setValue("selected_project_image", null);
        addNewProject(newInputValue);
    }

    const setSelectedValue = (project) => {
        if(project && project.image) {
            const projArray = project.image.split("/");
            setValue("selected_project_image", projArray[projArray.length - 1]);
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            // rules={{
            //   required: "this field is required"
            // }}
            render={({ field, fieldState: { error } }) => {
                const { onChange, value, ref } = field;

                return (
                    <>
                        <Autocomplete
                            freeSolo
                            disabled={disabled}
                            value={
                                value
                                    ? options.find((option) => {
                                        return value === option.id;
                                    }) ?? null
                                    : null
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            onChange={(event, newValue) => {
                                setSelectedValue(newValue);
                                onChange(newValue ? newValue.id : null);
                            }}
                            onInputChange={handleInputChange}
                            id="controllable-states-demo"
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                    disabled={disabled}
                                    {...params}
                                    id="search"
                                    size="small"
                                    placeholder={placeholder}
                                    sx={{ width: "100%" }}
                                    inputRef={inputRef}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {
                                                    iconName != "timezone" && <SearchIcon style={{ fill: "#D0D5DD" }} />
                                                }
                                                {
                                                    iconName == "timezone" && <AccessTimeOutlinedIcon style={{ fill: "#D0D5DD" }} />
                                                }
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                        <Box onClick={handleAddClick} sx={{
                                                            position: "relative",
                                                            left: "7px",
                                                            width: "24px",
                                                            height: "24px",
                                                            borderRadius: "50%",
                                                            background: "#6927DA",
                                                            cursor: "pointer"
                                                        }}>
                                                          <AddIcon sx={{
                                                            color: "white",
                                                          }} />
                                                        </Box>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        />
                        {error ? (
                            <span style={{ color: "red" }}>{error.message}</span>
                        ) : null}
                    </>
                );
            }}
        />
    );
};