import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./app";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";


const theme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif", // Set Inter as the default font family
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
    </ThemeProvider>
  // </React.StrictMode>
);