// React Core Imports
import React, { useEffect, useState } from "react"

// Routing
import { useNavigate } from "react-router-dom"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { setIsLoadingState } from "../../redux/slices/common"

// MUI Components
import { Box, Typography, Grid } from "@mui/material"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"

// Custom Components
import Pagination from "../../components/common/GenericTable/Pagination/Pagination"
import CardComponent from "../../components/common/CardView/cardComponent"
import ClientList from "./clientList"

// Services
import CampaignService from "../../services/api/campaign.service"
import ClientService from "../../services/api/client.service"
import TeamMemberService from "../../services/api/team-member.service"

const AdminDashboard = () => {
  // Redux State
  const user = useSelector((state) => state.auth.auth.user)
  const dispatch = useDispatch()

  // Local States
  const [pendingCampaign, setPendingCampaignData] = useState([])
  const [clientList, setClientListData] = useState([])
  const [totalClientRecords, setTotalClientRecords] = useState(0)
  const [totalPendingReviewRecords, setTotalPendingReviewRecords] = useState(0)
  const [currentClientPage, setClientCurrentPage] = useState(1)
  const [currentPendingReviewPage, setCurrentPendingReviewPage] = useState(1)

  const [adminProjectDetails, setAdminProjectDetails] = useState([])

  // Pagination Config
  const clientPageSize = 8
  const pendingReviewSize = 8
  const [pageQueryParams, setPageQueryParams] = useState({
    page: 0,
    size: clientPageSize,
  })

  const [pagePRQueryParams, setPagePRQueryParams] = useState({
    page: 0,
    size: pendingReviewSize,
  })
  // Navigation
  const navigate = useNavigate()
  const redirectToProjectListView = (row) => {
    navigate(`/project-view/${row.companyId}/${row.companyName}`)
  }

  useEffect(() => {
    if (user) {
      dispatch(setIsLoadingState(true))
      TeamMemberService.getMembersDashboardData()
        .then(
          (data) => {
            let adminAnalytics = []

            Object.entries(data.data).forEach(([key, val]) => {
              switch (key) {
                case "totalCustomerCount":
                  adminAnalytics.push({
                    title: "Total Customers",
                    count: val,
                    percentageAmount: 20,
                    status: true,
                  })
                  break
                case "totalMemberCount":
                  adminAnalytics.push({
                    title: "Members",
                    count: val,
                    percentageAmount: 30,
                    status: false,
                  })
                  break
                case "totalUserCount":
                  adminAnalytics.push({
                    title: "Active Now",
                    count: val,
                    percentageAmount: 10,
                    status: false,
                  })
                  break
                // Add new cases here for other keys in the response
                default:
                  // Handle any other keys if necessary
                  break
              }
            })
            setAdminProjectDetails(adminAnalytics)
            return Promise.resolve()
          },
          (error) => {
            console.log("Error Message", error.response.data.message)
            return Promise.reject()
          }
        )
        .finally(() => {
          dispatch(setIsLoadingState(false))
        })
    }
  }, [dispatch, user])

  useEffect(() => {
    if (user) {
      dispatch(setIsLoadingState(true))
      let queryParams = `page=${pagePRQueryParams.page}&size=${pagePRQueryParams.size}`

      CampaignService.getAllPendingReviews(queryParams)
        .then(
          (data) => {
            setPendingCampaignData(data.data.content)
            setTotalPendingReviewRecords(data.data.totalElements)
            dispatch(setIsLoadingState(false))

            return Promise.resolve()
          },
          (error) => {
            console.log("Error Message", error.response.data.message)
            return Promise.reject()
          }
        )
        .finally(() => {
          dispatch(setIsLoadingState(false))
        })
    }
  }, [dispatch, pagePRQueryParams, user])

  useEffect(() => {
    if (user) {
      let queryParams = `page=${pageQueryParams.page}&size=${pageQueryParams.size}`
      dispatch(setIsLoadingState(true))
      ClientService.getAllClients(queryParams)
        .then(
          (data) => {
            setTotalClientRecords(data.data.totalElements)
            dispatch(setIsLoadingState(false))
            setClientListData(data.data.content)
            return Promise.resolve()
          },
          (error) => {
            console.log("Error Message", error.response.data.message)
            return Promise.reject()
          }
        )
        .finally(() => {
          dispatch(setIsLoadingState(false))
        })
    }
  }, [user, pageQueryParams, dispatch])

  const handelOnChangePage = (page) => {
    if (page) {
      setClientCurrentPage(page)
      setPageQueryParams({
        ...pageQueryParams,
        page: page - 1,
      })
    }
  }
  const handelOnChangePendingReviewPage = (page) => {
    if (page) {
      setCurrentPendingReviewPage(page)
      setPagePRQueryParams({
        ...pagePRQueryParams,
        page: page - 1,
      })
    }
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 6 }}>
      <Grid container spacing={3}>
        {/* Welcome Text */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ fontSize: "2rem", fontWeight: 500 }}>
            Welcome back, {user?.userName || user?.userFirstName}
          </Typography>
          <Typography variant="body1">
            Track, manage and forecast your campaign details.
          </Typography>
        </Grid>

        {/* Admin Info Grid - 3 Columns */}
        {adminProjectDetails.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                p: 3,
                border: "1px solid #EAECF0",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="body1">{item.title}</Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "2rem", fontWeight: 500 }}
              >
                {item.count}
              </Typography>
              {item.status !== undefined && (
                <Box sx={{ display: "flex" }}>
                  {item.status ? (
                    <Box sx={{ display: "flex" }}>
                      <ArrowUpwardIcon
                        sx={{ fontSize: "20px", color: "#12B76A" }}
                      />
                      <Box sx={{ color: "#12B76A" }}>
                        {item.percentageAmount}%
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      <ArrowDownwardIcon
                        sx={{ fontSize: "20px", color: "#F04438" }}
                      />
                      <Box sx={{ color: "#F04438" }}>
                        {item.percentageAmount}%
                      </Box>
                    </Box>
                  )}
                  <Typography paddingLeft={1} variant="body1">
                    vs last month
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "1.5rem",
              fontWeight: 500,
            }}
          >
            Pending Review
          </Typography>
        </Grid>

        {/*  Pending Campaign Card */}
        {pendingCampaign.length > 0 ? (
          <CardComponent data={pendingCampaign} />
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              sx={{ mt: 2 }}
            >
              No Pending Campaigns
            </Typography>
          </Grid>
        )}

        {/* Pagination */}
        <Grid item xs={12}>
          <Box sx={{ mt: 4 }}>
            <Pagination
              className="pagination-bar"
              currentPage={currentPendingReviewPage}
              totalCount={totalPendingReviewRecords}
              pageSize={pendingReviewSize}
              onPageChange={(page) => {
                handelOnChangePendingReviewPage(page)
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "1.5rem",
              fontWeight: 500,
            }}
          >
            All Clients
          </Typography>
        </Grid>

        {/* Client List Component */}
        {clientList.length > 0 ? (
          <ClientList
            clientList={clientList}
            redirectToProjectListView={redirectToProjectListView}
          />
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              sx={{ mt: 2 }}
            >
              No Clients Available
            </Typography>
          </Grid>
        )}

        {/* Pagination */}
        <Grid item xs={12}>
          <Box sx={{ mt: 4 }}>
            <Pagination
              className="pagination-bar"
              currentPage={currentClientPage}
              totalCount={totalClientRecords}
              pageSize={clientPageSize}
              onPageChange={(page) => {
                handelOnChangePage(page)
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
export default AdminDashboard
