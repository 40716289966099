import React from "react";
import {
    Box, TextField, Fab,
    Radio, RadioGroup, FormControlLabel, FormControl
} from "@mui/material";
import {  Controller } from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CommonFileUploader from "../../../common/CommonFileUploader/CommonFileUploader";
import { ProjectSelect } from "./project-select/ProjectSelect";

const CampaignDetails = (props) => {
    const {control, register, handleAddProjectField, setNewProjectImageData, getValues, setValue} = props;
   
    const handleAddProject =  (writtenValue) => {
        handleAddProjectField(writtenValue);
    };

    const getNewProjectImage = (files) => {
        setNewProjectImageData(files);
    }
   
    return (<>
        {/* <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 4
        }}>
            <Box className="create-campaign-title">New Project</Box>
            <Box onClick={handleAddProject}>

                <Fab size="small" color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Box>

        </Box> */}
        {/* <Box className='input-field'>
            <Box className='input'>
                { getValues("showNewProjectName") &&
                    <TextField
                        disabled={getValues("project_name") ? true: false}
                        {...register('new_project_name')}
                        placeholder='Enter project name'
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2 }}
                        size='small'
                    />
                }
            </Box>
        </Box> */}

<Box sx={{
            mt: 4
        }} className="create-campaign-title">Select your project*</Box>
        <Box sx={{
            mt: 2
        }} >
            <ProjectSelect
                options={getValues("projects")}
                control={control}
                name="project_name"
                placeholder="Select project"
                // disabled={getValues("new_project_name") ? true: false}
                addNewProject={handleAddProject}
                getValues={getValues}
                setValue={setValue}
            />
        </Box>
        <Box sx={
            {
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                mt: 2,
                color: "#475467"
            }
        }>Use search bar to select the project you want to include the campaign</Box>
        
        <Box>
            <Controller
                    name="projectImage" // Name of the field
                    control={control}
                    render={({ field }) => (
                        <CommonFileUploader 
                            // label={competitorLabel}
                            getFiles={(files) => {
                                field.onChange(files);
                                getNewProjectImage(files); // Update state
                            }}
                            fileTypes={["jpg", "png"]}
                            file={getValues("selected_project_image") ? {name : getValues("selected_project_image")}: field.value } // Use field.value as the file prop
                            showFileList={true}
                            label="project"
                            containerheight="74"
                            borderColor= "#7F56D9"
                            disabled={getValues("project_name") && getValues("project_name") < 10000}
                        />
                    )}
                />
        </Box>

        <Box sx={{
            mt: 1
        }} className="create-campaign-title">Title*</Box>
        <Box className='input-field'>
            <Box className='input'>
                <TextField
                    variant='outlined'
                    placeholder='What is your campaign title?'
                    sx={{ width: "100%", mt: 2 }}
                    size='small'
                    {...register('title', { required: true })}
                />
            </Box>
        </Box>
        <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{
                    display: "flex",
                    mt: 2,
                    gap: 2,
                    width: "100%"
                }}>
                    <Controller
                        name="startDate"
                        control={control}
                        defaultValue={null} // Set the default value for the start date
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                placeholder="Start date"
                                fullWidth
                                sx={{ width: "50%" }}
                                slotProps={{
                                    inputAdornment: {
                                        position: 'start'
                                    },
                                    textField: {
                                        placeholder: "Start date"
                                    }
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="endDate"
                        control={control}
                        defaultValue={null} // Set the default value for the end date
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                placeholder="End date"
                                fullWidth
                                sx={{ width: "50%" }}
                                slotProps={{
                                    inputAdornment: {
                                        position: 'start'
                                    },
                                    textField: {
                                        placeholder: "End date"
                                    }
                                }}
                            />
                        )}
                    />
                </Box>
            </LocalizationProvider>
        </Box>
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            gap : 1
        }}>
            <Box sx={{
                width: "50%"
            }}>
                <Box sx={{
                marginTop: "16px"
                }} className="create-campaign-title">Add DCM as Tracker</Box>
                <FormControl>
                    <Controller
                        name="dcm"
                        control={control}
                        defaultValue="no" // Set the default value here
                        render={({ field }) => (
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                {...field}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        )}
                    />
                </FormControl>
            </Box>

            <Box sx={{
                width: "50%",
                ml: 1
            }}>
                <Box sx={{
                marginTop: "16px"
                }} className="create-campaign-title">Add Tag</Box>
                <FormControl>
                    <Controller
                        name="tag"
                        control={control}
                        defaultValue="yes" // Set the default value here
                        render={({ field }) => (
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                {...field}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        )}
                    />
                </FormControl>
            </Box>
        </Box>
       
    </>);
}

export default CampaignDetails;
