 import { Box } from "@mui/material";
 const Footer = () => {
    return (<>
        <footer style={
              {
                display: "flex",
                justifyContent:"space-between"
              }
            }>
               <Box sx={{
                 fontWeight: 400,
                 fontSize: "14px",
                 lineHeight: "20px",
                 mt: 1,
                 color: '#000000'
               }}>
                  © Stretch 2024
               </Box>
               <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px"
               }}>
                <Box className="login-logo" sx={{
                  mt: 1
                }}>
                      <img
                      src="/images/mail.png"
                        alt="logo"
                        style={{ height: 10 }}
                      />
                  </Box>
                  <a href="mailto:help@stretch.com">
                  <Box sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  mt: 1,
                  color: '#000000'
                  
                }}>help@stretch.com</Box>
                  </a>
                 
               </Box>
            </footer>
    </>)
 }

  export default Footer;