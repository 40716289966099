import '../Settings.scss';
import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import './Clients.scss';
import SettingsHeader from '../../../components/common/SettingsHeader/SettingsHeader';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditClient from '../../../components/modals/edit-client-modal/EditClient';
import CreateClient from '../../../components/modals/create-client-modal/CreateClient';
import ClientService from '../../../services/api/client.service';
import { useNavigate } from 'react-router-dom';
import { getPermission } from '../../../utils/cryptograpgy';
import Pagination from '../../../components/common/GenericTable/Pagination/Pagination';
import { setIsLoadingState } from "../../../redux/slices/common"
import { useDispatch, useSelector } from "react-redux"



const Clients = () => {
    const navigate = useNavigate();
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const isUser = getPermission('STRETCH_CLIENT');
            if (isUser) {
                navigate('/');
            }
        }
        return () => {
            mounted = false;
        };
    }, [navigate]);

     // Pagination Config
  const clientPageSize = 8
  const [pageQueryParams, setPageQueryParams] = useState({
    page: 0,
    size: clientPageSize,
  })
    const [editClientModalOpen, setEditClientModalOpen] = useState(false);
    const [createClientModalOpen, setCreateClientModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [users, SetUsers] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const dispatch = useDispatch()

    const isUser = getPermission('STRETCH_CLIENT');
    const user = useSelector(state => state.auth.auth.user);

    let clientsPageSize = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const usersTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * clientsPageSize;
        const lastPageIndex = firstPageIndex + clientsPageSize;
        return users.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, users, clientsPageSize]);

    const handleEditClientModalClose = () => {
        setEditClientModalOpen(false);

    };

    const handleCreateClientModalClose = () => {
        setCreateClientModalOpen(false);
       
    };

    const handleCreate = (e) => {
        e.preventDefault();
        setCreateClientModalOpen(true);
    };

    const handleEdit = (rowData) => {
        setSelectedClient(rowData)
        setEditClientModalOpen(true);
    };

    const handleDelete = () => {
        console.log('Delete Click');
    };

    useEffect(() => {
            setLoading(false);

            let queryParams = `page=${pageQueryParams.page}&size=${pageQueryParams.size}`
            ClientService.getAllClients(queryParams)
            .then((res) => {
                console.log(res.data.content)
                if (res && res?.data?.content?.length > 0) {
                    setLoading(false);
                    dispatch(setIsLoadingState(false))
                const loadedRows = [];
                    res.data.content.forEach((client) => {
                        loadedRows.push({
                            id: client.companyId,
                            image: client.companyLogo,
                            name: client.companyName,
                            status: client.isActive === true ? 1 : 0,
                            projectCount: client.totalProjects,
                            campaignCount: client.totalCampaigns,
                            clientEmail: client.email
                        });
                    });
                    SetUsers(loadedRows);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [user]);

    const columns = [
        {
            field: 'image',
            headerName: '',
            width: 100,
            flex: 0.35,
            renderCell: (params) => (
                <img style={{ border: 'none', borderRadius: '50%', height: '35px', width: '35px',marginLeft:50 }} src={'/images/default-user-icon.jpg'} alt='' />
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 130,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const status = params.value;
                let mainDiv = { display: 'flex', alignItems: 'center' };

                let statusStyle = {};
                if (status) {
                    statusStyle = {
                        border: '1px solid #ABEFC6',
                        background: '#ECFDF3',
                        borderRadius: '20px',
                        color: '#067647',
                        padding: '1px 12px',
                        fontWeight: 500,
                        ...mainDiv,
                    };
                } else {
                    statusStyle = {
                        border: '1px solid #EAECF0',
                        borderRadius: '20px',
                        padding: '3px 13px',
                        fontWeight: 500,
                        ...mainDiv,
                    };
                }
                return (
                    <div style={statusStyle}>
                        <FiberManualRecordIcon
                            sx={{ marginRight: '3px', transform: 'scale(0.6)' }}
                        />
                        {status ? 'Active' : 'Deactivated'}
                    </div>
                );
            },
        },
        {
            field: 'campaignCount',
            headerName: 'Number Of Campaigns',
            type: 'number',
            minWidth: 130,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'projectCount',
            headerName: 'Number Of Project',
            type: 'number',
            minWidth: 130,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        // {
        //     field: 'actions',
        //     headerName: '',
        //     flex: 1,
        //     minWidth: 80,
        //     cellClassName: 'actions',
        //     align: 'center',
        //     headerAlign: 'center',
        //     filterable: false,
        //     sortable: false,
        //     renderCell: (params) => (
        //         <div style={{ display: 'flex', alignItems: 'center' }}>
        //             <IconButton aria-label='edit' onClick={handleDelete}>
        //                 <DeleteOutlinedIcon />
        //             </IconButton>
        //             <IconButton aria-label='delete' onClick={() => handleEdit(params.row)}>
        //                 <EditOutlinedIcon />
        //             </IconButton>
        //         </div>
        //     ),
        // },
    ];

    const getRowClassName = (params) => {
        return params.id % 2 === 0 ? 'even-row' : 'odd-row';
    };

    return (
        <Box className='dashboard-container'>
            <SettingsHeader />

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="success" size={40} />
                </Box>
            ) : (
                <Box sx={{ width: '100%', mb: 3 }}>
                {users.length === 0 ? (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: ['left', 'center'],
                                justifyContent: 'space-between',
                                padding: '20px',
                                borderBottom: '1px solid #EAECF0',
                                flexDirection: ['column', 'row'],
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                    }}
                                >
                                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                                        Client
                                    </Typography>
                                    <Typography
                                        sx={{
                                            border: '1px solid #E9D7FE',
                                            background: '#F9F5FF',
                                            borderRadius: '20px',
                                            color: '#6941C6',
                                            padding: '0 12px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        0 Clients
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box
                                    variant="body2"

                                    >
                                        Manage your client details here.
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        fontWeight: 600,
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                    }}
                                    onClick={handleCreate}
                                >
                                    + New Client
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                textAlign: 'center',
                                padding: '20px',
                                borderRadius: '8px',
                                border: '1px solid #EAECF0',
                                mt: 2,
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 600, color: '#666' }}>
                                No clients available
                            </Typography>
                            
                        </Box>
                    </>
                ) : (
                    <DataGrid
                        rows={usersTableData}
                        columns={columns}
                        disableRowSelectionOnClick
                        getRowClassName={getRowClassName}
                        showPagination={false}
                        slots={{
                            toolbar: () => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: ['left', 'center'],
                                        justifyContent: 'space-between',
                                        padding: '20px',
                                        borderBottom: '1px solid #EAECF0',
                                        flexDirection: ['column', 'row'],
                                    }}
                                >
                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                            }}
                                        >
                                            <Typography variant="h5" sx={{ fontWeight: 600 }}>
                                                Client
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    border: '1px solid #E9D7FE',
                                                    background: '#F9F5FF',
                                                    borderRadius: '20px',
                                                    color: '#6941C6',
                                                    padding: '0 12px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {users.length} Clients
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Box
                                                variant="body2"
                                            >
                                                Manage your client details here.
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                fontWeight: 600,
                                                borderRadius: '8px',
                                                textTransform: 'none',
                                            }}
                                            onClick={handleCreate}
                                        >
                                            + New Client
                                        </Button>
                                    </Box>
                                </Box>
                            ),
                            footer: () => (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 70,
                                        borderTop: '1px solid #EAECF0',
                                        p: 2,
                                    }}
                                >
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={users.length}
                                        pageSize={clientsPageSize}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    />
                                </Box>
                            ),
                        }}
                    />
                )}
            </Box>
            
            )}
            <Box className='modal'>
                <EditClient
                    editClientModalOpen={editClientModalOpen}
                    handleEditClientModalClose={handleEditClientModalClose}
                    selectedClient={selectedClient}
                />

                <CreateClient
                SetUsers={SetUsers}
                    createClientModalOpen={createClientModalOpen}
                    handleCreateClientModalClose={handleCreateClientModalClose}
                />
            </Box>
        </Box>
    );
};

export default Clients;
