import React, { useEffect, useState } from "react";
import {Box, Link, Modal,} from "@mui/material";
import './clientViewDetails.scss';
import Stack from "@mui/material/Stack";
import LinkIcon from '@mui/icons-material/Link';
import Chip from "@mui/material/Chip";
import LocationMap from "../../common/map/LocationMap";
import Grid from '@mui/material/Grid';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {useNavigate} from "react-router-dom";
import {setIsLoadingState} from "../../../redux/slices/common";
import CampaignService from "../../../services/api/campaign.service";
import {useDispatch} from "react-redux";
import moment from "moment";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InsertLinkIcon from '@mui/icons-material/InsertLink';


const CampaignViewDetailsModal = ({ clientViewModalOpen, handleClientViewModalClose, detailsData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [campaign, setCampaignViewDetailsData] = useState('');
    const [locations, setLocations] = useState(  [] );
    useEffect(() => {
        if (detailsData?.campaignId) {
            dispatch(setIsLoadingState(true));
            CampaignService.getCampaignViewDetails(detailsData?.campaignId).then(
                (data) => {
                    setCampaignViewDetailsData(data.data);
                    if(data.data?.locationDetails){
                        let pois = [];
                        data.data?.locationDetails.forEach((r) => {
                            pois.push({
                                latitude: parseFloat(r.latitude),
                                longitude: parseFloat(r.longitude)
                            })
                        })
                        setLocations(pois);
                    }
                    dispatch(setIsLoadingState(false));
                    return Promise.resolve();
                },
                (error) => {
                    console.log("Error Message", error.response.data.message);
                    return Promise.reject();
                }
            ).finally(() => {
                dispatch(setIsLoadingState(false));
            });
        }

    }, [detailsData?.campaignId]);
    const handleOnApproved = () => {
        handleClientViewModalClose(true);
    }
    const handleOnRejected = () => {
        handleClientViewModalClose(true);
    }

    const handleOnClose = () => {
        handleClientViewModalClose(true);
        setLocations([])
        setCampaignViewDetailsData('')
        
    }

    const goToCampaignEdit = (e) => {
        e.preventDefault();
        handleClientViewModalClose(true);
        navigate("/edit-campaign")
    }

    return (
        <>
            {campaign ? <Modal
                open={clientViewModalOpen}
                onClose={handleClientViewModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    backdropFilter: "blur(15px)",
                }}
            >
                <Box className="admin-details-modal" sx={{display:'flex',justifyContent:'space-evenly',flexDirection:'column'}}>
                    <Box >
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Box className="login-logo">
                            <img
                                src="/images/featured-icon.png"
                                alt="logo"
                                style={{height: 48}}
                            />
                        </Box>
                        <Box sx={{
                            cursor: "pointer"
                        }} className="login-logo" onClick={handleOnClose}>
                            <img
                                src="/images/close-icon.png"
                                alt="logo"
                                style={{height: 48}}
                            />
                        </Box>
                    </Box>
                    <Box sx={{marginTop: '8px'}}>
                        <Stack spacing={1}>
                            <Box className="com_name">{campaign.campaignTitle}</Box>
                            <Chip label={(campaign.campaignRemark === 'ENDED') ? 'Ended' : (campaign.campaignRemark === 'RUNNING' ? 'Running' :
                                (campaign.campaignRemark === 'UNDER_REVIEW' ? 'Under Review' : (campaign.campaignRemark === 'DRAFT' ? 'Draft' :
                                    (campaign.campaignRemark === 'PENDING' ? 'Pending' : (campaign.campaignRemark === 'REJECTED' ? 'Reject' : 'Paused')))))
                            } style={{
                                background: (campaign.campaignRemark === 'ENDED') ? '#344054' : (campaign.campaignRemark === 'RUNNING' ? '#039855' :
                                    (campaign.campaignRemark === 'UNDER_REVIEW' ? '#E04F16' : (campaign.campaignRemark === 'DRAFT' ? '#444CE7' :
                                        (campaign.campaignRemark === 'PENDING' ? '#344054' : (campaign.campaignRemark === 'REJECTED' ? '#B00020' : '#344054'))))),

                                color: '#ffffff',
                                width: 'fit-content'
                            }}/>
                        </Stack>
                    </Box>

                    { locations.length > 0 &&
                    <Box sx={{ marginTop: '8px'}} >
                        <LocationMap locations={locations}/>
                    </Box>
                    }

</Box>

                    <Box sx={{ marginTop: '18px'}}>
                        <Grid container spacing={2} columns={16}>
                            <Grid item xs={8}>

                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                >
                                    <Box className="date_title"> Starting Date</Box>
                                    <Stack direction="row" spacing={1} alignItems={'center'}>
                                        <CalendarTodayIcon sx={{fontSize: 20}}/>
                                        <Box className="date_text">{campaign.campaignStartDate ? (moment(campaign.campaignStartDate).format('DD/MM/YYYY')) : ''}</Box>
                                    </Stack>

                                </Stack>

                                {/* { campaign.platFormDetails.length > 0 ?
                                   <Box>

                                        {campaign.platFormDetails.map((row, rowIndex) => (
                                            <Stack direction="row" spacing={1} key={rowIndex} alignItems={'center'}
                                                   sx={{marginTop: '4px'}}>
                                                <img
                                                    src="/images/input.png"
                                                    alt="logo"
                                                    style={{height: 16}}
                                                />
                                                <Box className="link_text">{row?.landingPage}</Box>
                                            </Stack>
                                        ))}
                                    </Box>

                                   : ''

            } */}
                                 {campaign.platFormDetails.length > 0 ?
                                    <Box sx={{ marginTop: '18px'}}>

                                        {campaign.platFormDetails.map((row, rowIndex) => (
                                            <Box key={rowIndex}>
                                                {row?.platform ?
                                                    <Stack direction="row" spacing={1.5} key={rowIndex}
                                                           alignItems={'center'}
                                                           sx={{marginTop: '4px'}}>
                                                        {/* <img
                                                            src="/images/input.png"
                                                            alt="logo"
                                                            style={{height: 16}}
                                                        /> */}
                                                        <CheckBoxIcon color="primary"/>
                                                        <Box className="link_text">{row?.platform.name}</Box>
                                                    </Stack>
                                                    : (row.url ? ((row?.url.length > 0) ?
                                                        <Stack direction="row" spacing={1} key={rowIndex}
                                                               alignItems={'center'}
                                                               sx={{marginTop: '4px'}}>
                                                            {/* <img
                                                                src="/images/input.png"
                                                                alt="logo"
                                                                style={{height: 16}}
                                                            /> */}
                                                            <Box className="link_text">{row?.name}</Box>
                                                        </Stack>
                                                        : '') : '')
                                                }
                                            </Box>
                                        ))}
                                    </Box>

                                    : ''} 
                            </Grid>
                            <Grid item xs={8}>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                >
                                    <Box className="date_title"> End Date</Box>
                                    <Stack direction="row" spacing={1} alignItems={'center'}
                                           sx={{marginTop: '4px'}}>
                                        <CalendarTodayIcon sx={{fontSize: 20}}/>
                                        <Box className="date_text">{campaign.campaignEndDate ? (moment(campaign.campaignEndDate).format('DD/MM/YYYY')) : ''}</Box>
                                    </Stack>
                                </Stack>

                                { campaign.platFormDetails.length > 0 ?
                                   <Box sx={{ marginTop: '18px'}}>

                                        {campaign.platFormDetails.map((row, rowIndex) => (
                                            <Stack direction="row" spacing={1} key={rowIndex} alignItems={'center'}
                                                   sx={{marginTop: '4px'}}>
                                               <InsertLinkIcon/>
                                                <a target="_blank" rel="noopener noreferrer"  href={row?.landingPage}>{row?.landingPage}</a>
                                            </Stack>
                                        ))}
                                    </Box>

                                   : ''

            }
                                 {/* {campaign.platFormDetails.length > 0 ?
                                    <Box>

                                        {campaign.platFormDetails.map((row, rowIndex) => (
                                            <Box key={rowIndex}>
                                                {row?.platform ?
                                                    <Stack direction="row" spacing={1} key={rowIndex}
                                                           alignItems={'center'}
                                                           sx={{marginTop: '4px'}}>
                                                        <img
                                                            src="/images/input.png"
                                                            alt="logo"
                                                            style={{height: 16}}
                                                        />
                                                        <Box className="link_text">{row?.platform.name}</Box>
                                                    </Stack>
                                                    : (row.url ? ((row?.url.length > 0) ?
                                                        <Stack direction="row" spacing={1} key={rowIndex}
                                                               alignItems={'center'}
                                                               sx={{marginTop: '4px'}}>
                                                            <img
                                                                src="/images/input.png"
                                                                alt="logo"
                                                                style={{height: 16}}
                                                            />
                                                            <Box className="link_text">{row?.name}</Box>
                                                        </Stack>
                                                        : '') : '')
                                                }
                                            </Box>
                                        ))}
                                    </Box>

                                    : ''}  */}
                            </Grid>
                        </Grid>
                    </Box>


                    {/* {detailsData.status === 'ENDED' 
                        ?
                        <Box sx={{marginTop: '36px',}}>
                            <button className="btn_approved" onClick={handleOnApproved}>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <SaveAltIcon sx={{fontSize: 20, color: "#027A48!important"}}/>
                                    <Box className="Approve_btn_text">Download Report</Box>
                                </Stack>
                            </button>
                        </Box>

                        :

                        <Box sx={{flexGrow: 1, marginTop: '36px',}}>
                            <Grid container spacing={2} columns={16}>
                                <Grid item xs={8}>
                                    <button className="btn_approved"
                                            onClick={(e) => {goToCampaignEdit(e)}}>
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <EditOutlinedIcon sx={{fontSize: 20}}/>
                                            <Box className="Approve_btn_text"> Edit Campaign</Box>
                                        </Stack>
                                    </button>
                                </Grid>
                                <Grid item xs={8}>
                                    <button className="btn_download" onClick={handleOnRejected}>
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <SaveAltIcon sx={{fontSize: 20, color: "#FFFFFF!important"}}/>
                                            <Box className="btn_download_text">Download Tag</Box>
                                        </Stack>
                                    </button>
                                </Grid>
                            </Grid>
                        </Box>
                    } */}
                </Box>
            </Modal>
           : ''  }
        </>
    );
}

export default CampaignViewDetailsModal;
