import { Box, Button, Divider, FormHelperText, Grid, IconButton, Modal, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactFlagsSelect from 'react-flags-select';
import SaveIcon from '@mui/icons-material/Save';
import '../create-campaign-modal/CreateCampaign.scss'

function CreatePayment({ createPaymentModalOpen, handleCreatePaymentModalClose }) {
    const { register, handleSubmit, control, reset, formState: { errors }, getValues } = useForm();
    const [payments, setPayments] = React.useState(['']);

    const handleOnClose = () => {
        handleCreatePaymentModalClose(true);
    }

    const handleRemovePayment = (indexToRemove) => {
        setPayments(prevPayments => prevPayments.filter((_, index) => index !== indexToRemove));
    };

    const handleAddPayment = () => {
        setPayments(prevPayments => [...prevPayments, '']);
    };


    const handlePaymentChange = (index, value) => {
        const newPayments = [...payments];
        newPayments[index] = value;
        setPayments(newPayments);
    };

    const onSubmit = (data) => {
        const emailParams = data.emails.map((email, i) => `email${i}=${email}`);

        const queryParams = [
            `clientName=${data.clientName}`,
            `expiryDate=${data.expiryDate}`,
            `cardNumber=${data.cardNumber}`,
            `cvv=${data.cvv}`,
            ...emailParams,
            `street=${data.street}`,
            `city=${data.city}`,
            `state=${data.state}`,
            `province=${data.province}`,
            `country=${data.country}`,
        ];

        console.log(queryParams);
    }

    return (
        <Modal
            open={createPaymentModalOpen}
            onClose={handleCreatePaymentModalClose}
            sx={{
                backdropFilter: "blur(15px)",
            }}
        >
            <Box
                className="create-campaign-modal"
                sx={{
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none'
                }}
            >
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    type='reset'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Box className="login-logo" sx={{ marginBottom: 2 }}>
                            <Typography variant='h6' sx={{ fontWeight: 600 }}>Payment method</Typography>
                            <Typography variant='body1'>Update your billing details and address.</Typography>
                        </Box>

                        <Box sx={{
                            cursor: "pointer"
                        }} className="login-logo" onClick={handleOnClose}>
                            <img
                                src="/images/close-icon.png"
                                alt="logo"
                                style={{ height: 48 }}
                            />
                        </Box>
                    </Box>

                    <Divider sx={{ borderRadius: 1 }} />

                    <Box sx={{ py: 2 }}>
                        <Typography variant='body2' sx={{ fontWeight: 600 }}>Card Details</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box>
                                        <Typography htmlFor='clientName' variant="body2" component="label">
                                            Name on card
                                        </Typography>
                                        <Box className='input'>
                                            <TextField
                                                variant='outlined'
                                                sx={{ width: '100%', mt: '10px' }}
                                                size='small'
                                                type='text'
                                                placeholder='Olivia Rhye'
                                                {...register('clientName', { required: true })}
                                            />
                                            {errors.clientName && (
                                                <FormHelperText error>Please enter client name</FormHelperText>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography htmlFor='expiryDate' variant="body2" component="label">
                                            Expiry
                                        </Typography>
                                        <Box className='input'>
                                            <TextField
                                                variant='outlined'
                                                sx={{ width: '100%', mt: '10px' }}
                                                size='small'
                                                type='text'
                                                placeholder='06 / 2024'
                                                {...register('expiryDate', { required: true })}
                                            />
                                            {errors.expiryDate && (
                                                <FormHelperText error>Please enter expiry date</FormHelperText>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ mt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box>
                                        <Typography htmlFor='cardNumber' variant="body2" component="label">
                                            Card number
                                        </Typography>
                                        <Box className='input'>
                                            <TextField
                                                variant='outlined'
                                                sx={{ width: '100%', mt: '10px' }}
                                                size='small'
                                                type='text'
                                                placeholder='1234 1234 1234 1234'
                                                {...register('cardNumber', { required: true })}
                                            />
                                            {errors.cardNumber && (
                                                <FormHelperText error>Please enter the card number.</FormHelperText>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography htmlFor='cvv' variant="body2" component="label">
                                            CVV
                                        </Typography>
                                        <Box className='input'>
                                            <TextField
                                                variant='outlined'
                                                sx={{ width: '100%', mt: '10px' }}
                                                size='small'
                                                type='text'
                                                placeholder='...'
                                                {...register('cvv', { required: true })}
                                            />
                                            {errors.cvv && (
                                                <FormHelperText error>Please enter CVV number.</FormHelperText>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <Divider sx={{ borderRadius: 1 }} />

                    <Box sx={{ py: 1 }}>
                        <Box>
                            <Typography variant='body2' sx={{ fontWeight: 600 }}>Email address</Typography>
                            <Typography htmlFor='email' variant="body2" component="label">
                                Invoices will be sent to this email address.
                            </Typography>
                            {payments.map((value, index) => (
                                <Grid key={index} container spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <Box className='input' sx={{ pt: 1 }}>
                                            <TextField
                                                variant='outlined'
                                                sx={{ width: '100%', mt: '10px' }}
                                                size='small'
                                                type='text'
                                                placeholder={`demo${index}@gmail.com`}
                                                name={`email${index}`}
                                                onChange={e => handlePaymentChange(index, e.target.value)}
                                                {...register(`emails[${index}]`, { required: true, pattern: /^\S+@\S+$/i })}
                                            />
                                            {errors[`email${index}`] && (
                                                <FormHelperText error>Please enter a valid email address.</FormHelperText>
                                            )}
                                        </Box>
                                    </Grid>
                                    {payments.length > 1 && (
                                        <Grid item>
                                            <IconButton onClick={() => handleRemovePayment(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                        </Box>
                        <Box>
                            <Button sx={{ color: '#475467' }} variant="text" startIcon={<AddIcon />} onClick={handleAddPayment}>
                                Add another
                            </Button>
                        </Box>
                    </Box>

                    <Divider sx={{ borderRadius: 1 }} />

                    <Box sx={{ py: 2 }}>
                        <Typography variant='body2' htmlFor='street' sx={{ fontWeight: 600 }}>Street address</Typography>
                        <Grid container>
                            <Grid item xs={10}>
                                <Box className='input'>
                                    <TextField
                                        variant='outlined'
                                        sx={{ width: '100%', mt: '10px' }}
                                        size='small'
                                        placeholder='100 smith street'
                                        type='text'
                                        {...register('street', { required: true })}
                                    />
                                    {errors.street && (
                                        <FormHelperText error>Please enter street address.</FormHelperText>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Divider sx={{ borderRadius: 1 }} />

                    <Box sx={{ py: 2 }}>
                        <Typography htmlFor='city' variant='body2' sx={{ fontWeight: 600 }}>City</Typography>
                        <Grid container>
                            <Grid item xs={10}>
                                <Box className='input'>
                                    <TextField
                                        variant='outlined'
                                        sx={{ width: '100%', mt: '10px' }}
                                        size='small'
                                        type='text'
                                        placeholder='callingWood'
                                        {...register('city', { required: true })}
                                    />
                                    {errors.city && (
                                        <FormHelperText error>Please enter city address.</FormHelperText>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Divider sx={{ borderRadius: 1 }} />

                    <Box sx={{ py: 2 }}>
                        <Typography htmlFor='state' variant='body2' sx={{ fontWeight: 600 }}>State/Province</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Box className='input'>
                                    <TextField
                                        variant='outlined'
                                        sx={{ width: '100%', mt: '10px' }}
                                        size='small'
                                        type='text'
                                        placeholder='VIC'
                                        {...register('state', { required: true })}
                                    />
                                    {errors.state && (
                                        <FormHelperText error>Please enter state.</FormHelperText>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box className='input'>
                                    <TextField
                                        variant='outlined'
                                        sx={{ width: '100%', mt: '10px' }}
                                        size='small'
                                        type='number'
                                        placeholder='3066'
                                        {...register('province', { required: true })}
                                    />
                                    {errors.province && (
                                        <FormHelperText error>Please enter province.</FormHelperText>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Divider sx={{ borderRadius: 1 }} />

                    <Box sx={{ py: 2 }}>
                        <Typography htmlFor='country' variant='body2' sx={{ fontWeight: 600, pb: 1 }}>Country</Typography>
                        <Grid container>
                            <Grid item xs={10}>
                                <Box className='input'>
                                    <Controller
                                        control={control}
                                        name="country"
                                        render={({ field }) => (
                                            <ReactFlagsSelect
                                                className='menu-flags'
                                                searchable
                                                searchPlaceholder='Search country'
                                                id='country'
                                                selected={getValues("country")}
                                                onSelect={(countryCode) => field.onChange(countryCode)}
                                            />
                                        )}
                                    />
                                    {errors.country && (
                                        <FormHelperText error>Please enter country.</FormHelperText>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ py: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button startIcon={<SaveIcon />}
                                    variant='outlined'
                                    fullWidth
                                    style={{
                                        border: '1px solid gray',
                                        fontWeight: 600,
                                        borderRadius: '8px',
                                        color: 'black',
                                    }}
                                >
                                    Save as draft
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    style={{
                                        fontWeight: 600,
                                        borderRadius: '8px',
                                    }}
                                >
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal >
    )
}

export default CreatePayment;
