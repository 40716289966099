import React, { useRef } from 'react';
import { Box, Divider, Button, Popper, ClickAwayListener } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './navbar.scss';
import CreateCampaignModal from '../../../src/components/modals/create-campaign-modal/CreateCampaign';
import { useState } from 'react';
import { logout, setUser } from '../../redux/slices/auth';
import { useCookies } from "react-cookie";
import { connect } from "react-redux";
import AuthService from '../../services/api/auth.service';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import LensIcon from '@mui/icons-material/Lens';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';

import {
  accessToken,
  refreshToken
} from "../../config/config";
import { getPermission } from '../../utils/cryptograpgy';


const Navbar = ({ logout, setUser, user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const notificationElementRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null); // profile
  const [anchorEl2, setAnchorEl2] = React.useState(null); // notification
  const [anchorEl3, setAnchorEl3] = React.useState(null); // responsive menus
  const open3 = Boolean(anchorEl3);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popper' : undefined;
  const isUser = getPermission("STRETCH_CLIENT") || getPermission("STRETCH_MEMBER");
  const [image, setImage] = useState("/images/default-user-icon.jpg");
  const [cookies, setCookie, removeCookie] = useCookies();
  const [createCampaignModalOpen, setCreateCampaignModalOpen] = useState(false);
  const options = [
    '+ Create Campaign',
    'Dashboard',
    'Reports',
    'Notifications',
    'Help'
  ];
  const ITEM_HEIGHT = 48;

  const profileSettings = [
    {
      icon: '/images/users.png',
      name: 'Users',
    },
    {
      icon: '/images/profile-settings.png',
      name: 'Profile settings',
    },
    {
      icon: '/images/password.png',
      name: 'Password',
    },
    {
      icon: '/images/logout.png',
      name: 'Logout',
    },
  ];

  const notifications = [
    {
      project: "Technology and innovations",
      time: "2 mins ago",
      text: "Your campaign has been approved",
      type: "tag"
    },
    {
      project: "Technology and innovations",
      time: "2 mins ago",
      text: "Your campaign has been approved",
      type: "report"
    },
    {
      project: "Technology and innovations",
      time: "2 mins ago",
      text: "Your campaign has been approved",
      type: "tag"
    },
    {
      project: "Technology and innovations",
      time: "2 mins ago",
      text: "Your campaign has been approved",
      type: "tag"
    },
    {
      project: "Technology and innovations",
      time: "2 mins ago",
      text: "Your campaign has been approved",
      type: "report"
    },
    {
      project: "Technology and innovations",
      time: "2 mins ago",
      text: "Your campaign has been approved",
      type: "tag"
    }
  ]

  const handleClickOnResposiveMenus = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClickOnResposiveMenuItems = (option) => {
    if(option == options[0]) {
      // setCreateCampaignModalOpen(true);
      handleCreateCampaign();
    }
    else if(option == options[1]){
      redirectToHomepage();
    }
    else if(option == options[2]){
      naviagateToReportPage();
    }
    else if(option == options[3]) {
      const elementRef = notificationElementRef; 
      elementRef.current.click();
    }
    setAnchorEl3(null);
  };

  const handleClickOnProfilePopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickOnNotificationPopper = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  const handleRouteClick = (name) => {
    if (name == "Logout") {
      handleLogout();
    }
    else if (name == "Profile settings") {
      navigate('/settings/my-profile');
    }
    else if (name == 'Password') {
      navigate('/settings/password');
    }
    else if (name == "Users") {
      navigate('/settings/clients');
    }
    else {
      navigate('/settings/my-profile');
    }
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const redirectToHomepage = () => {
    if (getPermission("STRETCH_CLIENT")) {
      navigate("/client-dashboard-projects");
    }
    else {
      navigate("/admin-dashboard");
    }
  };

  const naviagateToReportPage  = () => {
    if (getPermission("STRETCH_CLIENT")) {
      navigate("/client-reports");
    }
    else {
      navigate("/admin-reports");
    }
  };

  // const handleCreateCampaignModalOpen = (e) => {
  //   e.preventDefault();
  //   setCreateCampaignModalOpen(true);
  // }

  // const handleCreateCampaignModaClose = () => {
  //   setCreateCampaignModalOpen(false);
  // };

  const handleCreateCampaign = () => {
    navigate('/create-campaign');
  };

  const handleLogout = () => {
    localStorage.setItem('clickEvent', 'logout');
 
    logoutCookie();

  };

  const logoutCookie = () => {
    removeCookie(accessToken, { path: '/' });
    removeCookie(refreshToken, { path: '/' });
    setUser(null);
    logout();
    navigate("/");
  }

  return (
    <>
      <Box className='dashboard'>
        <Box
          className='navbar'
          sx={{
            height: '80px',
            paddingTop: '16px',
            paddingLeft: '10%',
            paddingRight: '10%',
            paddingBottom: '16px',
            display: 'flex',
            fontWeight: 700,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              width: '50%',
              fontWeight: 700,
            }}
          >
            <Box
              sx={{
                mr: 2,
                cursor: 'pointer',
              }}
              className='login-logo'
              onClick={redirectToHomepage}
            >
              <img
                src='/images/stretchNew.png'
                alt='logo'
                width={100}
                height={100}
              />
            </Box>
            <Box className="hide-hamburger-menu" sx={{
              cursor: "pointer"
            }} onClick={redirectToHomepage}>
              <Box sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                color: location.pathname.includes("dashboard") ? "#6941C6" : "black",
                textDecoration: location.pathname.includes("dashboard") ? "underline" : "none"
              }}>
                Dashboard
              </Box>
            </Box>
            <Box onClick={naviagateToReportPage} className="hide-hamburger-menu" sx={{
              display: "flex",
              alignItems: "center"
            }}>
              <Box sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                color: location.pathname.includes("report") ? "#6941C6" : "#475467",
                cursor: "pointer"
              }}>
                Reports
              </Box>
              <ExpandMoreOutlinedIcon />
            </Box>
            <Box className="hide-hamburger-menu">
              <Box sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#475467",
                cursor: "pointer"
              }}>
                Help
              </Box>
            </Box>
          </Box>
          <ClickAwayListener onClickAway={() => {
            setAnchorEl(null);
            setAnchorEl2(null);
          }}>
            <Box
              sx={{
                display: 'flex',
                width: '50%',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
               <Box sx={{
                position: "absolute",
                left: "3%"
               }} className='show-hamburger-menu'>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open3 ? 'long-menu' : undefined}
                aria-expanded={open3 ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClickOnResposiveMenus}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl3}
                open={open3}
                onClose={handleClickOnResposiveMenuItems}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
                sx={{
                  mt: 4
                }}
              >
                {options.map((option) => (
                 !(!isUser && option==options[0]) && <MenuItem key={option} 
                  // selected={option === 'Pyxis'}
                   onClick={() => handleClickOnResposiveMenuItems(option)}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
              </Box>
              {
                isUser && <Box className="hide-hamburger-menu">
                <Button  style={{ fontWeight: 600, borderRadius: '8px', textTransform: "none" }} variant='contained' onClick={handleCreateCampaign}>
                  + Create Campaign
                </Button>
                </Box>
              }
             
              <Box
                onClick={handleClickOnNotificationPopper}
                sx={{
                  mr: 2,
                  ml: 2,
                  cursor: "pointer"
                }}
                className='login-logo hide-hamburger-menu'
              >
                <img
                 ref={notificationElementRef}
                  src='/images/notification.png'
                  alt='logo'
                  style={{ height: 44 }}
                />
              </Box>
              <Box>
                <button
                  aria-describedby={id}
                  type='button'
                  onClick={handleClickOnProfilePopper}
                >
                  <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2
                  }}>
                    <Box>
                      {image && <img className='image-container' src={user?.profileImage ? user.profileImage : image} defaultValue="Profile Image"></img>}
                    </Box>
                    <Box sx={{ cursor: 'pointer' }}>
                      <Box>
                        <Box sx={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "black"
                        }}>
                          {user?.userEmail}
                        </Box>
                      </Box>
                      <Box sx={{
                        display: "flex",
                        fontSize: "12px",
                        color: "#475467"
                      }}>{user?.email}</Box>
                    </Box>
                  </Box>
                </button>

                <Popper id={id} open={open} anchorEl={anchorEl}>
                  <Box
                    sx={{
                      padding: 4,
                      mt: 4,
                      backgroundColor: '#F2F4F7',
                    }}
                  >
                    <Box>
                      {profileSettings.map((menu, index) => {
                        return (
                          ((isUser && menu.name != "Users") || (!isUser)) && <Box
                            sx={{
                              display: 'flex',
                              padding: 1,
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: "beige"
                              }
                            }}
                            key={index}
                            onClick={() => handleRouteClick(menu.name)}
                          >
                            <Box
                              sx={{
                                mr: 2,
                                ml: 2,
                              }}
                              className='login-logo'
                            >
                              <img
                                src={menu.icon}
                                alt='logo'
                                style={{ height: 24 }}
                              />
                            </Box>
                            <Box
                              sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: 'black',
                              }}
                            >
                              {menu.name}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Popper>

                <Popper id={id2} open={open2} anchorEl={anchorEl2}>
                  <Box
                    sx={{
                      padding: 2,
                      mt: 4,
                      backgroundColor: 'white',
                      width: "345px",
                      border: "1px solid #F2F4F7",
                      borderRadius: "10px"
                    }}
                  >
                    <Box sx={{
                      mb: 3
                    }}>
                      <Box sx={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}>
                        <Box sx={{
                          fontSize: "18px",
                          fontWeight: "600"
                        }}>Recent Activity</Box>
                        <MoreVertOutlinedIcon sx={{
                          color: "#98A2B3"
                        }} />
                      </Box>
                    </Box>
                    <Divider sx={{
                      color: "#EAECF0"
                    }} />

                    <Box sx={{
                      mt: 2,
                      maxHeight: "325px",
                      overflowY: "scroll"
                    }}>
                      {
                        notifications.map((notification, index) => {
                          return (
                            <Box key={index} sx={{
                              display: "flex",
                              gap: 1,
                              mb: 1
                            }} >
                              <Box className="login-logo">
                                <img
                                  src="/images/featured-icon.png"
                                  alt="logo"
                                  style={{ height: 48 }}
                                />
                              </Box>
                              <Box>
                                <Box sx={{
                                  display: "flex"
                                }}>
                                  <Box sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#344054"
                                  }}>
                                    {notification.project?.length >= 20 ? notification.project.substring(0, 19) + "...  " : notification.project}
                                  </Box>
                                  <Box sx={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "#475467"
                                  }}>
                                    {notification.time}
                                  </Box>
                                  <LensIcon sx={{
                                    width: "8px",
                                    height: "8px",
                                    color: "#17B26A"
                                  }} />
                                </Box>
                                <Box sx={{
                                  fontSize: "14px",
                                  color: "#475467"
                                }}>{notification.text}</Box>
                                <Box sx={{
                                  fontSize: "14px",
                                  color: "#475467"
                                }}>
                                  <a style={{
                                    cursor: "pointer"
                                  }}> {notification.type == "tag" ? "Download Tag" : "Download Report"} </a> now
                                </Box>
                              </Box>
                            </Box>
                          )
                        })
                      }
                    </Box>

                  </Box>
                </Popper>
              </Box>
            </Box>
          </ClickAwayListener>

        </Box>
        <Divider />
      </Box>
      {/* <Box className="modal">
        <CreateCampaignModal
          createCampaignModalOpen={createCampaignModalOpen}
          handleCreateCampaignModaClose={handleCreateCampaignModaClose} />
      </Box> */}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.auth.user
});

export default connect(mapStateToProps, { logout, setUser })(
  Navbar
);

