import React from "react";
import {
    Box, TextField, Checkbox, FormControlLabel, IconButton
} from "@mui/material";
import { Controller } from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import CommonFileUploader from "../../../common/CommonFileUploader/CommonFileUploader";
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';


const PlatformDetails = (params) => {
    const { control, handlePlatforms, handleAddingNewField, getValues, handleDeletingNewField } = params;

    const handleCheckboxChange = (index) => {
        handlePlatforms(index);
    };

    const handleAddInputField = (index) => {
        handleAddingNewField(index);
    };

    const getDCMFiles = (files, index) => {

        if (files.name) {
            // console.log("uploaded");
        }
        else {
            // console.log("removed");
            handlePlatforms(index);
        }
    }

    const handleDeleteField = (index, fieldIndex) => {
        handleDeletingNewField(index, fieldIndex);
    }

    return (
        <>
            <Box sx={{
                mt: 4
            }} className="create-campaign-title">Select Platforms</Box>
            <Box sx={{
                ml: 2
            }}>
                <Box>
                    {getValues("platforms").map((section, index) => (
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column"
                        }} key={index}>
                            <FormControlLabel
                                control={<Checkbox checked={section.isChecked} onChange={() => handleCheckboxChange(index)} />}
                                label={<Box sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                    color: "#344054"
                                }}> {section.name}</Box>}
                            />
                            {
                                getValues("dcm") == "no" && <Box sx={{
                                    ml: 4
                                }}>
                                    {section.isChecked && section.fields && <Box sx={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "20px",
                                        color: "#98A2B3",
                                        mb: 1
                                    }}>Landing Page*</Box>}
                                    {<Box sx={{
                                        maxHeight: "143px",
                                        overflowY: "auto"
                                    }}>
                                        {section.isChecked && (
                                            section.fields?.map((value, fieldIndex) => (
                                                <Box key={fieldIndex} sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mb: 1,
                                                    gap: 1
                                                }}>
                                                    <Controller
                                                        key={fieldIndex}
                                                        name={`platforms.${index}.fields.${fieldIndex}`}
                                                        control={control}
                                                        defaultValue={value}
                                                        render={({ field }) =>
                                                            <TextField size="small" sx={{
                                                                width: "85%"
                                                            }} {...field} />}
                                                    />
                                                    <IconButton onClick={() => handleDeleteField(index, fieldIndex)} aria-label="delete">
                                                        <DeleteOutlineOutlined />
                                                    </IconButton>
                                                </Box>

                                            ))
                                        )}
                                    </Box>}

                                    {section.isChecked && section.fields && (
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer"
                                        }} onClick={() => handleAddInputField(index)}>
                                            <AddIcon></AddIcon>
                                            <Box sx={{
                                                fontWeight: 600,
                                                fontSize: "14px",
                                                lineHeight: "20px",
                                                color: "#344054"
                                            }} > Add another </Box>
                                        </Box>
                                    )}
                                </Box>
                            }

                            {
                                getValues("dcm") == "yes" && section.isChecked && section.fields &&
                                <Controller
                                    name={`platforms.${index}.file`}
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <CommonFileUploader
                                                getFiles={(files) => {
                                                    field.onChange(files);
                                                    getDCMFiles(files, index); // Update state
                                                }}
                                                fileTypes={["csv"]}
                                                file={field.value} // Use field.value as the file prop
                                                showFileList={true}
                                                label="dcm"
                                                containerheight="74"
                                                borderColor="#EAECF0"
                                            />
                                            <a href="https://dev-stretch-api.memob.com/assets/file/Mik.csv">Download Sample CSV File</a>
                                        </>
                                    )}
                                />
                            }

                        </Box>
                    ))}
                </Box>
            </Box>
        </>
    );
}

export default PlatformDetails;