// React and Hooks
import React, { useEffect, useState } from "react";

// MUI Components
import { Box, Typography, Grid, Avatar } from "@mui/material";

// MUI Icons
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

// Custom Components
import SearchComponent from "../../components/common/SearchComponent/searchComponent";
import ClientWiseProjectList from "../../components/common/ClientwiseProjectList/clientWiseProjectList";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setIsLoadingState } from "../../redux/slices/common";

// Services
import ClientService from "../../services/api/client.service";

const ClientDashboard = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.auth.user)
  const [clientInfo, clientCampaignAnalytics] = useState([])
  const [searchValue, setSearchValue] = useState(null)

  useEffect(() => {
    dispatch(setIsLoadingState(true))
    ClientService.getClientDashBoardData()
      .then(
        (data) => {
          let campaignAnalytics = []

          // Adjusting the structure to map the new response keys and values
          Object.entries(data.data).forEach(([key, val]) => {
            switch (key) {
              case "totalCampaignCount":
                campaignAnalytics.push({
                  title: "Total Campaigns",
                  noOfCampaigns: val,
                  percentageAmount: 20,
                  status: true,
                })
                break
              case "totalPendingCount":
                campaignAnalytics.push({
                  title: "Pending for Approval Campaigns",
                  noOfCampaigns: val,
                  percentageAmount: 30,
                  status: false,
                })
                break
              case "totalActiveCount":
                campaignAnalytics.push({
                  title: "Active Campaigns",
                  noOfCampaigns: val,
                  percentageAmount: 10,
                  status: false,
                })
                break
              // Add new cases here for other keys in the response
              default:
                // Handle any other keys if necessary
                break
            }
          })

          clientCampaignAnalytics(campaignAnalytics)
          return Promise.resolve()
        },
        (error) => {
          console.log("Error Message", error.response.data.message)
          return Promise.reject()
        }
      )
      .finally(() => {
        dispatch(setIsLoadingState(false))
      })
  }, [dispatch])

  const handleChange = (value) => {
    if (value) {
      setSearchValue(value)
    } else {
      setSearchValue(null)
    }
  }
  return (
    <Box sx={{ flexGrow: 1, padding: 6 }}>
      <Grid container spacing={3}>
        {/* Welcome Text */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ fontSize: "2rem", fontWeight: 500 }}>
            Welcome back, {user?.userName || user?.userFirstName}
          </Typography>
          <Typography variant="body1">
            Track, manage and forecast your campaign details.
          </Typography>
        </Grid>

        {/* Client Info Grid - 3 Columns */}
        {clientInfo.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                p: 3,
                border: "1px solid #EAECF0",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="body1">{item.title}</Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "2rem", fontWeight: 500 }}
              >
                {item.noOfCampaigns}
              </Typography>
              {item.status !== undefined && (
                <Box sx={{ display: "flex" }}>
                  {item.status ? (
                    <Box sx={{ display: "flex" }}>
                      <ArrowUpwardIcon
                        sx={{ fontSize: "20px", color: "#12B76A" }}
                      />
                      <Box sx={{ color: "#12B76A" }}>
                        {item.percentageAmount}%
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      <ArrowDownwardIcon
                        sx={{ fontSize: "20px", color: "#F04438" }}
                      />
                      <Box sx={{ color: "#F04438" }}>
                        {item.percentageAmount}%
                      </Box>
                    </Box>
                  )}
                  <Typography paddingLeft={1} variant="body1">
                    vs last month
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        ))}

        {/* Profile Section */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "flex-start", md: "center" },
              flexDirection: { xs: "column", md: "row" }, // Column layout on mobile, row on larger screens
              justifyContent: { xs: "flex-start", md: "space-between" }, // Adjust spacing for mobile and desktop
            }}
          >
            {/* Profile Details */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: { xs: 2, md: 0 },
              }}
            >
              <Avatar
                sx={{ width: 56, height: 56 }}
                alt={"profile"}
                src={user?.profileImage ? user?.profileImage : "dummy/img4.png"}
              />
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 500,
                  mt: { xs: 1, md: 0 },
                  ml: 2,
                }}
              >
                {user?.userCompany || user?.companyName}
              </Typography>
            </Box>

            {/* Search Component */}
            <Box sx={{ width: { xs: "100%", md: "auto" } }}>
              <SearchComponent searchData={handleChange} />
            </Box>
          </Box>
        </Grid>

        {/* Client-Wise Project List */}
        <Grid item xs={12}>
          <ClientWiseProjectList searchValue={searchValue} />
        </Grid>
      </Grid>
    </Box>
  )
}
export default ClientDashboard
